import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-colour-select',
    templateUrl: './colour-select.component.html',
    styleUrls: ['./colour-select.component.scss']
})
export class ColourSelectComponent implements OnChanges {

    @Input() form: FormGroup;
    @Input() formControlKey: string;

    @Output() colourSelected = new EventEmitter();

    themes = environment.configuration.availableThemes;
    activeTheme: string;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        this.activeTheme = this.form.get(this.formControlKey).value;
    }

    onClick(theme: string): void {
        this.form.get(this.formControlKey).setValue(theme);
        this.form.markAsDirty();
        this.colourSelected.emit();
    }

}
