import { Component, OnInit } from '@angular/core';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { PagedResponse } from 'django-rest-core';
import { MetadataEnum } from '@app/shared/models/metadataEnum.model';
import { Observable, of } from 'rxjs';
import { StatusState } from '@app/shared/models/enums/enums.model';

@Component({
    selector: 'app-case-status-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class CaseStatusSelectComponent extends SelectBaseComponent<MetadataEnum> implements OnInit {

    constructor(
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.bindToField = 'title';
        this.bindToValue = 'value';
        this.getData(null).subscribe((response) => {
            this.options = response.results;
        });
    }

    getData(page: number): Observable<PagedResponse<MetadataEnum>> {
        const data = new PagedResponse<MetadataEnum>();
        data.results = [];

        for (const caseStatus of Object.values(StatusState)) {
            switch (caseStatus) {
                case StatusState.OPEN:
                    data.results.push(new MetadataEnum(StatusState.OPEN, 'Open'));
                    break;
                case StatusState.CLOSED:
                    data.results.push(new MetadataEnum(StatusState.CLOSED, 'Closed'));
                    break;
            }
        }

        return of(data);
    }

}
