import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ReferralService } from '@app/core/services/referral/referral.service';
import { Case } from '@app/shared/models/case.model';
import { CaseSummary, OpenReferrals } from '@app/shared/models/caseSummary.model';
import { StatusState } from '@app/shared/models/enums/enums.model';
import { ReferralFilters } from '@app/shared/models/filters/referralFilters.model';
import { Status } from '@app/shared/models/status.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cases-referrals-status-overview',
    templateUrl: './cases-referrals-status-overview.component.html',
    styleUrls: ['./cases-referrals-status-overview.component.scss']
})
export class CasesReferralsStatusOverviewComponent implements OnInit, OnDestroy {

    readonly referralsTerm = environment.configuration.terminology.referralPlural;
  
    @Input() case: Case;
    @Input() caseId: number;

    filterOptions: ReferralFilters = new ReferralFilters();
    summaries: OpenReferrals[] = [];
    subscriptions = new Subscription();
    loaded = true;
    spinnerName = 'referral-status-spinner';

    constructor(
        private router: Router,
        private referralService: ReferralService,
        private spinnerService: NgxSpinnerService
    ) { }

    ngOnInit(): void {
        this.getCaseSummary();
        this.subscriptions.add(this.referralService.hasUpdated().subscribe((res) => {
            this.getCaseSummary();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    getCaseSummary(): void {
        this.loaded = false;
        this.spinnerService.show(this.spinnerName);
        this.filterOptions.caseId = this.caseId.toString();
        this.referralService.getCaseSummary(1, this.filterOptions).subscribe((caseSummary: CaseSummary) => {
            caseSummary.O.forEach((openReferrals) => {
                this.summaries.push(openReferrals);
            });
            if (caseSummary.C.positiveClosures?.count) {
                const temp = new OpenReferrals();
                temp.count = caseSummary.C.positiveClosures.count;
                temp.referrals = caseSummary.C.positiveClosures.referrals;
                temp.status = new Status();
                temp.status.name = 'Positive Closures';
                temp.status.state = StatusState.CLOSED;
                this.summaries.push(temp);
            }
            if (caseSummary.C.negativeClosures?.count) {
                const temp = new OpenReferrals();
                temp.count = caseSummary.C.negativeClosures.count;
                temp.referrals = caseSummary.C.negativeClosures.referrals;
                temp.status = new Status();
                temp.status.name = 'Negative Closures';
                temp.status.state = StatusState.CLOSED;
                this.summaries.push(temp);
            }
            this.spinnerService.hide(this.spinnerName);
            this.loaded = true;
        });
    }

    goToOrganisation(): void {
        this.router.navigate([environment.links.organisation, this.case?.organisationSent.uid]);
    }

    getTheme(status: Status, index: number) {
        if (status.state === StatusState.OPEN) {
            switch (index % 2) {
                case 0:
                    return 'white';
                default:
                case 1:
                    return 'tertiary';
            }
        } else {
            if (status.name === 'Positive Closures') {
                return 'primary';
            }
            return 'red';
        }
    }

}
