import { Component, OnInit } from '@angular/core';
import { StaffRoleService } from '@app/core/services/staff-role.service';
import { StaffRole } from '@app/shared/models/staffRole.model';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-staff-role-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class StaffRoleSelectComponent extends SelectBaseComponent<StaffRole> implements OnInit {

    constructor(
        private staffRoleService: StaffRoleService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.staffRoleService;
    }

}
