import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsBaseService } from './forms-base.service';
import { FormArrayBaseService } from './form-array-base.service';
import { ReferralClosureReason } from '@app/shared/models/referralClosureReason.model';
import { ClosureType } from '@app/shared/models/enums/enums.model';
import { partition } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class ReferralClosureReasonFormService extends FormsBaseService implements FormArrayBaseService<ReferralClosureReason> {

    constructor(protected formBuilder: FormBuilder) {
        super(formBuilder);
    }

    createFormArray(referralClosureReason: ReferralClosureReason[]): FormGroup {
        return this.formBuilder.group({
            formArray: this.formBuilder.array(referralClosureReason ?
                referralClosureReason.map((referralclosurereason) => this.createForm(referralclosurereason)) : []
            )
        });
    }

    createForm(referralClosureReason?: ReferralClosureReason): FormGroup {
        return this.formBuilder.group({
            uid: [referralClosureReason?.uid],
            url: [referralClosureReason?.url],
            name: [referralClosureReason?.name, Validators.required],
            requiresClosureReasonNote: [referralClosureReason?.requiresClosureReasonNote],
            closureType: [referralClosureReason?.closureType, Validators.required],
        });
    }

    public from(referralClosureReasonForm: FormGroup, referralClosureReason = new ReferralClosureReason()): ReferralClosureReason {
        const formData = referralClosureReasonForm.getRawValue();

        referralClosureReason.uid = formData.uid;
        referralClosureReason.url = formData.url;
        referralClosureReason.name = formData.name;
        referralClosureReason.requiresClosureReasonNote = formData.requiresClosureReasonNote ? true : false;
        referralClosureReason.closureType = formData.closureType;

        return referralClosureReason;
    }

    public fromArray(form: FormArray, referralClosureReason: ReferralClosureReason[] = []): ReferralClosureReason[] {
        const newReferralClosureReason = [];

        for (let i = 0; i < form.controls.length; i++) {
            newReferralClosureReason.push(this.from(form.controls[i] as FormGroup, referralClosureReason[i]));
        }

        return newReferralClosureReason;
    }
}
