import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter, PagedResponse, ResourceLink, RestfulServiceBase } from 'django-rest-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Util } from '@app/shared/models/utils/utils';

// This is modified code based on this class => RestfulServiceReadOnly<T>
@Injectable({
    providedIn: 'root'
})
export class BaseReadOnlyService<T, F extends IFilter> extends RestfulServiceBase {

    endPoint: string;
    defaultSort: string;
    exampleObject: T;

    protected getApiRoot = () => `${environment.apiRoot}${this.endPoint}/`;

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(uid: string | number, subEndpoint = '', setTypeOnResponse = true): Observable<T> {
        if (subEndpoint) {
            subEndpoint = subEndpoint + '/';
        }
        const request = this.http.get(`${this.getApiRoot()}${uid}/${subEndpoint}`);
        if (setTypeOnResponse) {
            return this.setTypeOnResponse(request, this.exampleObject);
        } else {
            return request as Observable<any>;
        }
    }

    getPagedList(page: number, filter?: F, sortBy?: string, subEndpoint = '', setTypeOnResponse = true): Observable<PagedResponse<T>> {
        if (subEndpoint) {
            subEndpoint = subEndpoint + '/';
        }
        let request = `${this.getApiRoot()}${subEndpoint}?page=${page}`;
        if (filter) {
            request += `${filter.getSearchCriteriaString()}`;
        }
        if (sortBy) {
            request += `&ordering=${sortBy}`;
        }
        if (setTypeOnResponse) {
            return this.setTypeOnResponse(this.http.get(`${request}`), this.exampleObject);
        } else {
            return this.http.get(`${request}`) as Observable<PagedResponse<any>>;
        }
    }

    setObjectResources(resouceLinks: ResourceLink<T>[]): Observable<ResourceLink<T>> {
        return this.setResource(resouceLinks, this.mapResource);
    }

    mapResource = (resourceLink: ResourceLink<T>) => {
        return this.followResourceLink(resourceLink).pipe(
            map((response) => {
                resourceLink.value = Util.staticPlainToClassArrayFromObject(this.exampleObject, response);
                return resourceLink;
            })
        );
    }

    setTypeOnResponse(observable: Observable<any>, existingObject: any): Observable<any> {
        return observable.pipe(
            map((response: PagedResponse<T> | T) => {
                if (response.hasOwnProperty('results')) {
                    (response as PagedResponse<T>).results = Util.staticPlainToClassArrayFromObject(
                        existingObject, (response as PagedResponse<T>).results
                    );
                } else {
                    response = Util.staticPlainToClassArrayFromObject(existingObject, response);
                }
                return response;
            })
        );
    }
}
