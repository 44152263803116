import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-details-header',
    templateUrl: './details-header.component.html',
    styleUrls: ['./details-header.component.scss']
})
export class DetailsHeaderComponent implements OnInit {

    readonly clientTerm = environment.configuration.terminology.client;
    readonly caseTerm = environment.configuration.terminology.case;
    readonly referralTerm = environment.configuration.terminology.referral;
   
    @Input() isOverview = true;

    @Output() showReferralOverview = new EventEmitter();
    @Output() showContactDetails = new EventEmitter();
    @Output() showActivityDetails = new EventEmitter();
    @Output() printReferral = new EventEmitter();
    @Output() clientInfoSelected = new EventEmitter();

    navItems = [
        {
            title: `${this.referralTerm} Status`,
            icon: 'folder-tree',
            active: false,
            onClick: () => this.onShowReferralOverviewSelected()
        },
        {
            title: `${this.clientTerm} Info`,
            icon: 'id-card',
            active: false,
            onClick: () => this.onShowContactDetailsSelected()
        },
        {
            title: `${this.caseTerm} Activity`,
            icon: 'wave-pulse',
            active: false,
            onClick: () => this.onShowActivity()
        },
    ];

    constructor() { }

    ngOnInit(): void {
        this.navItems[0].active = this.isOverview;
        this.navItems[1].active = !this.isOverview;

        if (this.navItems[1].active === true) {
            this.clientInfoSelected.emit(true);
        }
    }

    print(): void {
        this.printReferral.emit();
    }

    resetNavItems(): void {
        this.navItems.map(x => x.active = false);
    }

    onShowReferralOverviewSelected(): void {
        this.resetNavItems();
        this.navItems[0].active = true;
        this.showReferralOverview.emit();
        this.clientInfoSelected.emit(false);
    }

    onShowContactDetailsSelected(): void {
        this.resetNavItems();
        this.navItems[1].active = true;
        this.showContactDetails.emit();
        this.clientInfoSelected.emit(true);
    }

    onShowActivity(): void {
        this.resetNavItems();
        this.navItems[2].active = true;
        this.showActivityDetails.emit();
        this.clientInfoSelected.emit(false);
    }

}
