<div [formGroup]="formGroup">
    <div class="d-flex flex-column gy4">
        <div *ngIf="hasFixedAddressCheckbox">
            <app-checkbox-input labelText="No Fixed Address" formControlKey="noFixedAbode"
                [formGroup]="formGroup"></app-checkbox-input>
        </div>
        <div *ngIf="getFormGroup('localAuthority')">
            <label class="required-field xs-label">LOCAL AUTHORITY</label>
            <app-local-authority-select bindToField="name" [formGroup]="formGroup" formControlKey="localAuthority"
                placeholder="Local Authority">
            </app-local-authority-select>
            <app-error [control]="getFormGroup('localAuthority')"></app-error>
        </div>
        <ng-container *ngIf="!getFormGroup('noFixedAbode')?.value || !hasFixedAddressCheckbox">
            <div>
                <label class="required-field xs-label" id="postcode">POSTCODE</label>
                <input name="postcode" id="postcode" class="form-control light-gray-border xs-placeholder"
                    formControlName="postcode" placeholder="Postcode">
                <app-error [control]="getFormGroup('postcode')"></app-error>
            </div>
            <div>
                <div class="row justify-content-between gy3">
                    <div class="col-auto d-flex align-items-center">
                        <button class="btn btn-outline-primary" [disabled]="!getFormGroup('postcode').value"
                            (click)="findAddress(getFormGroup('postcode').value)">Find Address</button>
                    </div>
                    <div class="col d-flex align-items-center">
                        <a (click)="manualAddressEnter()" class="form-link">Or enter address manually</a>
                    </div>
                </div>
            </div>
            <div *ngIf="postcodesFound && !showAddressFields">
                <ng-select [items]="postcodesArray" placeholder="Select an address" bindLabel="house" bindValue="value"
                    dropdownPosition="top">
                    <ng-template ng-option-tmp let-item="item" let-searchTerm="searchTerm">
                        <div (click)="selectAddress(item)" class="p2">
                            <span class="xs-text">{{item.house}} {{item.street}}, {{item.locality}} {{item.city}},
                                {{item.postcode}}</span><br>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
            <ng-container *ngIf="showAddressFields">
                <div>
                    <a (click)="clearAddress()" class="form-link-red float-right">Clear Address</a>
                </div>
                <div>
                    <label class="required-field xs-label" for="firstLine">ADDRESS LINE 1</label>
                    <input id="firstLine" class="form-control light-gray-border xs-placeholder"
                        formControlName="firstLine" placeholder="Address Line 1" />
                    <app-error [control]="getFormGroup('firstLine')"></app-error>
                </div>
                <div>
                    <label class="xs-label" for="address2">ADDRESS LINE 2</label>
                    <input id="address2" class="form-control light-gray-border xs-placeholder"
                        formControlName="secondLine" placeholder="Address Line 2" />
                </div>
                <div>
                    <label class="xs-label required-field" for="address-city">TOWN / CITY</label>
                    <input id="address-city" class="form-control light-gray-border xs-placeholder"
                        formControlName="town" placeholder="Town / City" />
                    <app-error [control]="getFormGroup('town')"></app-error>
                </div>
                <div>
                    <label for="address-county" class="xs-label">COUNTY</label>
                    <input type="text" name="county" class="form-control light-gray-border xs-placeholder"
                        id="address-county" placeholder="County" formControlName="county" />
                    <app-error [control]="getFormGroup('county')"></app-error>
                </div>
            </ng-container>
        </ng-container>
        <div *ngIf="!hasCustomSave">
            <div class="row no-gutters gy3 gx3 mt3">
                <div class="col-auto">
                    <button (click)="save()" class="btn btn-primary no-wrap" [disabled]="!isValid">Save</button>
                </div>
                <div class="col-auto">
                    <button (click)="goBack()" class="btn btn-outline-primary">Back</button>
                </div>
            </div>
        </div>
    </div>
</div>
