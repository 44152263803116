import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dropdown-item',
    templateUrl: './dropdown-item.component.html',
    styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent {

    @Input() faIcon: string;
    @Input() faType: 'light' | 'regular' | 'solid' = 'light';
    @Input() displayAsWarning = false;
    @Input() text: string;
    @Input() isActive = false;
    @Input() hasClickEvent = true;

    @Output() clickEvent = new EventEmitter<PointerEvent>();

    constructor() { }

    clicked = (event: PointerEvent) => this.clickEvent.emit(event);

    get type(): string {
        return this.faType === 'light' ? 'fal' : (this.faType === 'solid' ? 'fas' : 'far');
    }
}
