import { Transform } from 'class-transformer';
import { ResourceLink } from 'django-rest-core';
import { Case } from '../case.model';
import { Note } from './note.model';

export class CaseNote extends Note {

    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<Case>(params.value) : null, { toClassOnly: true })
    case: ResourceLink<Case>;
}
    