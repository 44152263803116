import { IFilter } from 'django-rest-core';
import { uniq } from 'lodash-es';
import * as moment from 'moment';
import { Moment } from 'moment';

export class ClientFilters implements IFilter {

    name: string;
    firstName: string;
    lastName: string;
    dob: Moment;
    postcode: string;
    ageFrom: number;
    ageTo: number;
    genderIds: string[];
    localAuthoritiesIds: string[];

    constructor() { }

    public getSearchCriteriaString(): string {
        let search = '';
        if (this.name) {
            search += '&search=' + encodeURIComponent(this.name);
        }
        if (this.firstName) {
            search += '&first_name__iexact=' + encodeURIComponent(this.firstName);
        }
        if (this.lastName) {
            search += '&last_name__iexact=' + encodeURIComponent(this.lastName);
        }
        if (this.dob) {
            search += '&date_of_birth=' + encodeURIComponent(this.dob.format('YYYY-MM-DD'));
        }
        if (this.postcode) {
            search += '&postcode=' + encodeURIComponent(this.postcode);
        }
        if (this.ageFrom || this.ageFrom === 0) {
            search += '&age_from=' + this.ageFrom;
        }
        if (this.ageTo || this.ageTo === 0) {
            search += '&age_to=' + this.ageTo;
        }
        if (this.genderIds?.length > 0) {
            this.genderIds.forEach(element => search += '&gender=' + element);
        }
        if (this.localAuthoritiesIds?.length > 0) {
            this.localAuthoritiesIds.forEach(element => search += '&local_authority=' + element);
        }

        return search;
    }

    toUrl(): {[k: string]: any} {
        const urlParams: {[k: string]: any} = {};

        for (const key of Object.keys(this)) {
            urlParams[key] = null;
            const valid = (this[key] || this[key] === false || this[key] === 0);
            if (valid) {
                switch (key) {
                    case 'name':
                    case 'firstName':
                    case 'lastName':
                    case 'postcode':
                    case 'ageFrom':
                    case 'ageTo':
                        urlParams[key] = this[key];
                        break;
                    case 'genderIds':
                    case 'localAuthoritiesIds':
                        if (Array.isArray(this[key]) && this[key].length > 0) {
                            urlParams[key] = uniq(this[key] as any).join(',');
                        }
                        break;
                    case 'dob':
                        if (this[key].isValid()) {
                            urlParams[key] = this[key].format('YYYY-MM-DD');
                        }
                        break;
                }
            }
        }
        return urlParams;
    }

    fromUrl(queryParams): void {
        for (const key of Object.keys(queryParams)) {
            const valid = (queryParams[key] || queryParams[key] === false || queryParams[key] === 0);
            if (valid) {
                switch (key) {
                    case 'name':
                    case 'firstName':
                    case 'lastName':
                    case 'postcode':
                        this[key as any] = queryParams[key];
                        break;
                    case 'ageFrom':
                    case 'ageTo':
                        if (parseInt(queryParams[key], 10)) {
                            this[key] = parseInt(queryParams[key], 10);
                        }
                        break;
                    case 'genderIds':
                    case 'localAuthoritiesIds':
                        const filteredValue = queryParams[key]?.replace(/[^0-9,]/g, '');
                        if (filteredValue) {
                            const tempArray = [];
                            uniq(filteredValue.split(',')).forEach((item: string) => {
                                if (parseInt(item, 10)) {
                                    tempArray.push(item);
                                }
                            });
                            this[key] = tempArray.length > 0 ? tempArray : null;
                        }
                        break;
                    case 'dob':
                        const newDate = moment(new Date(queryParams[key]), 'YYYY-MM-DD');
                        if (
                            newDate.isValid() &&
                            newDate.isAfter(moment('01/01/1900', 'DD/MM/YYYY')) &&
                            newDate.isBefore(moment('31/12/2030', 'DD/MM/YYYY'))
                        ) {
                            this[key] = newDate.isValid() ? newDate : null;
                        }
                        break;
                }
            }
        }
    }

}
