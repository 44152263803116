import { Component, OnInit } from '@angular/core';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { PagedResponse } from 'django-rest-core';
import { MetadataEnum } from '@app/shared/models/metadataEnum.model';
import { Observable, of } from 'rxjs';
import { DashboardOrganisationType } from '@app/shared/models/enums/enums.model';

@Component({
    selector: 'app-organisation-type-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class OrganisationTypeSelectComponent extends SelectBaseComponent<MetadataEnum> implements OnInit {

    constructor(
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.bindToField = 'title';
        this.bindToValue = 'value';
        this.getData(null).subscribe((response) => {
            this.options = response.results;
        });
    }

    getData(page: number): Observable<PagedResponse<MetadataEnum>> {
        const data = new PagedResponse<MetadataEnum>();
        data.results = [];

        for (const dashboardOrganisationType of Object.values(DashboardOrganisationType)) {
            switch (dashboardOrganisationType) {
                case DashboardOrganisationType.SENT:
                    data.results.push(new MetadataEnum(DashboardOrganisationType.SENT as any, 'Sent'));
                    break;
                case DashboardOrganisationType.RECEIVED:
                    data.results.push(new MetadataEnum(DashboardOrganisationType.RECEIVED as any, 'Received'));
                    break;
            }
        }

        return of(data);
    }

}