import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { ReferralFormService } from '@app/core/services/forms/referral-form.service';
import { ModalService } from '@app/core/services/modal.service';
import { OrganisationService } from '@app/core/services/organisation.service';
import { ReferralService } from '@app/core/services/referral/referral.service';
import { ToastService } from '@app/core/services/toast.service';
import { Case } from '@app/shared/models/case.model';
import { Client } from '@app/shared/models/client.model';
import { OrganisationFilters } from '@app/shared/models/filters/organisationFilters.model';
import { Issue } from '@app/shared/models/issue.model';
import { Organisation } from '@app/shared/models/organisation.model';
import { Referral } from '@app/shared/models/referral.model';
import { User } from '@app/shared/models/user.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReferralCreatedModalComponent } from '../../../modules/referral/components/modals/referral-created-modal/referral-created-modal.component';
import { mergeMap } from 'rxjs/operators';
import { IssueService } from '@app/core/services/issue.service';


@Component({
    selector: 'app-create-referral-form',
    templateUrl: './create-referral-form.component.html',
    styleUrls: ['./create-referral-form.component.scss']
})
export class CreateReferralFormComponent implements OnInit, OnDestroy {

    readonly referralTerm = environment.configuration.terminology.referral;
    readonly issueTerm = environment.configuration.terminology.issue;
    readonly organisationTerm = environment.configuration.terminology.organisation;
    
    @Input() client: Client;
    @Input() case: Case;

    @Output() backClicked = new EventEmitter();

    referralForm: FormGroup;
    currentUser: User;
    organisation: Organisation;
    subscription = new Subscription();
    organisationFilters = new OrganisationFilters();
    newReferral: Referral;
    issue: Issue;

    constructor(
        private currentUserService: CurrentUserService,
        private modalService: ModalService,
        private bsModalService: BsModalService,
        private organisationService: OrganisationService,
        private referralService: ReferralService,
        private referralFormService: ReferralFormService,
        private router: Router,
        private issueService: IssueService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.subscription.add(
            this.currentUserService.getUser().subscribe((user) => {
                this.currentUser = user;
            })
        );
        this.referralForm = this.referralFormService.createForm();
        this.subscription.add(this.referralForm.get('issue').valueChanges.subscribe((response) => {
            const organisationControl = this.referralForm.get('organisation');
            if (!response) {
                organisationControl.disable();
            } else {
                organisationControl.enable();
            }
            organisationControl.setValue(null);
        }));
        this.setOrganisationFilters();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getOrganisation(organisation: Organisation): void {
        if (organisation) {
            this.organisationService.get(organisation.uid)
            .subscribe((res) => {
                this.organisation = res;
            });
        } else {
            this.organisation = null;
        }
    }

    createReferral(referral: Referral): void {
        referral.case = this.case;
        this.referralService.add(referral).pipe(
            mergeMap((createdReferral) => {
                this.newReferral = createdReferral;
                return forkJoin(
                    this.issueService.mapResource(this.newReferral.issue),
                    this.organisationService.mapResource(this.newReferral.organisation)
                );
            })
        ).subscribe(([issue, organisation])=>{
            this.issue = issue.value;
            this.referralService.emitUpdated();
            this.showReferralCreatedModal(this.newReferral);
            this.toastService.add({title: 'Success', message: `${this.referralTerm} added.`, type: 'success'});
        },(err) => {
            let errMessage: string;
            if (err.error.non_field_errors) {
                errMessage = err.error.non_field_errors[0];
            } else {
                errMessage = 'Error occurred.';
            }
            this.toastService.add({title: 'Error', type: 'error', message: errMessage});
            },
        );
    }

    onSaveClicked(): void {
        const newReferral = this.referralFormService.from(this.referralForm);
        this.createReferral(newReferral);
    }

    onBackClicked(): void {
        this.backClicked.emit();
    }

    addNewReferral = () => {
        this.modalService.closeAll();
        this.referralForm = this.referralFormService.createForm();
        this.organisation = null;
    }

    viewNewReferral(newReferral: Referral): void {
        this.modalService.closeAll();
        this.router.navigate([environment.links.referral, newReferral.uid]);
    }

    showReferralCreatedModal(referral: Referral): void {
        this.modalService.create(
            this.bsModalService, (options: any) =>
                this.bsModalService.show(ReferralCreatedModalComponent, options),
            'lg',
            {
                case: this.case,
                client: this.client,
                referral,
                issue: this.issue,
                viewReferral: () => this.viewNewReferral(referral),
                addReferral: this.addNewReferral
            },
            false
        );
    }

    setOrganisationFilters(issue?: Issue): void {
        this.organisationFilters = new OrganisationFilters();
        this.organisationFilters.caseId = this.case.uid as any;
        this.organisationFilters.caseIssueId = issue?.uid as any;
    }

}
