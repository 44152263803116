<div class="row no-gutters nowrap">
    <div class="col-auto mr5">
        <app-organisation-logo [logoUrl]="organisation?.logo" [name]="organisation?.name" [squareLogo]="true"></app-organisation-logo>
    </div>
    <div class="col show-ellipsis d-flex flex-column justify-content-center">
        <div class="h2 mb0 font-weight-normal">
            {{ organisation?.name ? organisation.name : ('No ' + organisationTerm + ' Selected') }}
        </div>
        <div *ngIf="organisation?.parent?.name" class="xs-text mt4 text-primary font-weight-bold card-subtitle">
            {{ organisation?.parent?.name ? organisation?.parent?.name : '-'}}
        </div>
    </div>
</div>