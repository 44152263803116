import { Expose, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CaseConsent } from './caseConsent.model';
import { Client } from './client.model';
import { Organisation } from './organisation.model';
import { Issue } from './issue.model';
import { ResolutionStatus, StatusState } from './enums/enums.model';
import { BaseModel } from './base-model';

export class Case extends BaseModel {

    @Type(() => Client)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    client: Client;

    @Expose({name: 'referred_from'})
    @Type(() => Organisation)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    organisationSent: Organisation;

    @Expose({name: 'is_case_consent_overdue'})
    isCaseConsentOverdue: boolean;

    @Expose({name: 'referrals_count'})
    referralsCount: number;

    @Expose({name: 'allow_email_notifications'})
    allowEmailNotifications: boolean;

    @Expose({name: 'case_consents'})
    @Type(() => CaseConsent)
    caseConsents: CaseConsent[];

    tags: string;

    @Type(() => Issue)
    issues: Issue[];

    @Expose({name: 'created_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DDTHH:mmZ') : null, { toPlainOnly: true })
    createdAt: Moment;

    @Expose({name: 'updated_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DDTHH:mmZ') : null, { toPlainOnly: true })
    updatedAt: Moment;

    @Expose({name: 'case_number'})
    caseNumber: number;

    @Expose({name: 'resolution_status'})
    resolutionStatus: ResolutionStatus;

    @Expose({name: 'current_status'})
    currentStatus: StatusState;

}
