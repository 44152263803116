import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss']
})
export class FieldComponent {

    @Input() type: 'light' | 'regular' | 'solid' = 'light';
    @Input() icon: string;
    @Input() size: 'sm' | 'md' | 'lg' = 'md';
    @Input() alignment: 'start' | 'end' | 'center' = 'start';
    @Input() placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'bottom';
    @Input() tooltipText: string;
    @Input() hasInput = false;
    @Input() ngxContainer = '#page-container';
    @Input() showBottomMargin = true;

    constructor() { }

}
