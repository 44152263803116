import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {
    constructor() { }

    static eitherOr(first: string, second: string): (formGroup: FormGroup) => ValidationErrors {
        return (fg: FormGroup): ValidationErrors | null => {
            const value1 = fg.get(first).value != null;
            const value2 = fg.get(second).value != null;
            return value1 || value2
                ? null
                : ({
                    eitherOr: {
                        customErrorMessage:
                            'Either Postcode or Local Authority must be provided',
                    },
                } as ValidationErrors);
        };
    }

    static oneMustHaveTwo(first: string, second: string): (formGroup: FormGroup) => ValidationErrors {
        return (fg: FormGroup): ValidationErrors | null => {
            const value1 = fg.get(first).value != null;
            const value2 = fg.get(second).value != null;
            if (value1 || value2) {
                return value1 && value2
                    ? null
                    : ({
                        oneMustHaveTwo: {
                            customErrorMessage:
                                'Postcode and First line of Address are required',
                        },
                    } as ValidationErrors);
            } else {
                return null;
            }
        };
    }

    static lastMustBeHigher(first: string, last: string): (formGroup: FormGroup) => ValidationErrors {
        return (fg: FormGroup): ValidationErrors | null => {
            const firstValue = fg.get(first);
            const lastValue = fg.get(last);

            if (
                firstValue.value &&
                firstValue.statusChanges &&
                lastValue.value &&
                lastValue.dirty &&
                lastValue.statusChanges
            ) {
                if (firstValue.value > lastValue.value) {
                    lastValue.setErrors({ age: false });
                    return {
                        lastMustBeHigher: {
                            customErrorMessage:
                                '"to" field must be higher than "from"',
                        },
                    } as ValidationErrors;
                } else {
                    return null;
                }
            } else {
                return;
            }
        };
    }

    // ** Form Control validators **
    static websiteValidator(): ValidatorFn {
        return (formControl: FormControl): ValidationErrors | null => {
            const websiteRegex = new RegExp(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                'g'
            );
            const value = formControl.value;

            if (!value || value?.match(websiteRegex)?.length > 0) {
                return null;
            } else {
                return {
                    customErrorMessage: 'URL is invalid',
                } as ValidationErrors;
            }
        };
    }

    static phoneNumberCheck(): ValidatorFn {
        return (formControl: FormControl): ValidationErrors | null => {
            const websiteRegex = new RegExp(
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                'g'
            );
            const value = formControl.value;

            if (!value || value?.match(websiteRegex)?.length > 0) {
                return null;
            } else {
                return {
                    customErrorMessage: 'Phone number is invalid',
                } as ValidationErrors;
            }
        };
    }

    // this will check if a date is a valid date of birth
    static dateOfBirthCheck(day: string, month: string, year: string): ValidationErrors | null {
        return (fg: FormGroup): ValidationErrors | null => {
            const YYYY: string = fg.get(year).value;
            const MM: string = fg.get(month).value;
            const DD: string = fg.get(day).value;

            if (YYYY !== null && MM !== null && DD !== null) {
                const dobString = `${YYYY}-${MM}-${DD}`;
                const dateMoment = moment(dobString, 'YYYY MMM DD');
                const today: Date = new Date();

                if (dateMoment.isAfter(today)) {
                    fg.get(day).setErrors({ futureDob: false });
                    fg.get(month).setErrors({ futureDob: false });
                    fg.get(year).setErrors({ futureDob: false });
                    return;
                } else if (
                    dateMoment.invalidAt() === 1 ||
                    dateMoment.invalidAt() === 2
                ) {
                    fg.get(day).setErrors({ date: false });
                    fg.get(month).setErrors({ date: false });
                    fg.get(year).setErrors({ date: false });
                    return;
                } else {
                    fg.get(day).setErrors(null);
                    fg.get(month).setErrors(null);
                    fg.get(year).setErrors(null);
                    return;
                }
            } else {
                return null;
            }
        };
    }
}
