import { Component, Input } from '@angular/core';
import { BreadCrumb } from '@app/shared/models/breadCrumb.model';

@Component({
    selector: 'app-directory',
    templateUrl: './directory.component.html',
    styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent {

    @Input() directory: (string | BreadCrumb)[];
    @Input() mainTitle: string;
    @Input() hasCustomMainTitle = false;
    @Input() subtitle: string;

    constructor() { }

    isObject(breadCrumb): boolean {
        return typeof breadCrumb === 'object';
    }

    getTextColor(breadCrumb: BreadCrumb): string {
        return breadCrumb.color ? `text-${breadCrumb.color}` : 'text-body';
    }

}
