import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { AuthenticationCoreService } from 'authentication-core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
    selector: 'app-session-inactive-modal',
    templateUrl: './session-inactive-modal.component.html',
    styleUrls: ['./session-inactive-modal.component.scss']
})
export class SessionInactiveModalComponent implements OnDestroy {

    subscriberTime: number;

    private modalTimer: Observable<number>;
    private timerSubscription = new Subscription();

    constructor(
        public authenticationService: AuthenticationCoreService,
        private currentUserService: CurrentUserService,
        private router: Router,
        private modalRef: BsModalRef,
    ) {
        this.modalTimer = timer(0, 1000);
        this.timerSubscription = this.modalTimer.subscribe(value => {
            this.subscriberTime = (this.authenticationService.getWarningPeriodInSeconds()) - value;
        });
    }

    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
        this.modalTimer = null;
        this.subscriberTime = 0;
    }

    formatSessionTimeRemaining = (secondsRemaining: number) => Math.floor(secondsRemaining / 60) + 'm ' + secondsRemaining % 60 + 's';

    keepLoggedIn(): void {
        this.authenticationService.keepLoggedIn();
        this.close();
    }

    logOut(): void {
        this.currentUserService.logOut().subscribe(() => {
            this.router.navigate(['/authentication/login']);
            this.close();
        });
    }

    close = () => this.modalRef.hide();
}
