import { Component, OnInit } from '@angular/core';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { PagedResponse } from 'django-rest-core';
import { MetadataEnum } from '@app/shared/models/metadataEnum.model';
import { Observable, of } from 'rxjs';
import { ResolutionStatus } from '@app/shared/models/enums/enums.model';

@Component({
    selector: 'app-resolution-status-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class ResolutionStatusSelectComponent extends SelectBaseComponent<MetadataEnum> implements OnInit {

    constructor(
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.bindToField = 'title';
        this.bindToValue = 'value';
        this.getData(null).subscribe((response) => {
            this.options = response.results;
        });
    }

    getData(page: number): Observable<PagedResponse<MetadataEnum>> {
        const data = new PagedResponse<MetadataEnum>();
        data.results = [];

        for (const resolutionStatus of Object.values(ResolutionStatus)) {
            switch (resolutionStatus) {
                case ResolutionStatus.RESOLVED:
                    data.results.push(new MetadataEnum(ResolutionStatus.RESOLVED, 'Resolved'));
                    break;
                case ResolutionStatus.UNRESOLVED:
                    data.results.push(new MetadataEnum(ResolutionStatus.UNRESOLVED, 'Unresolved'));
                    break;
            }
        }

        return of(data);
    }

}
