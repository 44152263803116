import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationCoreGuard, SessionCoreInterceptor } from 'authentication-core';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        AuthenticationCoreGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: SessionCoreInterceptor,
        //     multi: true
        // }
    ]
})
export class CoreModule { }
