import { Component, OnInit } from '@angular/core';
import { TagService } from '@app/core/services/tag.service';
import { BaseOpenSelectComponent } from '../base-open-select/base-open-select.component';

@Component({
    selector: 'app-tag-open-select',
    templateUrl: '../base-open-select/base-open-select.component.html',
})
export class TagOpenSelectComponent extends BaseOpenSelectComponent<string> implements OnInit {

    get activeUids(): string[] {
        return this.formControl?.value ?? [];
    }

    constructor(
        private tagService: TagService,
    ) {
        super();
    }

    initialize(): void {
        this.isDataObject = false;
    }

    getData(page: number, filter?): void {
        this.tagService.getPagedList(page, filter).subscribe((response) => {
            this.data = response.results.map(item => item.name);
        });
    }

}
