import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnChanges {

    @Output() onBlurEvent = new EventEmitter();

    @Input() labelText: string;
    @Input() showRequired = true;
    @Input() placeholder = '';
    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() marginBottom = true;
    @Input() rightText: string;

    formControl: FormControl;
    isRequired: boolean;

    constructor() { }

    ngOnChanges(): void {
        const formControl = this.formGroup?.get(this.formControlKey) as FormControl;
        this.formControl = formControl ?? new FormControl();
        if (this.formControl.validator) {
            this.isRequired = this.formControl?.validator('' as any)?.required;
        } else {
            this.isRequired = false;
        }
    }

    onBlur(): void {
        this.onBlurEvent.emit();
    }

}
