import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SideMenuItem } from '@app/shared/models/sideMenuItem.model';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

    @Input() navItems: SideMenuItem[] = [];

    @Output() itemSelected = new EventEmitter<SideMenuItem>();

    constructor() { }

    onSelected(item: SideMenuItem): void {
        this.itemSelected.emit(item);
    }

    onSubItemSelected(subItem: any, item: SideMenuItem) {
        item.activeSubItemType = subItem.type;
        this.onSelected(item);
    }

}
