<div class="card h-100">
    <div class="card-body d-flex flex-column justify-content-between">
        <div>
            <div class="mb6">
                <app-organisation-card-header [organisation]="organisation"></app-organisation-card-header>
            </div>
            <div class="mb6">
                <app-organisation-basic-info [organisation]="organisation"></app-organisation-basic-info>
            </div>
            <div *ngIf="showCriteria" class="mb5">
                <div class="xs-label">CRITERIA</div>
                <app-description-box [linesOfText]="7" [text]="organisation?.criteria ? organisation.criteria : '-' "
                    [alwaysHideControls]="true"></app-description-box>
            </div>
            <div *ngIf="showServiceOffer" class="mb5">
                <div class="xs-label">SERVICE OFFER</div>
                <app-description-box [linesOfText]="7"
                    [text]="organisation?.serviceOffer ? organisation.serviceOffer : '-' "
                    [alwaysHideControls]="true"></app-description-box>
            </div>
        </div>
        <div *ngIf="organisation" class="d-flex justify-content-between align-items-center">
            <a *ngIf="!hasCustomButtons; else customButtons" class="btn btn-primary" [href]="getViewLink()">View</a>
            <ng-template #customButtons>
                <ng-content select="[buttons]"></ng-content>
            </ng-template>
            <app-circle-label
                [label]="getCircleLabel()"
                [circleTheme]="organisation?.acceptingReferrals ? 'primary' : 'danger'"></app-circle-label>
        </div>
    </div>
</div>