import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedResponse } from 'django-rest-core';
import { Observable } from 'rxjs';
import { Referral } from '@app/shared/models/referral.model';
import { ReferralFilters } from '@app/shared/models/filters/referralFilters.model';
import { ReferralQuestionnaire } from '@app/shared/models/referralQuestionnaire.model';
import { BaseReadWriteService } from '../base-read-write.service';
import { CaseSummary } from '@app/shared/models/caseSummary.model';
import { map } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { ReferralStatusUpdate } from '@app/shared/models/referralCurrentStatus.model';

@Injectable({
    providedIn: 'root'
})
export class ReferralService extends BaseReadWriteService<Referral, ReferralFilters>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'referrals';
        this.exampleObject = new Referral();
    }

    updateReferralStatus(id: string | number, status: ReferralStatusUpdate): Observable<Partial<Referral>> {
        return this.setTypeOnResponse(this.http.post(`${this.getApiRoot()}${id}/update_status/`, classToPlain(status)), new Referral());
    }

    getReferralQuestionnaire(referralId: string | number): Observable<PagedResponse<ReferralQuestionnaire>> {
        return this.setTypeOnResponse(this.get(referralId, 'questionnaire', false), new ReferralQuestionnaire());
    }

    getCaseSummary(page: number, filter?: ReferralFilters, sortBy?: string): Observable<CaseSummary> {
        return this.getPagedList(page, filter, sortBy, 'case_summary', false).pipe(
            map((response) => {
                return plainToClass(CaseSummary, response.results as any);
            })
        );
    }

}
