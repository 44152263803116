import { Component, OnInit } from '@angular/core';
import { IssueService } from '@app/core/services/issue.service';
import { Issue } from '@app/shared/models/issue.model';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-referral-issues-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class ReferralIssuesSelectComponent extends SelectBaseComponent<Issue> implements OnInit {

    constructor(
        private issueService: IssueService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.issueService;
    }

}
