import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticationCoreGuard } from 'authentication-core';
import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { DefaultPageLayoutComponent } from './shared/components/layouts/default-page-layout/default-page-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
        canActivate: [AuthenticationCoreGuard],
    },
    {
        path: 'authentication',
        loadChildren: () =>
            import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: environment.links.dashboard.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Home' }
    },
    {
        path: environment.links.organisations.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/organisation/organisation.module').then(m => m.OrganisationModule),
        data: { title: environment.configuration.terminology.organisationPlural }
    },
    {
        path: environment.links.clients.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/client/client.module').then(m => m.ClientModule),
        data: { title: environment.configuration.terminology.clientPlural }
    },
    {
        path: environment.links.users.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/user/user.module').then(m => m.UserModule),
        data: { title: 'Users' }
    },
    {
        path: environment.links.referrals.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/referral/referral.module').then(m => m.ReferralModule),
        data: { title: environment.configuration.terminology.referralPlural }
    },
    {
        path: environment.links.cases.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/case/case.module').then(m => m.CaseModule),
        data: { title: environment.configuration.terminology.casePlural }
    },
    {
        path: environment.links.messages.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/messages/messages.module').then(m => m.MessagesModule),
        data: { title: 'Messages' }
    },
    {
        path: environment.links.settings.slice(1),
        canActivate: [AuthenticationCoreGuard],
        loadChildren: () =>
            import('./modules/settings/settings.module').then(m => m.SettingsModule),
        data: { title: 'Settings' }
    },
    {
        path: '',
        component: DefaultPageLayoutComponent,
        children: [
            {
                path: environment.links.pageNotFound.slice(1),
                component: PageNotFoundComponent,
                data: { title: 'Oops! We Couldn\'t Find That!' }
            },
        ]
    },
    {
        path: '**',
        redirectTo: environment.links.pageNotFound.slice(1),
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
