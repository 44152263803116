import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Event, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TawkService } from './core/services/tawk.service';
import { CurrentUserService } from './core/services/current-user.service';
import { GoogleTagMangerService } from './core/services/google-tag-manager.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    firstLoad = true;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private tawkService: TawkService,
        private currentUserService: CurrentUserService,
        private googleTagManagerService: GoogleTagMangerService
    ) { }

    ngOnInit(): void {
        this.router.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route: ActivatedRoute) => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }),
            filter((route: ActivatedRoute) => route.outlet === 'primary'),
            mergeMap((route: ActivatedRoute) => route.data)
        ).subscribe((data: Data) => {
            this.titleService.setTitle(
                (environment.configuration.adminOrganisationAbbreviation ? `${environment.configuration.adminOrganisationAbbreviation} ` : '')
                + `Referral System` + (data.title ? ` | ${data.title}` : '')
            );
        });
        this.currentUserService.getUser().subscribe((user) => {
            this.tawkService.load(environment.configuration.tawkWebchatCode);
            this.tawkService.updateTawkUser(user);
        })
        this.googleTagManagerService.load(environment.configuration.googleTagManagerId);
    }

}
