import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent {

    @Input() data: any[];
    @Input() isDataObject = true;
    @Input() bindToLabel = 'name';
    @Input() theme: 'default' | 'secondary' = 'default';
    @Input() activeTheme: 'default' | 'secondary' = 'default';
    @Input() activeUids: string[] = [];
    @Input() disabledUids: string[] = [];
    @Input() hasClickEvent = false;
    @Input() hasBorder = false;

    @Output() clickEvent = new EventEmitter<any>();

    constructor() { }

    onClick(item: any): void {
        this.clickEvent.emit(item);
    }

}
