<div class="row">
    <div class="{{ customPadding }} col-12" [ngClass]="{
            'col-md-8': valueSelected && !cardWrap,
            'col-lg-12 col-xl-8 col-md-12 col-sm-12': cardWrap && valueSelected}">
        <div class="mb3">
            <ng-content select="[header]"></ng-content>
        </div>
        <ng-content select="[main]"></ng-content>
    </div>
    <div *ngIf="valueSelected" [ngClass]="{'col-4': !cardWrap, 'col-xl-4 col-12' : cardWrap }" [class]="customPadding">
        <ng-content select="[right]"></ng-content>
    </div>
</div>