import { Component, Input } from '@angular/core';
import { RadioOption } from '@app/shared/models/radio-option.model';
import { ReferralClosureReasonService } from '@app/core/services/referral/referral-closure-reason.service';
import { ReferralClosureReasonFormService } from '@app/core/services/forms/referral-closed-reasons-form.service';
import { environment } from 'src/environments/environment';
import { ToastService } from '@app/core/services/toast.service';
import { BaseFormComponent } from '../base-form/base-form.component';
import { ReferralClosureReason } from '@app/shared/models/referralClosureReason.model';

@Component({
    selector: 'app-referral-closed-reasons-form',
    templateUrl: './referral-closed-reasons-form.component.html',
    styleUrls: ['./referral-closed-reasons-form.component.scss']
})
export class ReferralClosedReasonsFormComponent extends BaseFormComponent {

    readonly referralTerm = environment.configuration.terminology.referral;
    readonly radioOptions: RadioOption[] = [
        { name: 'Positive', value: 'P' },
        { name: 'Negative', value: 'N' }
    ]

    _closedReason: ReferralClosureReason;
    @Input() set closedReason(closedReason: ReferralClosureReason) {
        this._closedReason = closedReason;
        this.initialize();
    }

    @Input() isDirty = false;

    constructor(
        protected toastService: ToastService,
        private referralClosureReasonFormService: ReferralClosureReasonFormService,
        private referralClosureReasonService: ReferralClosureReasonService,
    ) {
        super(toastService);
    }

    initialize(): void {
        this.object = this._closedReason;
        this.objectType = `${this.referralTerm} Closure Reason`;
        this.objectService = this.referralClosureReasonService;
        this.formService = this.referralClosureReasonFormService;
        this.formGroup = this.formService.createForm(this.object);
        if (this.isDirty) {
            this.formGroup.markAsDirty();
        }
    }

}
