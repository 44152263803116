import { Component, OnInit } from '@angular/core';
import { TagService } from '@app/core/services/tag.service';
import { Tag } from '@app/shared/models/tag.model';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-tag-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class TagSelectComponent extends SelectBaseComponent<Tag> implements OnInit {

    constructor(
        private tagService: TagService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.tagService;
    }

}
