import { Expose, Transform } from 'class-transformer';
import { ResourceLink } from 'django-rest-core';
import { QuestionnaireResponse } from '../questionnaireResponse.model';
import { Referral } from '../referral.model';
import { Note } from './note.model';

export class ReferralQuestionnaireNote extends Note {

    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<Referral>(params.value) : null, { toClassOnly: true })
    referral: ResourceLink<Referral>;

    @Expose({name: 'case_questionnaire_response'})
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<QuestionnaireResponse>(params.value) : null, { toClassOnly: true })
    caseQuestionnaireResponse: ResourceLink<QuestionnaireResponse>;

}
