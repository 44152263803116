import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from 'django-rest-core';
import { Gender } from '@app/shared/models/gender.model';
import { BaseReadWriteService } from './base-read-write.service';

@Injectable({
    providedIn: 'root'
})
export class GenderService extends BaseReadWriteService<Gender, IFilter>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'genders';
        this.exampleObject = new Gender();
    }

}
