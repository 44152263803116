import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent implements OnChanges {

    @Input() labelText: string;
    @Input() hasTextWrap = true;
    @Input() showRequired = true;
    @Input() placeholder = '';
    @Input() uniqueId = '';
    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;

    formControl: FormControl;
    isRequired = false;

    constructor() { }

    ngOnChanges(): void {
        const formControl = this.formGroup?.get(this.formControlKey) as FormControl;
        this.formControl = formControl ?? new FormControl();
        if (this.formControl.validator) {
            this.isRequired = this.formControl?.validator('' as any)?.required;
        } else {
            this.isRequired = false;
        }
    }

    getId(): string {
        return `checkbox-${this.formControlKey}` + (this.uniqueId ? `-${this.uniqueId}` : '');
    }

}
