<form *ngIf="form" [formGroup]="form">

      <div class="form-group mb5">
        <input type="text"  
            class="form-control mb5"
            placeholder="Select date range..." 
            bsDaterangepicker 
            formControlName="{{controlName}}" 
            [bsConfig]="config" />
      </div>

  </form>