export enum StepState {
    ACTIVE = 1,
    INACTIVE = 2,
    COMPLETED = 3
}

export enum ClosureType {
    POSITIVE = 'P',
    NEGATIVE = 'N'
}

export enum StatusState {
    OPEN = 'O',
    CLOSED = 'C'
}

export enum ResolutionStatus {
    RESOLVED = 'R',
    UNRESOLVED = 'U'
}

export enum DashboardTimePeriod {
    WEEK = 1,
    MONTH = 2,
    ALLTIME = 3
}

export enum DashboardOrganisationType {
    SENT = 1,
    RECEIVED = 2
}