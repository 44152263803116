import { IFilter } from 'django-rest-core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ResolutionStatus, StatusState } from '../enums/enums.model';

export class CaseFilters implements IFilter {

    name: string;
    clientId: string;
    createdAt: Moment;
    createdFrom: Moment;
    createdTo: Moment;
    organisationId: string;
    organisationSentId: string;
    organisationReceivedId: string;
    allowEmails: boolean;
    resolutionStatus: ResolutionStatus;
    currentStatus: StatusState;

    constructor(
        createdAt?: Moment,
        createdFrom?: Moment,
        createdTo?: Moment,
        organisation?: string,
        organisationSent?: string,
        organisationReceivedId?: string
    ) {
        this.createdAt = createdAt;
        this.createdFrom = createdFrom;
        this.createdTo = createdTo;
        this.organisationId = organisation;
        this.organisationSentId = organisationSent;
        this.organisationReceivedId = organisationReceivedId;
    }

    public getSearchCriteriaString(): string {
        let search = '';
        if (this.name) {
            search += '&search=' + encodeURIComponent(this.name);
        }
        if (this.clientId) {
            search += '&client=' + encodeURIComponent(this.clientId);
        }
        if (this.organisationId) {
            search += '&organisation=' + this.organisationId;
        }
        if (this.organisationSentId) {
            search += '&organisation_sent=' + this.organisationSentId;
        }
        if (this.organisationReceivedId) {
            search += '&organisation_received=' + this.organisationReceivedId;
        }
        if (this.createdAt) {
            search += '&created_at__date=' + encodeURIComponent(this.createdAt.format('YYYY-MM-DD'));
        }
        if (this.createdFrom) {
            search += '&created_at_after=' + encodeURIComponent(this.createdFrom.format('YYYY-MM-DD'));
        }
        if (this.createdTo) {
            search += '&created_at_before=' + encodeURIComponent(this.createdTo.format('YYYY-MM-DD'));
        }
        if (this.resolutionStatus) {
            search += '&resolution_status=' + this.resolutionStatus;
        }
        if (this.currentStatus) {
            search += '&current_status=' + this.currentStatus;
        }
        if (this.allowEmails === false || this.allowEmails === true) {
            search += '&allow_email_notifications=' + this.allowEmails;
        }

        return search;
    }

    toUrl(): {[k: string]: any} {
        const urlParams: {[k: string]: any} = {};

        for (const key of Object.keys(this)) {
            urlParams[key] = null;
            const valid = (this[key] || this[key] === false || this[key] === 0);
            if (valid) {
                switch (key) {
                    case 'name':
                    case 'clientId':
                    case 'organisationReceivedId':
                    case 'organisationSentId':
                    case 'allowEmails':
                    case 'resolutionStatus':
                    case 'currentStatus':
                        urlParams[key] = this[key];
                        break;
                    case 'createdFrom':
                    case 'createdTo':
                    case 'createdAt':
                        urlParams[key] = this[key].format('YYYY-MM-DD');
                        break;
                }
            }
        }

        return urlParams;
    }

    fromUrl(queryParams): void {
        for (const key of Object.keys(queryParams)) {
            if (queryParams[key]) {
                switch (key) {
                    case 'name':
                    case 'clientId':
                    case 'organisationReceivedId':
                    case 'organisationSentId':
                    case 'allowEmails':
                    case 'resolutionStatus':
                    case 'currentStatus':
                        this[key as any] = queryParams[key];
                        break;
                    case 'createdFrom':
                    case 'createdTo':
                    case 'createdAt':
                        const newDate = moment(new Date(queryParams[key]), 'YYYY-MM-DD');
                        if (newDate.isValid() &&
                            newDate.isAfter(moment('01/01/2010', 'DD/MM/YYYY')) &&
                            newDate.isBefore(moment('31/12/2030', 'DD/MM/YYYY'))) {
                            this[key] = newDate.isValid() ? newDate : null;
                        }
                        break;
                }
            }
        }
    }
}
