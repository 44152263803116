<div class="add-container d-flex align-items-center"
	(focus)="onFocus()"
	(click)="clicked()"
	(keyup.enter)="clicked()"
	tabindex="{{ tabIndex }}">
	<div class="icon-container transition"
		[class.show-outline]="showIconOutline">
	    <i class="{{ type }} fa-{{ faIcon }}"></i>
	</div>
    <span *ngIf="text && !hideText"
    	class="show-ellipsis ml3">
        {{ text }}
        <span *ngIf="required" class="required-field">*</span>
    </span>
</div>