import { Component, Input } from '@angular/core';
import { Step } from '@app/shared/models/step.model';

@Component({
    selector: 'app-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent {

    @Input() step: Step;
    @Input() activeStepIndex: number;

    constructor() { }

}
