import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUploadService } from '@app/core/services/file-upload.service';
import { ToastService } from '@app/core/services/toast.service';
import { SystemFile } from '@app/shared/models/files/file.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {

    @ViewChild('fileInput') fileInput: ElementRef;

    @Output() onFileUpload = new EventEmitter<SystemFile>();

    @Input() text = 'Attach a File';
    @Input() faIcon = 'paperclip';
    @Input() validFileTypes: string[] = ['txt', 'pdf'];
    @Input() fileSizeMB = 1;

    loaded = true;
    spinnerName = 'file-spinner';

    constructor(
        private fileUploadService: FileUploadService,
        private spinnerService: NgxSpinnerService,
        private toastService: ToastService
    ) { }

    get accepts(): string {
        return this.validFileTypes?.map(item => `.${item}`).join(',');
    }

    onChange(event: any): void {
        const file: File = event.target.files[0];
        if (!this.isValidFileType(file)) {
            this.fileInput.nativeElement.value = null;
            this.toastService.add({
                type: 'error',
                title: 'Error',
                message: 'Invalid file type'
            });
            return;
        }

        const bytes = file.size;
        const megabytes = 1048576;
        if (bytes > (this.fileSizeMB * megabytes)) {
            this.fileInput.nativeElement.value = null;
            this.toastService.add({
                type: 'error',
                title: 'Error',
                message: 'File is too large, please select a file under 1MB.'
            });
            return;
        }

        const formData = new FormData();
        const blob = new Blob([file]);
        formData.append('file', blob, file.name);
        this.uploadFile(file);
    }

    isValidFileType(file: File): boolean {
        for (const fileType of this.validFileTypes) {
            if (file.type.includes(fileType)) {
                return true;
            }
        }
        return false;
    }

    uploadFile(file: File): void {
        this.loaded = false;
        this.spinnerService.show(this.spinnerName);
        this.fileUploadService.uploadFile(file).subscribe((fileUrl: string) => {
            const newSystemFile = new SystemFile();
            newSystemFile.title = file.name;
            newSystemFile.downloadUrl = fileUrl;
            this.onFileUpload.emit(newSystemFile);
            this.spinnerService.hide(this.spinnerName);
            this.loaded = true;
        }, () => {
            this.spinnerService.hide(this.spinnerName);
            this.loaded = true;
        });
    }

}
