<!-- <h2 *ngIf="step !== 1">Edit {{ clientTerm }} Details</h2> -->
<div *ngIf="step === 1">
    <!-- Edit Basic Info -->
    <app-client-basic-info-form 
        [client]="client"
        (saveSuccessful)="onEditSuccessful($event)"
        (backClicked)="handleBackClicked()">
    </app-client-basic-info-form>
</div>
<div *ngIf="step === 2">
    <!-- Edit Contact Details -->
    <app-form-card title="Edit {{clientTerm}} Contact Details">
        <app-client-contact-details-form body
            [client]="client"
            (saveSuccessful)="onEditSuccessful($event)"
            (backClicked)="handleBackClicked()">
        </app-client-contact-details-form>
    </app-form-card>
</div>
<div *ngIf="step === 3">
    <!-- Edit Address -->
    <app-form-card title="Edit {{clientTerm}} Address">
        <app-address-form body
            [client]="client"
            [hasFixedAddressCheckbox]="true"
            (saveSuccessful)="onEditSuccessful($event)"
            (backClicked)="handleBackClicked()">
        </app-address-form>
    </app-form-card>
</div>
