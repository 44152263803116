import { Component, Input, OnInit } from '@angular/core';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { IFilter, PagedResponse } from 'django-rest-core';
import { Observable, of } from 'rxjs';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-static-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class StaticSelectComponent extends SelectBaseComponent<any> implements OnInit {

    @Input() options: any[] = [];
    @Input() selectedOption: any;

    constructor(private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                control: [this.selectedOption]
            });
            this.formControlKey = 'control';
        }
        this.searchable = false;
    }

    getData(page: number, filter?: IFilter): Observable<PagedResponse<any>> {
        const pagedResponse =  new PagedResponse();
        pagedResponse.results = this.options;
        return of(pagedResponse);
    }

}