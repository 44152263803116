import { Component, Input, OnChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-google-maps-embed',
    templateUrl: './google-maps-embed.component.html',
    styleUrls: ['./google-maps-embed.component.scss'],
})
export class GoogleMapsEmbedComponent implements OnChanges {

    @Input() address: string;
    @Input() fullAddress = false;
    @Input() satellite = false;

    loading = true;

    mapUrl: string;

    ngOnChanges() {
        if (this.address) {
            this._parseUrl();
        }
    }

    private getQueryString() {
        if (this.address) {
            return this.address;
        }
    }

    private _parseUrl() {
        this.mapUrl = environment.google_maps.url
            .replace('{QUERY}', this.getQueryString())
            .replace('{APIKEY}', environment.google_maps.apiKey)
            .replace('{MAPTYPE}', this.satellite ? 'satellite' : 'roadmap');
        this.loading = false;
    }

}
