import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-navbar-buttons',
    templateUrl: './navbar-buttons.component.html',
    styleUrls: ['./navbar-buttons.component.scss']
})
export class NavbarButtonsComponent implements OnInit {

    readonly loginLink = environment.links.auth.login;
    readonly messagesLink = environment.links.messages;

    constructor(
        private currentUserService: CurrentUserService,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    logout(): void {
        this.currentUserService.logOut().subscribe(() => {
            this.router.navigate([this.loginLink]);
        });
    }

    onMessageClick(){
        this.router.navigate([this.messagesLink]);
    }

}
