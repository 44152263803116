<ng-template #labelTemplate ng-label-tmp let-item="item" let-clear="clear">
    <app-tag [text]="resolveItem(item)"
        [hasClickEvent]="isOptionsRemoveable"
        [showRemove]="isOptionsRemoveable"
        (clickEvent)="clear(item)"></app-tag>
</ng-template>

<ng-template #addNewTemplate ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option">
        <span (click)="addNew(searchTerm)" class="d-flex align-items-center">
            <i class="fas fa-xs fa-plus ml0 pr4"></i>Add "{{ searchTerm }}"
        </span>
    </div>
</ng-template>

<div [formGroup]="formGroup">
    <ng-select #select
        [items]="options"
        [multiple]="multiselect ? true : false"
        [virtualScroll]="true"
        [closeOnSelect]="multiselect ? false : true"
        [hideSelected]="multiselect ? true : false"
        [typeahead]="input$"
        [bindLabel]="bindToField"
        [bindValue]="bindToValue"
        [placeholder]="getPlaceholderText()"
        [formControlName]="formControlKey"
        (scroll)="onScroll($event)"
        (change)="selectedOptionsChange.emit($event)"
        [clearSearchOnAdd]="true"
        [clearable]="clearable"
        [searchable]="searchable"
        [appendTo]="appendTo"
        [tabIndex]="tabIndex"
        [class]="customClass">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <ng-container *ngTemplateOutlet="labelTemplate; context: { item: item, clear: clear }"></ng-container>
        </ng-template>
        <ng-template *ngIf="canAddNew" ng-notfound-tmp let-searchTerm="searchTerm">
            <ng-container *ngTemplateOutlet="addNewTemplate; context: { searchTerm: searchTerm }"></ng-container>
        </ng-template>
    </ng-select>
</div>
