import { Expose, Transform, Type } from 'class-transformer';
import { BaseModel, ResourceLink } from 'django-rest-core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Address } from './address.model';
import { Gender } from './gender.model';
import { LocalAuthority } from './localAuthority.model';

export class Client extends BaseModel {
    uid: string;

    title: string;

    @Expose({name: 'first_name'})
    firstName: string;

    @Expose({name: 'last_name'})
    lastName: string;

    @Expose({name: 'full_name'})
    fullName: string;

    @Expose({name: 'display_name'})
    displayName: string;

    @Type(() => Address)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    address: Address;

    @Expose({name: 'local_authority'})
    @Type(() => LocalAuthority)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    localAuthority: LocalAuthority;

    @Expose({name: 'date_of_birth'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DD') : null, { toPlainOnly: true })
    dateOfBirth: Moment;

    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    gender: Gender;

    @Expose({name: 'preferred_pronouns'})
    preferredPronouns: string;

    age: number;

    @Expose({name: 'nhs_number'})
    nhsNumber: string;

    @Expose({name: 'gp_info'})
    gpInfo: string;

    email: string;

    @Expose({name: 'primary_phone_number'})
    primaryPhoneNumber: string;

    @Expose({name: 'secondary_phone_number'})
    secondaryPhoneNumber: string;

    @Expose({name: 'leave_message'})
    leaveMessage: boolean;

    @Expose({name: 'leave_text'})
    leaveText: boolean;

    @Expose({name: 'no_fixed_abode'})
    noFixedAbode: boolean;

}
