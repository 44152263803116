import { Component, Input } from '@angular/core';
import { StatusFormService } from '@app/core/services/forms/status-form.service';
import { ToastService } from '@app/core/services/toast.service';
import { Status } from '@app/shared/models/status.model';
import { StatusService } from '@app/core/services/status.service';
import { BaseFormComponent } from '../base-form/base-form.component';
import { RadioOption } from '@app/shared/models/radio-option.model';

@Component({
    selector: 'app-statuses-form',
    templateUrl: './statuses-form.component.html',
    styleUrls: ['./statuses-form.component.scss']
})
export class StatusesFormComponent extends BaseFormComponent {

    readonly radioOptions: RadioOption[] = [
        { name: 'Open', value: 'O' },
        { name: 'Closed', value: 'C' }
    ]

    _status: Status;
    @Input() set status(status: Status) {
        this._status = status;
        this.initialize();
    }

    @Input() isDirty = false;

    constructor(
        protected toastService: ToastService,
        private statusFormService: StatusFormService,
        private statusService: StatusService,
    ) {
        super(toastService);
    }

    initialize(): void {
        this.object = this._status;
        this.objectType = 'Status';
        this.objectService = this.statusService;
        this.formService = this.statusFormService;
        this.formGroup = this.formService.createForm(this.object);
        if (this.isDirty) {
            this.formGroup.markAsDirty();
        }
    }

}
