import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationCoreService, LoginResponse, LogoutResponse } from 'authentication-core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, mergeMap, skip } from 'rxjs/operators';
import { StorageCoreService } from 'storage-core';
import { CurrentUserCoreService } from 'user-core';
import { User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService extends CurrentUserCoreService implements OnDestroy {


    readonly localStorageOrg = 'CURRENT_ORGANISTION';

    private subscriptions = new Subscription();
    private isAuthenticated = false;
    private waitingOnRequests = false;
    private isUserSet = false;
    private userSubject = new BehaviorSubject<User>(null);
    private userObserver = this.userSubject.asObservable().pipe(filter(val => val !== null));
    isCurrentOrgSet = false;
    private currentOrgSubject = new BehaviorSubject<string>(null);
    currentOrgObserver = this.currentOrgSubject.asObservable().pipe(filter(val => val !== null));

    constructor(
        protected http: HttpClient,
        protected storageService: StorageCoreService,
        protected authenticationService: AuthenticationCoreService,
        private router: Router
    ) {
        super(http, storageService, environment);
        // NOTE: authenticationService.isAuthenticated() doesn't check if token is valid
        this.isAuthenticated = this.authenticationService.isAuthenticated();
        if (this.isAuthenticated) {
            this.setUser();
        }
        this.watchUserChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setUser(): void {
        this.waitingOnRequests = true;
        this.get().pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403 || error.status === 404) {
                    localStorage.clear();
                    this.router.navigate([environment.links.auth.login]);
                    return of(null);
                 } else {
                    return throwError(error);
                 }
            })
        ).subscribe((user) => {
            if (user) {
                this.setUserSubject(user);
                this.isUserSet = true;
            }
            this.waitingOnRequests = false;
        }, () => this.waitingOnRequests = false);
    }

    getUser(): Observable<User> {
        this.isAuthenticated = this.authenticationService.isAuthenticated();
        if (!this.isAuthenticated) {
            this.isUserSet = false;
        }

        if (this.isAuthenticated && !this.waitingOnRequests && !this.isUserSet) {
            this.setUser();
        }

        return this.userObserver;
    }

    getAndSetUser(): void {
        this.setUser();
    }

    setCurrentOrganisationUid(organisationUid: string): void {
        this.isCurrentOrgSet = true;
        localStorage.setItem(this.localStorageOrg, organisationUid);
        this.currentOrgSubject.next(organisationUid);
    }

    getCurrentOrganisationUid(): string {
        const currentOrgUid = localStorage.getItem(this.localStorageOrg);
        if (currentOrgUid === 'undefined' || currentOrgUid === 'null' || currentOrgUid === '0') {
            return null;
        } else {
            return currentOrgUid;
        }
    }

    login(credentials: any): Observable<LoginResponse> {
        this.isUserSet = false;
        localStorage.clear();
        return this.authenticationService.login(credentials);
    }

    logOut(): Observable<LogoutResponse> {
        this.isUserSet = false;
        localStorage.clear();
        this.userSubject.next(null);
        return this.authenticationService.logOut();
    }

    private setUserSubject(user: any): void {
        this.userSubject.next(plainToClass(User, user as object));
    }

    private watchUserChanges(): void {
        this.subscriptions.add(this.hasUpdated().pipe(
            mergeMap(() => this.get())
        ).subscribe((user) => this.setUserSubject(user)));
    }
}
