import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

    @Input() hasProgressText = true;
    @Input() value: number;
    @Input() max = 100;
    @Input() decimalPlaces = 2;

    get width(): number {
        return Number(((this.value / this.max) * 100).toFixed(this.decimalPlaces));
    }

    constructor() { }

}
