import { Component, Input } from '@angular/core';
import { Organisation } from '@app/shared/models/organisation.model';

@Component({
    selector: 'app-organisation-basic-info',
    templateUrl: './organisation-basic-info.component.html',
    styleUrls: ['./organisation-basic-info.component.scss']
})
export class OrganisationBasicInfoComponent {

    @Input() organisation: Organisation;
    @Input() extraDetails = false;

    constructor() { }

}
