import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { ScrollService } from '@app/core/services/scroll.service';
import { User } from '@app/shared/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-default-page-layout',
    templateUrl: './default-page-layout.component.html',
    styleUrls: ['./default-page-layout.component.scss']
})
export class DefaultPageLayoutComponent implements OnInit {

    readonly dashboardLink = environment.links.dashboard;
    readonly createLink = environment.links.createReferral;
    readonly casesLink = environment.links.cases;
    readonly referralsLink = environment.links.referrals;
    readonly organisationsLink = environment.links.organisations;
    readonly usersLink = environment.links.users;
    readonly settingsLink = environment.links.settings;
    readonly clientsLink = environment.links.clients;
    readonly clientsTerm = environment.configuration.terminology.clientPlural;
    readonly casesTerm = environment.configuration.terminology.casePlural;
    readonly referralsTerm = environment.configuration.terminology.referralPlural;
    readonly organisationsTerm = environment.configuration.terminology.organisationPlural;

    mobileScreenSize = false;
    sidebarCollapsed = true;
    user: User;
    navItems: NavItem[] = [
        {
            title: 'Home',
            icon: 'objects-column',
            route: this.dashboardLink,
            active: true,
            isValid: true
        },
        {
            title: this.clientsTerm,
            icon: 'id-card',
            route: this.clientsLink,
            active: false,
            isValid: false
        },
        {
            title: this.casesTerm,
            icon: 'folder-closed',
            route: this.casesLink,
            active: false,
            isValid: true
        },
        {
            title: this.referralsTerm,
            icon: 'folder-tree',
            route: this.referralsLink,
            active: false,
            isValid: true
        },
        {
            title: this.organisationsTerm,
            icon: 'buildings',
            route: this.organisationsLink,
            active: false,
            isValid: true
        },
        {
            title: 'Reports',
            icon: 'chart-pie',
            route: '',
            active: false,
            isValid: true
        },
        {
            title: 'Settings',
            icon: 'gear',
            route: this.settingsLink,
            active: false,
            isValid: false
        },
    ];

    constructor(
        private router: Router,
        private currentUserService: CurrentUserService,
        private scrollService: ScrollService
    ) { }

    get caseTerm() {
        return environment.configuration.terminology.case;
    }

    get referralTerm() {
        return environment.configuration.terminology.referral;
    }

    get caseTooltipText(): string {
        return this.sidebarCollapsed ? `Create ${this.caseTerm}` : '';
    }

    @HostListener('window:resize', ['$event.target'])
    onResize(window: Window): void {
        if (window.innerWidth < environment.mobileScreenSize) {
            this.mobileScreenSize = true;
        } else {
            this.mobileScreenSize = false;
        }
    }

    ngOnInit(): void {
        this.onResize(window);
        this.currentUserService.getUser().subscribe((user) => {
            this.user = user;
            this.setActive();
            if (this.user.isSiteAdmin || this.user.isOwner) {
                this.getNavItem(this.clientsTerm).isValid = true;
                this.getNavItem('Settings').isValid = true;
            }
        });
    }

    setActive(): void {
        this.resetActiveNavItems();
        const baseRoute = '/' + this.router.url.split('/')[1].split('?')[0];
        switch (baseRoute) {
            case this.clientsLink:
                this.getNavItem(this.clientsTerm).active = true;
                if (!this.user.isSiteAdmin && !this.user.isOwner) {
                    this.routeTo(this.dashboardLink);
                }
                break;
            case this.casesLink:
                this.getNavItem(this.casesTerm).active = true;
                break;
            case this.referralsLink:
                this.getNavItem(this.referralsTerm).active = true;
                break;
            case this.organisationsLink:
                this.getNavItem(this.organisationsTerm).active = true;
                break;
            case environment.links.settings:
                this.getNavItem('settings').active = true;
                if (!this.user.isSiteAdmin && !this.user.isOwner) {
                    this.routeTo(this.dashboardLink);
                }
                break;
            case this.dashboardLink:
            default:
                this.getNavItem('home').active = true;
                break;
        }
    }

    getNavItem(title: string): NavItem {
        return this.navItems.find(item => item.title.toLowerCase() === title.toLowerCase());
    }

    resetActiveNavItems(): void {
        this.navItems.map(x => x.active = false);
    }

    routeTo(link: string): void {
        this.router.navigate([link]);
    }

    createReferral(): void {
        this.router.navigate([this.createLink]);
    }

    isActive(index: number): boolean {
        const baseUrl = this.router.url.split('?')[0];
        switch (index) {
            case 1:
                return baseUrl === this.dashboardLink;
            case 2:
                return baseUrl === this.referralsLink;
            case 3:
                return baseUrl === this.organisationsLink;
            case 4:
                return baseUrl === this.usersLink;
        }
        return false;
    }

    toggleSidebar(): void {
        this.sidebarCollapsed = !this.sidebarCollapsed;
    }

    onDefaultPageScroll(): void {
        this.scrollService.emitScroll();
    }

}

class NavItem {
    title: string;
    icon: string;
    readonly route: string;
    active: boolean;
    isValid: boolean;
}
