import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Referral } from '@app/shared/models/referral.model';
import { FormsBaseService } from './forms-base.service';

@Injectable({
    providedIn: 'root'
})
export class ReferralFormService extends FormsBaseService {

    constructor(protected formBuilder: FormBuilder) {
        super(formBuilder);
      }

    getForm(): FormGroup {
        return this.formBuilder.group({
            referrals: this.formBuilder.array([this.createForm()])
        });
    }

    createForm(referral?: Referral): FormGroup {
        return this.formBuilder.group({
            issue: [referral?.issue, [Validators.required]],
            organisation: [{ value: referral?.organisation, disabled: !referral?.issue }, [Validators.required]],
            isUrgent: [referral?.isUrgent ?? false],
        });
    }

    public from(referralForm: FormGroup, referral = new Referral()): Referral {
        const formData = referralForm.getRawValue();

        referral.issue = formData.issue;
        referral.organisation = formData.organisation;
        referral.isUrgent = formData.isUrgent;

        return referral;
    }
}
