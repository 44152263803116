import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthenticationCoreModule } from 'authentication-core';
import { UserCoreModule } from 'user-core';
import { environment } from 'src/environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ConfigurationService } from './core/services/configuration.service';
import { TawkService } from './core/services/tawk.service';
import { GoogleTagMangerService } from './core/services/google-tag-manager.service';

const configInitializerFn = (configService: ConfigurationService) => {
    return () => {
        return configService.getConfiguration();
    };
};

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        AuthenticationCoreModule.forRoot(environment, environment.authentication.config),
        UserCoreModule.forRoot(environment),
        FontAwesomeModule,
        BrowserAnimationsModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: configInitializerFn,
            multi: true,
            deps: [ConfigurationService]
        },
        TawkService,
        GoogleTagMangerService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
