<div dropdown #dropdown="bs-dropdown"
    class="btn-group"
    [container]="ngxContainer"
    [insideClick]="allowInsideClicks"
    [autoClose]="autoClose">
    <span dropdownToggle
        (click)="clicked($event)"
        class="d-inline-block"
        [class.text-white]="!iconColourDark">
        <i *ngIf="!hasCustomTrigger; else trigger"
            class="fa-regular fa-{{ faSize }} fa-ellipsis-v pl3 pr3 -mr3 cursor-pointer"></i>
        <ng-template #trigger>
            <ng-content select="[content]"></ng-content>
        </ng-template>
    </span>
    <div *dropdownMenu class="dropdown-menu"
        [class.dropdown-menu-right]="dropRight"
        role="menu">
        <div class="list-group-flush">
            <ng-content select="[items]"></ng-content>
        </div>
    </div>
</div>