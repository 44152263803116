import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-list-with-detail-page',
    templateUrl: './list-with-detail-page.component.html',
    styleUrls: ['./list-with-detail-page.component.scss']
})
export class ListWithDetailPageComponent {

    @Input() customPadding = 'p5';
    @Input() valueSelected = false;
    @Input() cardWrap = false;

    constructor() { }

}
