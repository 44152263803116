import { Component, OnInit } from '@angular/core';
import { GenderService } from '@app/core/services/gender.service';
import { Gender } from '@app/shared/models/gender.model';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-gender-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class GenderSelectComponent extends SelectBaseComponent<Gender> implements OnInit {

    constructor(
        private genderService: GenderService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.genderService;
    }

}
