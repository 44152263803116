<div class="search-container d-flex align-items-center white-background-colour transition open">
    <span class="search-icon transition d-flex align-items-center cursor-pointer mr5" (click)="onBlur()">
        <i class="fa-regular fa-search"></i>
    </span>
    <input #searchBar
        [(ngModel)]="searchTerm"
        (ngModelChange)="onSearchChanged($event)"
        (blur)="onBlur()"
        (keydown.enter)="onBlur()"
        type="text"
        class="search-input form-control transition"
        [placeholder]="searchBy"
        aria-label="Search"
        autocomplete="off"
        [tabindex]="tabIndex">
</div>