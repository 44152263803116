import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '@app/shared/models/address.model';
import { Client } from '@app/shared/models/client.model';
import { Subscription } from 'rxjs';
import { FormsBaseService } from './forms-base.service';

@Injectable({
    providedIn: 'root'
})
export class AddressFormService extends FormsBaseService {

    constructor(
        protected formBuilder: FormBuilder
    ) {
        super(formBuilder);
    }

    getClientAddressForm(client?: Client): FormGroup {
        const formGroup = this.getAddressForm(client?.address, client?.noFixedAbode);
        formGroup.addControl(
            'noFixedAbode',
            this.formBuilder.control(client?.noFixedAbode ?? false)
        );
        formGroup.addControl(
            'localAuthority',
            this.formBuilder.control(client?.localAuthority, Validators.required)
        );
        return formGroup;
    }

    getAddressForm(address?: Address, noFixedAbode = false): FormGroup {
        const validator = !noFixedAbode ? Validators.required : null;
        return this.formBuilder.group({
            uid: [address?.uid],
            url: [address?.url],
            postcode: [address?.postcode, validator],
            firstLine: [address?.firstLine, validator],
            secondLine: [address?.secondLine],
            town: [address?.town, validator],
            county: [address?.county]
        });
    }

    getFixedAddressOnChange(formGroup: FormGroup): Subscription {
        return formGroup.get('noFixedAbode').valueChanges.subscribe((isChecked: boolean) => {
            if (isChecked) {
                formGroup.get('postcode').clearValidators();
                formGroup.get('firstLine').clearValidators();
                formGroup.get('town').clearValidators();
            } else {
                formGroup.get('postcode').setValidators(Validators.required);
                formGroup.get('firstLine').setValidators(Validators.required);
                formGroup.get('town').setValidators(Validators.required);
            }
            formGroup.get('postcode').updateValueAndValidity();
            formGroup.get('firstLine').updateValueAndValidity();
            formGroup.get('town').updateValueAndValidity();
            formGroup.get('localAuthority').updateValueAndValidity();
        });
    }

    public from(formGroup: FormGroup, address = new Address()): Address {
        const addressFormValue = formGroup.getRawValue();

        address.uid = addressFormValue.uid;
        address.url = addressFormValue.url;
        address.postcode = addressFormValue.postcode?.toUpperCase();
        address.firstLine = addressFormValue.firstLine;
        address.secondLine = addressFormValue.secondLine;
        address.town = addressFormValue.town;
        address.county = addressFormValue.county;

        return address;
    }

}
