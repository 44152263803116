import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from 'django-rest-core';
import { Issue } from '@app/shared/models/issue.model';
import { BaseReadWriteService } from './base-read-write.service';

@Injectable({
    providedIn: 'root'
})
export class IssueService extends BaseReadWriteService<Issue, IFilter>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'issues';
        this.exampleObject = new Issue();
    }

}
