import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address.model';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {
    transform(value: any, customFormat: string[] = null, country: string): any {
        if (value && typeof (value) === 'object') {
            return (value as Address).toFormattedAddress(customFormat, country);
        }
        return value;
    }
}
