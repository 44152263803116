import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { User } from "@app/shared/models/user.model";
import { Subject } from "rxjs";

declare var Tawk_API: any;

@Injectable()
export class TawkService {

    loaded: boolean;
    private loadSubject: Subject<boolean> = new Subject<boolean>();
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document
    ) { }

    public load(tawkWebchatCode: string) {
        if (this.loaded || !tawkWebchatCode) {
            return;
        }
        this.renderer = this.rendererFactory.createRenderer(null, null);
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/${tawkWebchatCode}/1gucppa4g';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
        `;
        this.renderer.appendChild(this._document.body, script);
        Tawk_API.onLoad = this.loadedEvent.bind(this);
    }

    private loadedEvent() {
        this.loaded = true;
        this.loadSubject.next(this.loaded);
    }

    public updateTawkUser(user: User) {
        this.loadedWrapper(() => { this.updateAtrributes(user) });
    }

    private loadedWrapper(func: any) {
        if (!this.loaded) {
            var sub = this.loadSubject.asObservable().subscribe({
                next: () => {
                    func();
                    sub.unsubscribe();
                },
                error: () => { }
            });
        } else {
            func();
        }
    }

    public expandChatWindow(show: boolean = false) {
        this.loadedWrapper(() => show ? Tawk_API.maximize() : Tawk_API.minimize());
    }

    public setChatVisibility(show: boolean = false) {
        this.loadedWrapper(() => show ? Tawk_API.showWidget() : Tawk_API.hideWidget());
    }

    private updateAtrributes(user: User) {
        Tawk_API.setAttributes({
            'name': user.name,
            'email': user.email,
            'uid': user.uid,
        }, function (error) { });
    }
}