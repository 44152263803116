import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-consent-form',
    templateUrl: './consent-form.component.html',
    styleUrls: ['./consent-form.component.scss']
})
export class ConsentFormComponent {

    readonly clientTerm = environment.configuration.terminology.client;
    readonly referralTerm = environment.configuration.terminology.referral;
  
    @Input() formGroup: FormGroup;
    @Input() consenteeControl: string;
    @Input() clientConsentControl: string;
    @Input() consentDateControl: string;

    consentTooltip = `You must acquire consent from the client before creating a ${this.referralTerm}.
        If the client cannot legally consent for themselves, you must obtain consent from their Legal Guardian or Representative.`

    constructor() { }

    toggleHasClientConsent(): void {
        if (this.formGroup.get(this.clientConsentControl).value === true){
            this.formGroup.get(this.consenteeControl).reset();
        }
    }

}
