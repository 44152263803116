import { Component, OnInit } from '@angular/core';
import { Organisation } from '@app/shared/models/organisation.model';
import { OrganisationService } from '@app/core/services/organisation.service';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { OrganisationFilters } from '@app/shared/models/filters/organisationFilters.model';

@Component({
    selector: 'app-organisation-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class OrganisationSelectComponent extends SelectBaseComponent<Organisation> implements OnInit {
    
    constructor(
        private organisationService: OrganisationService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.organisationService;
        this.getObjectCustomField = 'getOrganisationsSelectOptions';
        this.filter = new OrganisationFilters();
        this.filterSearchField = 'dropdownSearch';
    }

}
