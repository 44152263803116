<div class="w-fit-content">
    <label for="file-upload" class="btn btn-icon btn-outline-primary mb0 position-relative" [class.disabled]="!loaded">
        <div *ngIf="!loaded" class="position-relative mr3">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0)" [name]="spinnerName" type="ball-spin-clockwise" color="#000"
                [fullScreen]="false" size="small"></ngx-spinner>
        </div>
        <i *ngIf="loaded" class="far fa-lg fa-{{ faIcon }}"></i>
        <span>{{ text }}</span>
    </label>
    <input #fileInput type="file" id="file-upload" class="d-none" [disabled]="!loaded" [accept]="accepts" (change)="onChange($event)">
</div>
