import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-filter-button',
    templateUrl: './filter-button.component.html',
    styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent {

    @Output() filterClickEvent = new EventEmitter();

    @Input() filterIsActive = false;
    @Input() isHighlighted = false;
    @Input() faIcon = 'sliders-up';

    constructor() { }

    onFilterClick(): void {
        this.filterClickEvent.emit();
    }

}
