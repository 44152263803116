<div class="card" [ngClass]="{ 'main-form-border': mainForm }">
    <div class="card-header" [ngClass]="customPadding ? customPadding : ''">
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="card-body" [ngClass]="customPadding ? customPadding : ''">
        <div *ngIf="step" class="xs-label mb3 primary-colour">{{ step }}</div>
        <div *ngIf="title" class="header-xs font-weight-bold mb4">{{ title }}</div>
        <ng-content select="[body]"></ng-content>
    </div>
</div>
