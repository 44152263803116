import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-authentication-page-layout',
    templateUrl: './authentication-page-layout.component.html',
    styleUrls: ['./authentication-page-layout.component.scss']
})
export class AuthenticationPageLayoutComponent implements OnInit {


    constructor() {}

    ngOnInit(): void {
        
    }

}
