<div class="d-flex align-items-center">
    <app-user-icon [user]="user" [ngxContainer]="ngxContainer" placement="left" size="lg" faSize="lg"
        theme="tertiary" class="col-auto pr5">
    </app-user-icon>
    <div *ngIf="user?.isSiteAdmin; else regularUser">
        <ng-container *ngTemplateOutlet="userTemplate; context:{ user: user }"></ng-container>
    </div>
    <ng-template #userTemplate let-user="user">
        <div class="font-weight-bold show-ellipsis">
            {{ user?.name }}
        </div>
        <div class="xs-text">
            {{ user?.systemTitle }}
        </div>
    </ng-template>
    <ng-template #regularUser>
        <div *ngIf="formGroup" [formGroup]="formGroup">
            <ng-select *ngIf="user?.staff?.length > 1" class="navbar-select" formControlName="currentStaff"
                [items]="user?.staff" [clearable]="false" placeholder="No organsation selected"
                (change)="currentOrgChanged()">
                <ng-template ng-label-tmp let-item="item">
                    <ng-container *ngTemplateOutlet="staffTemplate; context:{ item: item }"></ng-container>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <ng-container *ngTemplateOutlet="staffTemplate; context:{ item: item }"></ng-container>
                </ng-template>
            </ng-select>
            <div *ngIf="user?.staff?.length === 1">
                <ng-container
                    *ngTemplateOutlet="staffTemplate; context:{ item: user?.staff[0] }"></ng-container>
            </div>
            <div *ngIf="!user?.staff?.length">
                <ng-container *ngTemplateOutlet="userTemplate; context:{ user: user }"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #staffTemplate let-item="item">
        <div class="font-weight-bold show-ellipsis">
            {{ item?.organisation.name }}
        </div>
        <div class="xs-text">
            {{ item?.staffTitle }}
        </div>
    </ng-template>
</div>
