<div *ngIf="form" class="card">
    <div class="card-body">
        <form>
            <h3 class="mb3 text-primary">Edit {{ objectType }}</h3>
            <div class="mb5">
                <ng-content select="[form]"></ng-content>
            </div>
            <button (click)="onSave()" [disabled]="!isValid" class="btn btn-primary">Save</button>
        </form>
    </div>
</div>
