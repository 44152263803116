import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GoogleTagMangerService {

    loaded: boolean;
    private loadSubject: Subject<boolean> = new Subject<boolean>();
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document
    ) { }

    public load(gtmCode: string) {
        if (!gtmCode) {
            return;
        }
        this.renderer = this.rendererFactory.createRenderer(null, null);

        const headScript = this.renderer.createElement('script');
        headScript.type = 'text/javascript';
        headScript.text = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmCode}');
        `;
        this.renderer.appendChild(this._document.head, headScript);

        const bodyScript = this.renderer.createElement('noscript');
        bodyScript.appendChild(this.renderer.createElement('iframe'))
        bodyScript.firstChild.src = `https://www.googletagmanager.com/ns.html?id=${gtmCode}`;
        bodyScript.firstChild.height = 0;
        bodyScript.firstChild.width = 0;
        bodyScript.firstChild.style = 'display:none;visibility:hidden';
        this.renderer.appendChild(this._document.body, bodyScript);
    }

}