<div class="container vh-100-navbar pt5 pr5 pl5"
    [ngClass]="{ 'scrollbar': hasScrollbar }">
	<div class="d-flex flex-column h-100">
	    <div>
		    <ng-content select="[header]"></ng-content>
		</div>
        <div class="list-container deep-h-100 mb5"
            [ngClass]="{ 'card': isCard }">
            <ng-content select="[body]"></ng-content>
        </div>
    </div>
</div>