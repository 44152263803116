import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClientFilters } from '@app/shared/models/filters/clientFilters.model';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

    @Input() header: string;
    @Input() formGroup: FormGroup;

    @Output() filtersChanged = new EventEmitter<ClientFilters>();
    @Output() filtersCleared = new EventEmitter();

    constructor() { }

    saveFilters(): void {
        this.filtersChanged.emit();
    }

    clearFilters(): void {
        this.filtersCleared.emit();
    }

}
