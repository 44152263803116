<app-config-form-template [objectType]="objectType" [form]="formGroup" (saveClicked)="save()">
    <div form>
        <div class="mb5">
            <app-text-input [formGroup]="formGroup" formControlKey="name" labelText="Label" [marginBottom]="false"></app-text-input>
        </div>
        <div class="mb5">
            <label class="xs-text font-weight-extra-bold text-uppercase required-field">State</label>
            <app-radio-input [formContainer]="formGroup" formControlKey="state" [options]="radioOptions" class="h-fit-content d-block mr6"></app-radio-input>
        </div>
        <div class="mb5">
            <label class="xs-text font-weight-extra-bold text-uppercase">Settings</label>
            <app-checkbox-input [formGroup]="formGroup" formControlKey="defaultOpeningStatus" labelText="Default opening status"></app-checkbox-input>
        </div>
        <div>
            <label class="xs-text font-weight-extra-bold text-uppercase">Colour</label>
            <app-colour-select></app-colour-select>
        </div>
    </div>
</app-config-form-template>