import { Component, Input } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddNoteModalComponent } from '../../add-note-modal/add-note-modal.component';
import { ReferralNote } from '@app/shared/models/notes/referral-note.model';
import { ResourceLink } from 'django-rest-core';
import { ReferralNotesService } from '@app/core/services/referral/referral-notes.service';
import { ToastService } from '@app/core/services/toast.service';
import { ActivityFeedService } from '@app/core/services/activity-feed.service';
import { Referral } from '@app/shared/models/referral.model';
import { CaseNote } from '@app/shared/models/notes/case-note.model';
import { Case } from '@app/shared/models/case.model';
import { CaseNotesService } from '@app/core/services/case/caseNotes.service';

@Component({
    selector: 'app-add-note-button',
    templateUrl: './add-note-button.component.html',
    styleUrls: ['./add-note-button.component.scss']
})
export class AddNoteButtonComponent {

    @Input() referral: Referral;
    @Input() case: Case;

    constructor(
        private activityFeedService: ActivityFeedService,
        private bsModalService: BsModalService,
        private caseNoteService: CaseNotesService,
        private modalService: ModalService,
        private referralNoteService: ReferralNotesService,
        private toastService: ToastService
    ) { }

    onAddNote(): void {
        this.modalService.create(
            this.bsModalService,
            (options: any) =>
                this.bsModalService.show(
                    AddNoteModalComponent,
                    options
                ),
            null,
            {
                saveNote: this.referral ? this.addReferralNote : this.addCaseNote
            },
            true
        );
    }

    addCaseNote = (content: string): void => {
        const note = new CaseNote();
        note.case = new ResourceLink(this.case.url, this.case);
        note.content = content;
        this.caseNoteService.add(note).subscribe(() => {
            this.toastService.add({
                title: 'Success',
                message: 'Note Added Successfully.',
                type: 'success',
            });
            this.activityFeedService.emitUpdated();
            this.caseNoteService.emitUpdated();
        },(err) => {
            console.log(err);
            this.toastService.add({
                title: 'Error',
                message: 'There was an error.',
                type: 'error',
            });
        });
    }

    addReferralNote = (content: string): void => {
        const note = new ReferralNote();
        note.referral = new ResourceLink(this.referral.url, this.referral);
        note.content = content;
        this.referralNoteService.add(note).subscribe(() => {
            this.toastService.add({
                title: 'Success',
                message: 'Note Added Successfully.',
                type: 'success',
            });
            this.activityFeedService.emitUpdated();
            this.referralNoteService.emitUpdated();
        },(err) => {
            console.log(err);
            this.toastService.add({
                title: 'Error',
                message: 'There was an error.',
                type: 'error',
            });
        });
    }

}
