import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

    @Input() searchTerm: string;
    @Input() searchBy: string;
    @Input() tabIndex = 0;
    @Input() outputOnBlur = false;

    @Input() searchBarOpened: Observable<void>;
    private searchBarOpenedSubscription: Subscription;

    @Output() searchChangedEvent = new EventEmitter();
    private debouncer = new Subject<string>();

    @ViewChild('searchBar') searchBar: ElementRef;

    constructor() {
        this.debouncer.pipe(debounceTime(500)).subscribe((value) => this.searchChangedEvent.emit(value));
    }

    ngOnInit(): void {
        if (this.searchBarOpenedSubscription) {
            this.searchBarOpenedSubscription.unsubscribe();
        }
    }

    onSearchChanged = (searchTerm: string) => {
        if (!this.outputOnBlur) {
            this.debouncer.next(searchTerm);
        }
    }

    onBlur(): void {
        if (this.outputOnBlur) {
            this.searchChangedEvent.emit(this.searchTerm);
        }
    }

}
