import { Component, Input } from '@angular/core';
import { Organisation } from '@app/shared/models/organisation.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-organisation-card',
    templateUrl: './organisation-card.component.html',
    styleUrls: ['./organisation-card.component.scss']
})
export class OrganisationCardComponent {

    readonly organisationLink = environment.links.organisation;
    readonly referralsTerm = environment.configuration.terminology.referralPlural;

    @Input() organisation: Organisation;
    @Input() hasCustomButtons = false;
    @Input() showCriteria = false;
    @Input() showServiceOffer = true;

    constructor() { }

    getViewLink(): string {
        return this.organisationLink + this.organisation.uid;
    }

    getCircleLabel(): string {
        return this.organisation?.acceptingReferrals ? `Accepting ${this.referralsTerm}` : `Not Accepting ${this.referralsTerm}`;
    }

}
