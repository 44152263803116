import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { classToPlain } from 'class-transformer';
import { Case } from '@app/shared/models/case.model';
import { CaseFilters } from '@app/shared/models/filters/caseFilters.model';
import { CaseConsent } from '@app/shared/models/caseConsent.model';
import { BaseReadWriteService } from '../base-read-write.service';
import { PagedResponse } from 'django-rest-core';

@Injectable({
    providedIn: 'root'
})
export class CaseService extends BaseReadWriteService<Case, CaseFilters>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'cases';
        this.exampleObject = new Case();
    }

    getCompactCases(page: number, filter?: CaseFilters, sortBy?: string): Observable<PagedResponse<Case>> {
        return this.getPagedList(page, filter, sortBy, 'compact');
    }

    // This can't use super.add because backend requires consent data as well as Case data in post request
    // TODO: Can we just push a partial CaseConsent on to Case.caseConsents and have backend use that? Could use super.add then as all data would be inside a Case
    addCase(newCase: Case, caseConsent: Partial<CaseConsent>): Observable<Case> {
        const plainCase = classToPlain(newCase);
        const plainCaseConsent = classToPlain(caseConsent);

        const caseToSend = {
            client: plainCase.client,
            referred_from: plainCase.referred_from,
            consent_date: plainCaseConsent.consent_date,
            has_client_consent: plainCaseConsent.has_client_consent,
            consentee: plainCaseConsent.consentee ?? ''
        };
        return this.setTypeOnResponse(this.http.post<Case>(this.getApiRoot(), caseToSend), new Case);
    }

}
