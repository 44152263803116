<div class="w-100 vh-100 hide-overflow position-absolute z-0">
        <div class="row">
            <div class="w-50 vh-100 col-6 bg-primary high-opacity"></div>
            <div class="hide-overflow col-6 w-50 vh-100 ml0 pl0"> 
                <img style="opacity: 25%;" width="100%" height="100%" src="../../../../../assets/img/background-pattern.svg">
        </div>
    </div>
</div>

<router-outlet></router-outlet>
