import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ImageUploadService } from '@app/core/services/image-upload.service';
import { ToastService } from '@app/core/services/toast.service';

@Component({
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss']
})
export class ImageInputComponent {

    @ViewChild('fileInput') fileInput: ElementRef;

    @Input() form: FormGroup;
    @Input() formControlKey: string;
    @Input() text = 'Upload Image';
    @Input() faIcon = 'circle-plus';
    @Input() showImage = true;
    @Input() validFileTypes: string[] = ['jpg', 'jpeg', 'png'];
    @Input() fileSizeMB = 1;

    @Output() imageUploaded = new EventEmitter();

    loaded = true;

    constructor(
        private imageUploadService: ImageUploadService,
        private toastService: ToastService
    ) { }

    get formControl(): FormControl {
        return this.form.get(this.formControlKey) as FormControl;
    }

    get formatValidTypes(): string {
        return this.validFileTypes.map(item => item.toUpperCase()).join(', ');
    }

    get accepts(): string {
        return this.validFileTypes?.map(item => `.${item}`).join(',');
    }

    onChange(event: any): void {
        const file: File = event.target.files[0];
        if (!file.type.includes('image')) {
            this.fileInput.nativeElement.value = null;
            this.toastService.add({
                type: 'error',
                title: 'Error',
                message: 'Invalid file type'
            });
            return;
        }

        const bytes = file.size;
        const megabytes = 1048576;
        if (bytes > (this.fileSizeMB * megabytes)) {
            this.fileInput.nativeElement.value = null;
            this.toastService.add({
                type: 'error',
                title: 'Error',
                message: 'File is too large, please select a file under 1MB.'
            });
            return;
        }

        const formData = new FormData();
        const blob = new Blob([file]);
        formData.append('file', blob, file.name);

        this.loaded = false;
        this.imageUploadService.uploadImage(formData).subscribe((imageURL: string) => {
            this.form.get(this.formControlKey).setValue(imageURL);
            this.form.markAsDirty();
            this.imageUploaded.emit();
            this.loaded = true;
        }, () => {
            this.loaded = true;
        });
    }

}
