import { Expose } from 'class-transformer';
import { BaseModel } from 'django-rest-core';

export class QuestionnaireQuestion extends BaseModel {
    
    uid: string;
    
    question: string;
    
    @Expose({name: 'boolean_question'})
    booleanQuestion: string;
    
    @Expose({name: 'is_boolean_question'})
    isBooleanQuestion: boolean;
    
    @Expose({name: 'question_required'})
    isQuestionRequired: boolean;
    
    @Expose({name: 'help_text'})
    helpText: string;

    order: number;
}
