import { Component, Input } from '@angular/core';
import { BreadCrumb } from '@app/shared/models/breadCrumb.model';

@Component({
    selector: 'app-directory-page-layout',
    templateUrl: './directory-page-layout.component.html',
    styleUrls: ['./directory-page-layout.component.scss']
})
export class DirectoryPageLayoutComponent {

    @Input() directory: (string | BreadCrumb)[];
    @Input() mainTitle: string;
    @Input() subtitle: string;
    @Input() hasCustomMainTitle = false;
    @Input() customHorizontalPadding: string;
    @Input() alignItems: 'center' | 'start' = 'center';

    constructor() { }

}
