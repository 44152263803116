import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BaseModel } from 'django-rest-core';

@Injectable({
    providedIn: 'root',
})
export class FormsBaseService {
    
    constructor(protected formBuilder: FormBuilder) {}

    appendToControl<T extends BaseModel>(control: AbstractControl, value: T) {
        if (control.value && control.value.length) {
            if (!control.value.find((x) => x.pk === value.pk)) {
                control.value.push(value);
                control.setValue(control.value);
            }
        } else {
            control.setValue([value]);
        }
    }

    replaceGroupInArrayAt(
        index: number,
        parentFormArray: FormArray,
        newForm: FormGroup
    ) {
        parentFormArray.removeAt(index);
        parentFormArray.insert(index, newForm);
    }

    createNestedGroup(...forms: FormGroup[]) {
        return this.formBuilder.group(forms);
    }
}
