import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalService } from '@app/core/services/modal.service';
import { DeleteModalComponent } from '@app/modules/referral/components/modals/delete-modal/delete-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-save-and-delete-icons',
    templateUrl: './save-and-delete-icons.component.html',
    styleUrls: ['./save-and-delete-icons.component.scss']
})
export class SaveAndDeleteIconsComponent {

    @Output() onSaveClicked = new EventEmitter<FormGroup>();
    @Output() onDeleteClicked = new EventEmitter<FormGroup>();

    @Input() form: FormGroup;

    constructor(private bsModalService: BsModalService, private modalService: ModalService) { }

    get isValid(): boolean {
        return this.form.valid && this.form.dirty;
    }

    get isDisabled(): boolean {
        return this.form.disabled;
    }

    onSave(): void {
        this.onSaveClicked.emit(this.form);
    }

    onDelete(): void {
        this.onDeleteClicked.emit(this.form);
    }

}
