import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-config-form-template',
    templateUrl: './config-form-template.component.html',
    styleUrls: ['./config-form-template.component.scss']
})
export class ConfigFormTemplateComponent {

    @Output() saveClicked = new EventEmitter();

    @Input() objectType: string;
    @Input() form: FormGroup;

    constructor() { }

    get isValid(): boolean {
        return this.form.valid && this.form.dirty;
    }

    onSave(): void {
        this.saveClicked.emit();
    }

}
