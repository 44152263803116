import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-list-header',
    templateUrl: './card-list-header.component.html',
    styleUrls: ['./card-list-header.component.scss']
})
export class CardListHeaderComponent {

    @Input() customClass: string;
    @Input() headings: { title: string; colSpan: number, textCenter: boolean, isRequired: boolean }[] = [];

    constructor() { }

}
