<div id="page-container" class="d-flex flex-column overflow-hidden">
    <app-navbar *ngIf="user" [user]="user" (logoClicked)="toggleSidebar()"></app-navbar>
    <div class="d-flex vh-100-navbar px-0">
        <div *ngIf="!mobileScreenSize || !sidebarCollapsed" [ngClass]="{ 'align-items-center': sidebarCollapsed }"
            class="d-flex flex-column bg-white vh-100-navbar sticky-top pt3 z-custom"
            [class.mobile-sidebar]="mobileScreenSize">
            <div class="pl5 pr5 pt3 pb3 new-referral-icon-top cursor-pointer" (click)="createReferral()">
                <app-icon faIcon="plus" [hasClickEvent]="true" tooltipText="Create {{ caseTerm }}" theme="primary"></app-icon>
                <div *ngIf="!sidebarCollapsed" class="ml3 text-nowrap font-weight-bold">Create {{ referralTerm }}</div>
            </div>
            <div *ngFor="let item of navItems" [ngClass]="{
                    'icon-tertiary': item.isValid && item.active,
                    'icon-clear': item.isValid && !item.active
                }">
                <div *ngIf="item.isValid"
                    class="icon-background icon-container rounded-high cursor-pointer d-flex align-items-center p1 ml5 mr5 mt4 mb4"
                    [ngClass]="item.active ? null : 'sidebar-hover'" (click)="routeTo(item.route)">
                    <app-icon
                        [faIcon]="item.icon"
                        faType="regular"
                        [hasClickEvent]="true"
                        [tooltipText]="sidebarCollapsed ? item.title : null"
                        [theme]="item.active ? 'tertiary' : 'clear'">
                    </app-icon>
                    <div *ngIf="!sidebarCollapsed" class="pr5 font-weight-bold"
                        [class.text-white]="item.isValid && item.active"
                        [class.text-dark]="item.isValid && !item.active">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'align-items-center': !sidebarCollapsed }"
                class="p1 ml5 mr5 mt4 mb4 new-referral-icon-bottom cursor-pointer" (click)="createReferral()">
                <app-icon faIcon="folder-plus" [tooltipText]="caseTooltipText" theme="primary"></app-icon>
                <div *ngIf="!sidebarCollapsed" class="ml3 text-nowrap font-weight-bold">Create {{ caseTerm }}</div>
            </div>
        </div>
        <div id="default-page-router-wrapper" class="light-background-colour w-100 scrollbar" (scroll)="onDefaultPageScroll()">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-session-inactive></app-session-inactive>