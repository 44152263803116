import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Gender } from '@app/shared/models/gender.model';
import { FormsBaseService } from './forms-base.service';
import { FormArrayBaseService } from './form-array-base.service';

@Injectable({
    providedIn: 'root',
})
export class GenderFormService extends FormsBaseService implements FormArrayBaseService<Gender> {

    constructor(protected formBuilder: FormBuilder) {
        super(formBuilder);
    }

    createFormArray(genders: Gender[]): FormGroup {
        return this.formBuilder.group({
            formArray: this.formBuilder.array(genders ?
                genders.map((gender) => this.createForm(gender)) : []
            )
        });
    }

    createForm(gender?: Gender): FormGroup {
        return this.formBuilder.group({
            uid: [gender?.uid],
            url: [gender?.url],
            name: [gender?.name, Validators.required],
            isNonBinary: [false]
        });
    }

    public from(genderForm: FormGroup, gender = new Gender()): Gender {
        const formData = genderForm.getRawValue();

        gender.uid = formData.uid;
        gender.url = formData.url;
        gender.name = formData.name;

        return gender;
    }

    public fromArray(form: FormArray, genders: Gender[] = []): Gender[] {
        const newGenders = [];

        for (let i = 0; i < form.controls.length; i++) {
            newGenders.push(this.from(form.controls[i] as FormGroup, genders[i]));
        }

        return newGenders;
    }
}
