<header>
    <div class="navbar-background transition-slow" [ngClass]="{ 'open': isNavbarOpen }"></div>
    <nav class="navbar navbar-expand-sm fixed-top navbar-light p0 border-bottom align-items-center justify-content-between scrollbar ">
        <div class="col d-flex align-items-center">
            <a class="navbar-brand click-event" (click)="onLogoClicked()">
                <app-organisation-logo width="56px"></app-organisation-logo>
            </a>
            <app-user-org-select [user]="user"></app-user-org-select>
        </div>
        <div class="col-auto collapse navbar-collapse no-border justify-content-end">
            <app-navbar-buttons></app-navbar-buttons>
        </div>
    </nav>
</header>
