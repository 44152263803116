import { IFilter } from 'django-rest-core';
import { uniq } from 'lodash-es';
import * as moment from 'moment';
import { Moment } from 'moment';

export class ReferralFilters implements IFilter {

    name: string;
    referralNumber: number;
    caseId: string;
    clientId: string;
    clientAge: number;
    clientDob: Moment;
    clientGenderIds: string[];
    organisationId: string;
    caseReferredFrom: string;
    closureNote: string;
    closureReasonIds: string[];
    createdAt: Moment;
    createdFrom: Moment;
    createdTo: Moment;
    clientCreatedById: string;
    isUrgent: boolean;
    isOverdue: boolean;
    isCloseOverdue: boolean;
    isUpdateOverdue: boolean;
    issueIds: string[];
    statusIds: string[];

    constructor() { }

    public getSearchCriteriaString(): string {
        let search = '';
        if (this.name) {
            search += '&search=' + encodeURIComponent(this.name);
        }
        if (this.referralNumber) {
            search += '&referral_number=' + encodeURIComponent(this.referralNumber);
        }
        if (this.caseId) {
            search += '&case=' + encodeURIComponent(this.caseId);
        }
        if (this.clientId) {
            search += '&case__client=' + encodeURIComponent(this.clientId);
        }
        if (this.clientAge) {
            search += '&client_age=' + encodeURIComponent(this.clientAge);
        }
        if (this.clientDob) {
            search += '&client_date_of_birth=' + moment(this.clientDob).format('YYYY-MM-DD');
        }
        if (this.clientGenderIds?.length > 0) {
            this.clientGenderIds.forEach(element => search += '&client_gender=' + element);
        }
        if (this.organisationId) {
            search += '&organisation=' + this.organisationId;
        }
        if (this.caseReferredFrom) {
            search += '&case__referred_from=' + this.caseReferredFrom;
        }
        if (this.closureNote) {
            search += '&closure_note=' + this.closureNote;
        }
        if (this.closureReasonIds?.length > 0) {
            this.closureReasonIds.forEach(element => search += '&closure_reason=' + element);
        }
        if (this.createdAt) {
            search += '&created_at=' + encodeURIComponent(moment(this.createdAt).format('YYYY-MM-DD'));
        }
        if (this.createdFrom) {
            search += '&created_at__gte=' + encodeURIComponent(moment(this.createdFrom).format('YYYY-MM-DD'));
        }
        if (this.createdTo) {
            search += '&created_at__lte=' + encodeURIComponent(moment(this.createdTo).format('YYYY-MM-DD'));
        }
        if (this.clientCreatedById) {
            search += '&case__client=' + this.clientCreatedById;
        }
        if (this.isUrgent != null) {
            search += '&is_urgent=' + this.isUrgent
        }
        if (this.isCloseOverdue != null) {
            search += '&is_close_overdue=' + this.isCloseOverdue;
        }
        if (this.isOverdue != null) {
            search += '&is_overdue=' + this.isOverdue;
        }
        if (this.isUpdateOverdue != null) {
            search += '&is_update_overdue=' + this.isUpdateOverdue;
        }
        if (this.issueIds?.length > 0) {
            this.issueIds.forEach(element => search += '&issue=' + element);
        }
        if (this.statusIds?.length > 0) {
            this.statusIds.forEach(element => search += '&current_status=' + element);
        }

        return search;
    }

    toUrl(): {[k: string]: any} {
        const urlParams: {[k: string]: any} = {};

        for (const key of Object.keys(this)) {
            urlParams[key] = null;
            const valid = (this[key] || this[key] === false || this[key] === 0);
            if (valid) {
                switch (key) {
                    // STRINGS, NUMBERS
                    case 'name':
                    case 'referralNumber':
                    case 'clientId':
                    case 'clientFirstName':
                    case 'clientLastName':
                    case 'clientAge':
                    case 'caseId':
                    case 'organisationId':
                    case 'caseReferredFrom':
                    case 'clientCreatedById':
                    case 'isUrgent':
                    case 'isOverdue':
                    case 'isUpdateOverdue':
                    case 'isCloseOverdue':
                        urlParams[key] = this[key];
                        break;
                    // ARRAYS
                    case 'statusIds':
                    case 'closureReasonIds':
                    case 'issueIds':
                    case 'clientGenderIds':
                        if (Array.isArray(this[key]) && this[key].length > 0) {
                            urlParams[key] = uniq(this[key] as any).join(',');
                        }
                        break;
                    // DATES
                    case 'clientDob':
                    case 'createdFrom':
                    case 'createdTo':
                    case 'createdAt':
                        urlParams[key] = moment(this[key]).format('YYYY-MM-DD');
                        break;
                }
            }
        }

        return urlParams;
    }

    fromUrl(queryParams): void {
        for (const key of Object.keys(queryParams)) {
            if (queryParams[key]) {
                switch (key) {
                    // STRINGS
                    case 'name':
                    case 'referralNumber':
                    case 'clientId':
                    case 'clientFirstName':
                    case 'clientLastName':
                    case 'caseId':
                    case 'organisationId':
                    case 'caseReferredFrom':
                    case 'clientCreatedById':
                    case 'isUrgent':
                    case 'isOverdue':
                    case 'isUpdateOverdue':
                    case 'isCloseOverdue':
                        this[key as any] = queryParams[key];
                        break;
                    // NUMBERS
                    case 'clientAge':
                        if (parseInt(queryParams[key], 10)) {
                            this[key] = parseInt(queryParams[key], 10);
                        }
                        break;
                    // ARRAYS
                    case 'statusIds':
                    case 'closureReasonIds':
                    case 'issueIds':
                    case 'clientGenderIds':
                        const filteredValue = queryParams[key]?.replace(/[^0-9,]/g, '');
                        if (filteredValue) {
                            const tempArray = [];
                            uniq(filteredValue.split(',')).forEach((item: string) => {
                                if (parseInt(item, 10)) {
                                    tempArray.push(item);
                                }
                            });
                            this[key] = tempArray.length > 0 ? tempArray : null;
                        }
                        break;
                    // DATES & MOMENTS
                    case 'clientDob':
                    case 'createdFrom':
                    case 'createdTo':
                    case 'createdAt':
                        const newMoment = moment(new Date(queryParams[key]), 'YYYY-MM-DD');
                        if (newMoment.isValid() &&
                            newMoment.isAfter(moment('01/01/2010', 'DD/MM/YYYY')) &&
                            newMoment.isBefore(moment('31/12/2030', 'DD/MM/YYYY'))) {
                            this[key] = newMoment.isValid() ? newMoment : null;
                        }
                        break;
                }
            }
        }
    }
}
