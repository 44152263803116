<div class="icon-parent d-flex align-items-center icon-{{ theme }}"
    [ngClass]="{ 'has-click-event': hasClickEvent, 'active': active, 'disabled': disabled }"
    (click)="clicked($event)"
    (keyup.enter)="clicked($event)"
    [placement]="placement"
    [tooltip]="tooltipText"
    [container]="ngxContainer"
    tabindex="0">
    <div class="icon-container {{ alignment }} {{ size }} deep-w-100">
		<div class="icon icon-background" [ngClass]="{'icon-circle': isCircle, 'rounded-high': !isCircle }">
		    <i *ngIf="!text; else hasText" class="{{ type }} fa-{{ faSize }} fa-{{ faIcon }}"></i>
            <ng-template #hasText>
                <div class="xs-text">{{ text }}</div>
            </ng-template>
            <ng-content select="[icon]"></ng-content>
		</div>
    </div>
</div>
