<div class="mb3 xs-text">{{ formatValidTypes }} (Max size: {{ fileSizeMB }}MB)</div>
<div class="w-fit-content" [ngClass]="{ 'd-flex align-items-center': showImage }">
    <app-organisation-logo *ngIf="showImage"
        [loaded]="loaded"
        [logoUrl]="formControl.value"
        class="mr4"
        [hasBorder]="true">
    </app-organisation-logo>
    <label for="file-upload" class="btn btn-icon btn-white border mb0" [class.disabled]="!loaded">
        <i class="fa-solid fa-lg fa-{{ faIcon }} text-primary"></i>
        <span>{{ text }}</span>
    </label>
    <input #fileInput type="file" id="file-upload" class="d-none" [disabled]="!loaded" [accept]="accepts" (change)="onChange($event)">
</div>