import { Expose, Transform } from 'class-transformer';
import * as moment from 'moment';
import { Moment } from 'moment';
import { User } from '../user.model';
import { BaseModel } from '../base-model';

export class SystemFile extends BaseModel {

    constructor(data?: Partial<SystemFile>) {
        super();
        Object.assign(this, data);
    }

    title: string;

    @Expose({name: 'download_url'})
    downloadUrl: string;

    @Expose({name: 'file_extension'})
    fileExtension: string;

    @Expose({name: 'file_name'})
    fileName: string;

    @Expose({name: 'fa_icon'})
    faIcon: string;

    @Expose({name: 'created_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DDTHH:mmZ') : null, { toPlainOnly: true })
    createdAt: Moment;

    @Expose({name: 'created_by'})
    createdBy: User;

}
