import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-filters-accordion',
    templateUrl: './filters-accordion.component.html',
    styleUrls: ['./filters-accordion.component.scss']
})
export class FiltersAccordionComponent implements OnInit {

    @Input() showAccordion = false;

    constructor() { }

    ngOnInit(): void {
    }

}
