import { Injectable } from '@angular/core';
import { BaseReadWriteService } from './base-read-write.service';
import { Status } from '@app/shared/models/status.model';
import { HttpClient } from '@angular/common/http';
import { IFilter } from 'django-rest-core';

@Injectable({
  providedIn: 'root'
})
export class StatusService extends BaseReadWriteService<Status, IFilter> {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'status';
        this.exampleObject = new Status();
    }
}
