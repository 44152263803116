import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-question-icon',
    templateUrl: './question-icon.component.html',
    styleUrls: ['./question-icon.component.scss']
})
export class QuestionIconComponent {

    @Input() tooltipText: string;
    @Input() placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'auto';
    @Input() ngxContainer = '#page-container';

    constructor() { }

}
