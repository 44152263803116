<div *ngIf="formControl" [class.form-group]="marginBottom">
    <label *ngIf="labelText" class="xs-label text-uppercase" [class.required-field]="isRequired && showRequired">
        {{ labelText }}
    </label>
    <div class="input-group">
        <input type="number" class="form-control number-input" [formControl]="formControl" [placeholder]="placeholder" (blur)="onBlur()">
        <div *ngIf="rightText" class="input-group-append">
            <span class="input-group-text">{{ rightText }}</span>
        </div>
    </div>
</div>