import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

    readonly dashboardLink = environment.links.dashboard;

    constructor(private router: Router) { }

    routeToHome = () => this.router.navigate([this.dashboardLink]);

}
