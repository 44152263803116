import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-ng-quill-editor',
    templateUrl: './ng-quill-editor.component.html',
    styleUrls: ['./ng-quill-editor.component.scss'],
})
export class NgQuillEditorComponent {

    // Documentation found here: https://www.npmjs.com/package/ngx-quill
    @Input() config;
    @Input() editorStyles;
    @Input() formControlKey: string;
    @Input() formGroup: FormGroup;

    constructor() { }

}


import Quill from 'quill';

const Link = Quill.import('formats/link');
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

class CustomLinkSanitizer extends Link {

    static sanitize(url: string): string {
        const sanitizedUrl = super.sanitize(url);

        if (!sanitizedUrl || sanitizedUrl === 'about:blank') {
            return sanitizedUrl;
        }

        const hasWhitelistedProtocol = Link.PROTOCOL_WHITELIST.some((protocol: string) => {
            return sanitizedUrl.startsWith(protocol);
        });

        if (hasWhitelistedProtocol) {
            return sanitizedUrl;
        }

        return `https://${sanitizedUrl}`;
    }

}

Quill.register(CustomLinkSanitizer, true);
