import { Component, OnInit } from '@angular/core';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { StatusService } from '@app/core/services/status.service';
import { Status } from '@app/shared/models/status.model';

@Component({
    selector: 'app-referral-status-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class ReferralStatusSelectComponent extends SelectBaseComponent<Status> implements OnInit {

    constructor(private statusService: StatusService) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.statusService;    
    }

}
