import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { ImageUpload } from '@app/shared/models/images/imageUpload.model';
import { ImageResponse } from '@app/shared/models/images/imageResponse.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImageUploadService {

    protected getApiRoot = () => `${environment.apiRoot}files/signed_post_url/?provider=cloudflare`

    constructor(protected http: HttpClient) { }

    uploadImage(formData: FormData): Observable<string> {
        return this.http.get(this.getApiRoot()).pipe(
            mergeMap((imageUpload: ImageUpload) => {
                let link = imageUpload.result.uploadURL;
                return this.http.post(link, formData);
            }), map((imageResponse: ImageResponse) => {
                const link = imageResponse.result.variants.find(link => link.indexOf('public') !== -1);
                return link;
            })
        )
    }

}