import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { ScrollService } from '@app/core/services/scroll.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html'
})
export class DropdownComponent {

    @Input() hasCustomTrigger = false;
    @Input() allowInsideClicks = false;
    @Input() ngxContainer = '#page-container';
    @Input() faSize: 'sm' | 'md' | 'lg' | 'xl' = 'md';
    @Input() iconColourDark = true;
    @Input() autoClose = true;
    @Input() dropUp = false;
    @Input() hasStopPropagation = false;

    dropRight = true;

    @ViewChild('dropdown') dropdown: BsDropdownDirective;

    constructor(private scrollService: ScrollService) { }

    clicked(event: MouseEvent): void {
        if (this.hasStopPropagation) {
            event.stopPropagation();
        }
        if (this.dropUp) {
            this.dropdown.dropup = this.dropUp;
        } else {
            this.dropdown.dropup = this.dropdown ? event.screenY > (window.outerHeight / 4) * 3 : false;
        }
        this.dropdown.placement = this.dropdown.dropup ? 'top' : 'bottom';

        // Close dropdown when router-outlet is scrolled
        this.scrollService.scrolling.pipe(
            first()
        ).subscribe(() => {
            this.dropdown.hide();
        });
    }

}
