<app-form-card title="Edit Details" class="mb3 d-block">
    <div body [formGroup]="formGroup">
        <div class="row gy3">
            <div class="col-12 col-xl-6">
                <label class="xs-label text-uppercase" for="title-dropdown">Title</label>
                <app-static-select id="title-dropdown" [formGroup]="formGroup" formControlKey="title" [options]="titleTypes" placeholder="Title"></app-static-select>
            </div>
            <div class="col-12 col-xl-6">
                <!-- TODO: Should pronouns not be a select component? -->
                <app-text-input
                    [formGroup]="formGroup"
                    formControlKey="pronouns"
                    placeholder="Pronouns"
                    labelText="Pronouns"
                    [marginBottom]="false"
                    [capitalizeText]="true">
                </app-text-input>
            </div>
            <div class="col-12">
                <app-text-input
                    [formGroup]="formGroup"
                    formControlKey="firstName"
                    placeholder="First Name"
                    labelText="First Name"
                    [marginBottom]="false"
                    [capitalizeText]="true">
                </app-text-input>
            </div>
            <div class="col-12">
                <app-text-input
                    [formGroup]="formGroup"
                    formControlKey="lastName"
                    placeholder="Last Name"
                    labelText="Last Name"
                    [marginBottom]="false"
                    [capitalizeText]="true">
                </app-text-input>
            </div>
            <div class="col-12 col-xl-6">
                <label for="date-of-birth-picker" class="xs-label text-uppercase required-field">Date of Birth</label>
                <app-date-picker id="date-of-birth-picker" [formGroup]="formGroup" formControlKey="dob"></app-date-picker>
            </div>
            <div class="col-12 col-xl-6">
                <div class="d-inline-flex w-100 align-items-center justify-content-between">
                    <label for="gender-select" class="xs-label">GENDER</label>
                    <i class="fa-solid fa-circle-question primary-colour tooltip-size mb3"
                        tooltip="Gender isn't required but can help to ensure the Client meets the criteria of the receiving Organisation"
                        placement="right">
                    </i>
                </div>
                <app-gender-select id="gender-select" class="xs-placeholder" bindToField="name" placeholder="Select Gender"
                    formControlKey="gender" [formGroup]="formGroup"></app-gender-select>
            </div>
        </div>
    </div>
</app-form-card>
<app-form-card *ngIf="showNhsNumber || showGPInfo">
    <div body [formGroup]="formGroup">
        <app-text-input *ngIf="showNhsNumber"
            [formGroup]="formGroup"
            formControlKey="nhsNumber"
            placeholder="NHS Number"
            labelText="NHS Number"
            [marginBottom]="showGPInfo"></app-text-input>
        <div *ngIf="showGPInfo">
            <label class="xs-label" for="gp-surgery">GP SURGERY</label>
            <textarea id="gp-surgery" rows="4" cols="1" class="form-control light-gray-border xs-placeholder"
                placeholder="GP Surgery Address" formControlName="gpSurgery">
            </textarea>
        </div>
    </div>
</app-form-card>
<div class="d-flex gx4 mt3">
    <button type="button" class="btn btn-primary no-wrap" (click)="save()"
        [disabled]="!isBasicInfoValid">Save</button>
    <button type="button" class="btn btn-outline-primary" (click)="goBack()">Back</button>
</div>
