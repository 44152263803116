import { Injectable } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormControl,
	FormGroup,
} from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class ValidationService {
    // TODO: get dateFormat from backend when available
	private static readonly dateFormat = 'dd/mm/yyyy';
	private static readonly timeFormat = 'hh:mm';

	public static getValidationErrorMessage(
		validatorName: string,
		validatorValue?: any,
		labelName?: string
	): any {
		if (labelName) {
			validatorName = labelName;
		}

		const errorMessageConfig = {
			required: `This field is required.`,
			email: `The field must be a valid email address.`,
			phoneNumber: `The field must be a valid phone number.`,
			ngbDate: `The field must be a valid date in the format ${this.dateFormat}.`,
			ngbTime: `The field must be a valid date in the format ${this.timeFormat}.`,
			maxlength: `The field can't contain more than ${validatorValue.requiredLength} characters.`,
			minlength: `The field must contain at least ${validatorValue.requiredLength} characters.`,
			pattern: `The value inserted is not valid.`,
			website: `website must start with http:// or https://`,
			age: `"to" field must be higher than "from" field.`,
			min: `Value can't be lesst than ${validatorValue.min}.`,
			max: `Value can't be more than ${validatorValue.max}.`,
			date: `Please enter a valid date`,
			futureDob: `Date of birth cannot be in the future`,
		};

		return errorMessageConfig[validatorName];
	}

	validateAllFormFields(control: AbstractControl) {
		if (control instanceof FormControl) {
			control.markAsTouched({ onlySelf: true });
		} else if (control instanceof FormGroup) {
			Object.keys(control.controls).forEach((field: string) => {
				const groupControl = control.get(field);
				this.validateAllFormFields(groupControl);
			});
		} else if (control instanceof FormArray) {
			const controlAsFormArray = control as FormArray;
			controlAsFormArray.controls.forEach(
				(arrayControl: AbstractControl) =>
					this.validateAllFormFields(arrayControl)
			);
		}
	}
}
