import { IFilter } from 'django-rest-core';
import * as moment from 'moment';
import { Moment } from 'moment';
import uniq from 'lodash-es/uniq';

export class OrganisationFilters implements IFilter {

    search: string;
    dropdownSearch: string;
    name: string;
    parentId: number;
    address: string;
    active: boolean;
    acceptsReferrals: boolean;
    acceptsReferralsFromId: number;
    acceptedIssueIds: number[];
    acceptedSecondaryIssueIds: number[];
    localAuthoritiesIds: number[];
    genderIds: number[];
    nameStartsWith: string;
    minimumAge: number;
    minimumAgeFrom: number;
    minimumAgeTo: number;
    maximumAge: number;
    maximumAgeTo: number;
    maximumAgeFrom: number;
    tagNames: string[];
    caseId: number;
    caseIssueId: number;

    constructor() { }

    public getSearchCriteriaString(): string {
        let search = '';
        if (this.search) {
            search += '&search=' + encodeURIComponent(this.name);
        }
        if (this.dropdownSearch) {
            search += '&dropdown_search=' + encodeURIComponent(this.dropdownSearch);
        }
        if (this.name) {
            search += '&ft_search=' + encodeURIComponent(this.name);
        }
        if (this.parentId) {
            search += '&parent=' + this.parentId;
        }
        if (this.address) {
            search += '&address=' + encodeURIComponent(this.address);
        }
        if (this.active != null) {
            search += '&active=' + this.active;
        }
        if (this.acceptsReferrals != null) {
            search += '&accepts_referrals=' + this.acceptsReferrals;
        }
        if (this.acceptsReferralsFromId) {
            search += '&accepts_referrals_from=' + this.acceptsReferralsFromId;
        }
        if (this.acceptedIssueIds?.length > 0) {
            this.acceptedIssueIds.forEach(element => search += '&accepted_issues=' + element);
        }
        if (this.acceptedSecondaryIssueIds?.length > 0) {
            this.acceptedSecondaryIssueIds.forEach(element => search += '&accepted_secondary_issues=' + element);
        }
        if (this.localAuthoritiesIds?.length > 0) {
            this.localAuthoritiesIds.forEach(element => search += '&local_authorities=' + element);
        }
        if (this.genderIds?.length > 0) {
            this.genderIds.forEach(element => search += '&genders=' + element);
        }
        if (this.nameStartsWith) {
            search += '&name_starts_with=' + this.nameStartsWith;
        }
        if (this.minimumAge) {
            search += '&minimum_age=' + this.minimumAge;
        }
        if (this.minimumAgeTo) {
            search += '&minimum_age_gte=' + this.minimumAgeTo;
        }
        if (this.minimumAgeFrom) {
            search += '&minimum_age_lte=' + this.minimumAgeFrom;
        }
        if (this.maximumAge) {
            search += '&maximum_age=' + this.maximumAge;
        }
        if (this.maximumAgeTo) {
            search += '&maximum_age_gte=' + this.maximumAgeTo;
        }
        if (this.maximumAgeFrom) {
            search += '&maximum_age_lte=' + this.maximumAgeFrom;
        }
        if (this.tagNames?.length > 0) {
            this.tagNames.forEach(element => search += '&tags=' + element);
        }
        if (this.caseId) {
            search += '&case_match=' + this.caseId;
        }
        if (this.caseIssueId) {
            search += '&case_issue_match=' + this.caseIssueId;
        }
        return search;
    }

    toUrl(): {[k: string]: any} {
        const urlParams: {[k: string]: any} = {};

        for (const key of Object.keys(this)) {
            urlParams[key] = null;
            const valid = (this[key] || this[key] === false || this[key] === 0);
            if (valid) {
                switch (key) {
                    case 'name':
                    case 'dropdownSearch':
                    case 'parentId':
                    case 'address':
                    case 'active':
                    case 'acceptsReferrals':
                    case 'minimumAge':
                    case 'maximumAge':
                    case 'minimumAgeFrom':
                    case 'minimumAgeTo':
                    case 'maximumAgeTo':
                    case 'maximumAgeFrom':
                    case 'acceptsReferralsFromId':
                    case 'caseId':
                    case 'caseIssueId':
                        urlParams[key] = this[key];
                        break;
                    case 'acceptedIssueIds':
                    case 'acceptedSecondaryIssueIds':
                    case 'localAuthoritiesIds':
                    case 'genderIds':
                    case 'tagNames':
                        if (Array.isArray(this[key]) && this[key].length > 0) {
                            urlParams[key] = uniq(this[key] as any).join(',');
                        }
                        break;
                }
            }
        }

        return urlParams;
    }

    fromUrl(queryParams): void {
        for (const key of Object.keys(queryParams)) {
            const valid = (queryParams[key] || queryParams[key] === false || queryParams[key] === 0);
            if (valid) {
                switch (key) {
                    case 'name':
                    case 'dropdownSearch':
                    case 'address':
                    case 'active':
                    case 'parentId':
                    case 'acceptsReferralsFromId':
                    case 'caseId':
                    case 'caseIssueId':
                        this[key as any] = queryParams[key];
                        break;
                    case 'acceptsReferrals':
                        switch (queryParams[key]?.toLowerCase()) {
                            case 'true':
                                this[key as any] = true;
                                break;
                            case 'false':
                                this[key as any] = false;
                                break;
                        }
                        break;
                    case 'minimumAge':
                    case 'maximumAge':
                    case 'minimumAgeFrom':
                    case 'minimumAgeTo':
                    case 'maximumAgeTo':
                    case 'maximumAgeFrom':
                        if (parseInt(queryParams[key], 10)) {
                            this[key] = parseInt(queryParams[key], 10);
                        }
                        break;
                    case 'acceptedIssueIds':
                    case 'acceptedSecondaryIssueIds':
                    case 'localAuthoritiesIds':
                    case 'genderIds':
                        const filteredValue = queryParams[key]?.replace(/[^0-9,]/g, '');
                        if (filteredValue) {
                            const tempArray = [];
                            uniq(filteredValue.split(',')).forEach((item: string) => {
                                if (parseInt(item, 10)) {
                                    tempArray.push(item);
                                }
                            });
                            this[key] = tempArray.length > 0 ? tempArray : null;
                        }
                        break;
                    case 'tagNames':
                        if (queryParams[key] && queryParams[key] !== undefined) {
                            const tempArray = [];
                            uniq(queryParams[key].split(',')).forEach((item: string) => {
                                tempArray.push(item);
                            });
                            this[key] = tempArray.length > 0 ? tempArray : null;
                        }
                        break;
                }
            }
        }
    }
}
