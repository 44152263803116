import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnChanges {

    @Input() labelText: string;
    @Input() showRequired = true;
    @Input() placeholder = '';
    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() marginBottom = true;
    @Input() rows = 3;
    @Input() maxLength: number;
    @Input() canResize = true;

    formControl: FormControl;
    isRequired: boolean;

    constructor() { }

    ngOnChanges(): void {
        const formControl = this.formGroup?.get(this.formControlKey) as FormControl;
        this.formControl = formControl ?? new FormControl();
        if (this.formControl.validator) {
            this.isRequired = this.formControl?.validator('' as any)?.required;
        } else {
            this.isRequired = false;
        }
    }

}
