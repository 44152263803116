import { Component, Input } from '@angular/core';
import { Organisation } from '@app/shared/models/organisation.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-organisation-card-header',
    templateUrl: './organisation-card-header.component.html',
    styleUrls: ['./organisation-card-header.component.scss']
})
export class OrganisationCardHeaderComponent {

    readonly organisationTerm = environment.configuration.terminology.organisation;
   
    @Input() organisation: Organisation;

    constructor() { }

}
