import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {

    @Input() header: string;
    @Input() theme: 'primary' | 'secondary' | 'light' = 'light';

    constructor() { }
}
