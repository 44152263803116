import { Expose, Transform, Type } from 'class-transformer';
import { BaseModel } from 'django-rest-core';
import { Address } from './address.model';
import { Gender } from './gender.model';
import { Issue } from './issue.model';
import { LocalAuthority } from './localAuthority.model';
import { Staff } from './staff.model';

export class Organisation extends BaseModel {

    uid: string;
    name: string;
    active: boolean;
    description: string;
    criteria: string;
    logo: string;
    website: string;
    alert: string;
    tags: string[];
    skype: string;

    @Type(() => Address)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    address: Address;

    @Expose({name: 'phone_number'})
    phoneNumber: string;

    @Expose({name: 'service_offer'})
    serviceOffer: string;

    @Expose({name: 'contact_email'})
    email: string;

    @Expose({name: 'accepts_referrals'})
    acceptsReferrals: boolean;

    @Expose({name: 'accepting_referrals'})
    acceptingReferrals: boolean;

    @Expose({name: 'location_latitude'})
    locationLatitude: number;

    @Expose({name: 'location_longitude'})
    locationLongitude: number;

    @Expose({name: 'maximum_age'})
    maximumAge: number;

    @Expose({name: 'minimum_age'})
    minimumAge: number;

    @Type(() => Gender)
    @Transform((params) => params.value ? params.value.map((item: Gender) => item.url) : undefined, { toPlainOnly: true })
    genders: Gender[];

    @Expose({name: 'accepted_issues'})
    @Type(() => Issue)
    @Transform((params) => params.value ? params.value.map((item: Issue) => item.url) : undefined, { toPlainOnly: true })
    acceptedIssues: Issue[];

    @Expose({name: 'accepted_secondary_issues'})
    @Type(() => Issue)
    @Transform((params) => params.value ? params.value.map((item: Issue) => item.url) : undefined, { toPlainOnly: true })
    acceptedSecondaryIssues: Issue[];

    @Expose({name: 'accepts_referrals_from'})
    @Type(() => Organisation)
    @Transform((params) => params.value ? params.value.map((item: Organisation) => item.url) : undefined, { toPlainOnly: true })
    acceptsReferralsFrom: Organisation[];

    @Expose({name: 'admin_organisation'})
    adminOrganisation: boolean;

    @Expose({name: 'local_authorities'})
    @Type(() => LocalAuthority)
    @Transform((params) => params.value ? params.value.map((item: LocalAuthority) => item.url) : undefined, { toPlainOnly: true })
    localAuthorities: LocalAuthority[];

    @Type(() => Organisation)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    parent: Organisation;

    @Type(() => Staff)
    @Transform((params) => params.value ? params.value.map((staff: Staff) => staff.url) : undefined, { toPlainOnly: true })
    staff: Staff[];
}
