import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { OrganisationAlphabetIndex } from '@app/shared/models/organisationAlphabetIndex.model';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { OrganisationService } from '@app/core/services/organisation.service';

@Component({
    selector: 'app-alphabet-sort-list',
    templateUrl: './alphabet-sort-list.component.html',
    styleUrls: ['./alphabet-sort-list.component.scss'],
    animations: [
        trigger('alphaListAnimation', [
            transition(':enter', [
                query('.btn-character', [
                    style({ opacity: 0, transform: 'translateX(50%)' }),
                    stagger(10, [
                        animate('300ms ease', style({ opacity: 1, transform: 'translateX(0)' })),
                    ])
                ], {optional: true})
            ]),
            transition(':leave', [
                query('.btn-character', [
                    style({ opacity: 1, transform: 'translateX(0)' }),
                    stagger(10, [
                        animate('300ms ease', style({ opacity: 0, transform: 'translateX(50%)' }))
                    ])
                ], {optional: true})
            ]),
        ])
    ]
})
export class AlphabetSortListComponent implements OnInit {

    @Input() selectedLetter: string;
    @Input() showList = true;

    @Output() letterClicked = new EventEmitter();

    alphabet: OrganisationAlphabetIndex[] = [];
    mobileScreenSize = false;

    constructor(private organisationService: OrganisationService) { }

    @HostListener('window:resize', ['$event.target'])
    onResize(window: Window): void {
        if (window.innerWidth < environment.mobileScreenSize) {
            this.mobileScreenSize = true;
        } else {
            this.mobileScreenSize = false;
        }
    }

    ngOnInit(): void {
        this.onResize(window);
        this.organisationService.getOrganisationAlphabetIndexs(1).subscribe((response) => {
            this.alphabet = response;
        });
    }

    onLetterClicked(letter: string): void {
        this.letterClicked.emit(letter);
    }

}
