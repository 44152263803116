<div class="d-flex h-min-content" [ngClass]="{ 'mb5': showBottomMargin }">
    <app-icon [class.mt3]="hasInput"
        theme="clear"
        size="sm"
        [faIcon]="icon"
        [faType]="type"
        [faSize]="size"
        [tooltipText]="tooltipText"
        [alignment]="alignment"
        [placement]="placement"
        [ngxContainer]="ngxContainer"></app-icon>
    <div class="deep-w-100 w-100">
        <ng-content select="[field]"></ng-content>
    </div>
</div>
