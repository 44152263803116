<accordion-group [isDisabled]="collapsedPermanently" class="hide-header" [isOpen]="isOpen" (isOpenChange)="isOpen = $event">
    <div (click)="viewReferrals()" [ngClass]="customTopPadding ? customTopPadding : 'pt5'"
        class="row no-gutters flex-nowrap align-items-center justify-content-between pb5 cursor-pointer" accordion-heading>
        <div [ngClass]="{ 'border-tertiary': theme === 'white', 'text-white': theme !== 'white' }"
            class="card col-8 accordion-header-card mr2 {{ backgroundClass }}">
            <div class="card-body p3">
                <div class="row flex-nowrap no-gutters">
                    <div class="no-wrap xs-label show-ellipsis col" [title]="title">{{ title | uppercase }}</div>
                    <i *ngIf="!collapsedPermanently" class="far fa-chevron-down transition-fast ml3 mr3 col-auto" [class.rotate]="isOpen"></i>
                </div>
            </div>
        </div>
        <div class="square xs-label col-auto {{ backgroundClass }}"
            [ngClass]="{'border border-tertiary': theme === 'white', 'no-border text-white': theme !== 'white' }">
            {{ getReferralsCount() }}
        </div>
        <div *ngIf="collapsedPermanently" class="sm-text cursor-pointer col-auto">
            <i class="far fa-chevron-right ml3"></i>
        </div>
    </div>
    <div *ngIf="referralSummaries?.length > 0">
        <div *ngFor="let referralSummary of referralSummaries" class="d-flex cursor-pointer open-item">
            <div class="pb3 w-100">
                <div class="card">
                    <a (click)="routeToReferral(referralSummary.uid)" [routerLink]="[referralLink, referralSummary.uid]"
                        class="link-black no-text-decoration card-body row no-gutters p3 xs-text align-items-center justify-content-between">
                        <div class="col show-ellipsis" [title]="referralSummary?.name">{{ referralSummary?.name }}</div>
                        <div class="col-auto ml3">
                            <span class="font-weight-bold {{ fontClass }}">{{ referralSummary?.uid }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</accordion-group>
