import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Client } from '@app/shared/models/client.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-edit-client-card',
    templateUrl: './edit-client-card.component.html',
    styleUrls: ['./edit-client-card.component.scss']
})
export class EditClientCardComponent {

    readonly clientTerm = environment.configuration.terminology.client;

    @Input() client: Client;
    @Input() step: number;

    @Output() backClicked = new EventEmitter();
    @Output() editSuccessful = new EventEmitter();

    constructor() {}

    onEditSuccessful(client: Client): void {
        this.editSuccessful.emit(client);
    }

    handleBackClicked(): void {
        this.backClicked.emit();
    }

}
