import { Expose, Transform, Type } from 'class-transformer';
import { BaseModel } from 'django-rest-core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Status } from './status.model';

export class ReferralStatus extends BaseModel {

    @Expose({name: 'created_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    createdAt: Moment;

    @Expose({name: 'updated_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    updatedAt: Moment;

    @Type(() => Status)
    status: Status;
}
