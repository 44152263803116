import { Component, OnInit } from '@angular/core';
import { IssueService } from '@app/core/services/issue.service';
import { Issue } from '@app/shared/models/issue.model';
import { BaseOpenSelectComponent } from '../base-open-select/base-open-select.component';

@Component({
    selector: 'app-issue-open-select',
    templateUrl: '../base-open-select/base-open-select.component.html',
})
export class IssueOpenSelectComponent extends BaseOpenSelectComponent<Issue> implements OnInit {

    constructor(
        private issueService: IssueService,
    ) {
        super();
    }

    getData(page: number, filter?): void {
        this.issueService.getPagedList(page, filter).subscribe((response) => {
            this.data = response.results;
        });
    }

}
