<div *ngIf="formControl" [class.mb5]="marginBottom">
    <label *ngIf="labelText" class="xs-label text-uppercase">
        {{ labelText }}
        <span *ngIf="isRequired" class="badge badge-primary">Required</span>
    </label>
    <div class="d-flex flex-column">
        <textarea [maxlength]="maxLength ? maxLength : '' " class="form-control font-weight-normal light-gray-border" [rows]="rows"
            [formControl]="formControl" [placeholder]="placeholder" [class.resize-none]="!canResize"></textarea>
        <span class="align-self-end xs-text" *ngIf="maxLength">Max characters: {{maxLength}}</span>
    </div>
</div>