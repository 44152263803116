import { Injectable } from '@angular/core';
import { Toast } from '@app/shared/models/toast/toast.model';
import { Queue } from '@app/shared/models/utils/queue';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

    private addSubject = new Subject<Toast>();
    private addSubject$ = this.addSubject.asObservable();

    private closeAllSubject = new Subject<Toast>();
    private closeAllSubject$ = this.closeAllSubject.asObservable();

    private toasts: Queue<Toast> = new Queue<Toast>();
    private hasQueueSubject = new Subject<boolean>();
    private hasQueueSubject$ = this.hasQueueSubject.asObservable();

    add(toast: Toast): void {
        if (!Array.isArray(toast.message)) {
            toast.message = [toast.message];
        }

        this.addSubject.next(Object.assign({}, {
            persistent: false,
            autoClose: 2000
        }, toast));
    }


    getAdd(): Observable<Toast> {
        return this.addSubject$;
    }

    closeAll() {
        this.closeAllSubject.next();
    }

    getCloseAll(): Observable<Toast> {
        return this.closeAllSubject$;
    }

    queue(toast: Toast): void {
        this.toasts.push(toast);
        this.hasQueueSubject.next(true);

    }

    showQueued(): void {
        while (!this.isEmpty()) {
            this.add(this.toasts.pop());
        }
    }

    hasQueue(): Observable<boolean> {
        if (!this.isEmpty()) {
            return of(true);
        }
        return this.hasQueueSubject$;
    }

    isEmpty = () => this.toasts.isEmpty();
}
