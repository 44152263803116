<!-- Padding allows headings to line up with card list content -->
<div class="row pl5 pr5">
    <div class="col-12">
        <div class="row no-wrap mb3 {{ customClass }}">
            <div *ngFor="let heading of headings"
                class="{{ heading.colSpan ? 'col-' + heading.colSpan : 'col' }} text-uppercase xs-label med-opacity show-ellipsis"
                [ngClass]="{ 'text-center': heading.textCenter, 'required-field': heading.isRequired }"
                [title]="heading.title">
                {{ heading.title }}
            </div>
        </div>
    </div>
</div>
