import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

    @Input() header: string;
    @Input() hasFooter = true;
    @Input() showClose = false;

    @Output() closeClickedEvent = new EventEmitter();

    constructor() { }

    closeClicked = () => this.closeClickedEvent.emit();
}
