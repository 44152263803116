import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientService } from '@app/core/services/client.service';
import { ClientFormService } from '@app/core/services/forms/client-form.service';
import { ToastService } from '@app/core/services/toast.service';
import { BaseFormComponent } from '@app/shared/components/forms/base-form/base-form.component';
import { Client } from '@app/shared/models/client.model';

@Component({
  selector: 'app-client-contact-details-form',
  templateUrl: './client-contact-details-form.component.html',
  styleUrls: ['./client-contact-details-form.component.scss']
})
export class ClientContactDetailsFormComponent extends BaseFormComponent {

    _client: Client;
    @Input() set client(client: Client) {
        this._client = client;
        this.formGroup = this.clientFormService.getForm(this._client);
    }

    @Input() hasCustomSave: boolean;

    @Output() backClicked = new EventEmitter();
    @Output() saveSuccessful = new EventEmitter<Client>();

    showSecondaryTelephone = false;

    get isContactDetailsValid(): boolean {
        if (!this.formGroup) {
            return false;
        }
        return this.formGroup.get('email').valid &&
                this.formGroup.get('primaryTelephone').valid &&
                this.formGroup.get('secondaryTelephone').valid &&
                this.formGroup.dirty;
    }

    constructor(
        protected toastService: ToastService,
        private clientService: ClientService,
        private clientFormService: ClientFormService
    ) {
        super(toastService);
    }

    toggleShowSecondaryTelephone(): void {
        this.showSecondaryTelephone = !this.showSecondaryTelephone;
        if (!this.showSecondaryTelephone) {
            if (this.formGroup.get('secondaryTelephone').value) {
                this.formGroup.get('secondaryTelephone').setValue(null);
                this.formGroup.markAsDirty();
            }
        }
    }

    initialize(): void {
        this.object = this._client;
        this.objectType = 'Client';
        this.objectService = this.clientService;
        this.formService = this.clientFormService;
        this.showSecondaryTelephone = this._client?.secondaryPhoneNumber ? true : false;
        if (!this.formGroup) {
            this.formGroup = this.formService.getForm(this.object);
        }
    }

    save(): void {
        if (this.hasCustomSave) {
            this.saveSuccessful.emit();
        } else {
            super.save(this._client);
        }
    }

    onSuccessfulSave(updatedClient: Client): void {
        this.saveSuccessful.emit(updatedClient);
    }

    goBack(): void {
        this.backClicked.emit();
    }

}
