<label id="date-of-birth" class="xs-label" [ngClass]="{'required-field': isRequired}">DATE OF BIRTH</label>
<i *ngIf="appIcon" class="fa-solid fa-circle-question primary-colour float-right" [tooltip]="appIconText" placement="right">
</i>
<div>
    <div class="row no-gutters dob-container">
        <div class="col" [formGroup]="dateOfBirthForm">
            <ng-select class="day" [formGroup]="dateOfBirthForm" formControlName="day" placeholder="Day"
                [items]="dayScope" [multiple]="false" [closeOnSelect]="true" (change)="setDateOfBirth()">
            </ng-select>
        </div>
        <div class="col" [formGroup]="dateOfBirthForm">
            <ng-select class="month" [formGroup]="dateOfBirthForm" formControlName="month" placeholder="Month"
                [items]="months" bindLabel="name" [multiple]="false" [closeOnSelect]="true" (change)="setDateOfBirth()">
            </ng-select>
        </div>
        <div class="col" [formGroup]="dateOfBirthForm">
            <ng-select class="year" [formGroup]="dateOfBirthForm" formControlName="year" placeholder="Year"
                [items]="yearScope" bindLabel="name" [multiple]="false" [closeOnSelect]="true"
                (change)="setDateOfBirth()">
            </ng-select>
        </div>
    </div>
    <app-error [class.mt3]="dateOfBirthForm.get('day').invalid" *ngIf="dateOfBirthForm" [control]="dateOfBirthForm.get('day')"></app-error>
</div>