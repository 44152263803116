import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-organisation-logo',
    templateUrl: './organisation-logo.component.html',
    styleUrls: ['./organisation-logo.component.scss']
})
export class OrganisationLogoComponent {

    _loaded = true;
    @Input() set loaded(loaded: boolean) {
        this._loaded = loaded;
        if (loaded === true) {
            this.spinnerService.hide(this.orgLogoSpinnerName);
        } else {
            this.spinnerService.show(this.orgLogoSpinnerName);
        }
    }

    @Input() logoUrl = environment.configuration.logo;
    @Input() name = environment.configuration.adminOrganisationAbbreviation;
    @Input() hasBorder = false;
    @Input() width = '75px';
    // Forces height to be the same as width
    @Input() squareLogo = true;
    @Input() height = this.squareLogo ? this.width : null;

    orgLogoSpinnerName = 'org-logo-spinner';

    constructor(
        private spinnerService: NgxSpinnerService
    ) { }

}
