import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivityFeedService } from '@app/core/services/activity-feed.service';
import { ActivityFeed } from '@app/shared/models/activity-feed.model';
import { ActivityFeedFilters } from '@app/shared/models/filters/activityFeed.model';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivityFeedActivityType, ActivityFeedObjectType } from '@app/shared/models/enums/activityFeedObjectTypes.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
@Component({
    selector: 'app-activity-feed',
    templateUrl: './activity-feed.component.html',
    styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent implements OnInit {

    readonly referralLink = environment.links.referral;
    readonly caseLink = environment.links.case;
    readonly caseTerm = environment.configuration.terminology.case;
    readonly referralTerm = environment.configuration.terminology.referral;

    @Input() caseId: string;
    @Input() referralId: string;
    @Input() hideSwitch = false;
    @Input() infiniteScrollContainer = '';

    activityFeeds: ActivityFeed[] = [];
    form: FormGroup;
    subscriptions = new Subscription();
    loaded = true;
    hasNextPage = true;
    page = 1;
    spinnerName = 'activityFeedSpinner';
    activityFeedObjectType = ActivityFeedObjectType;

    constructor(
        private spinnerService: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private activityFeedService: ActivityFeedService,
        private router: Router
    ) { }

    get allCaseActivityControl() {
        return this.form.get('allCaseActivity');
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            allCaseActivity: [false]
        });
        this.subscriptions.add(this.allCaseActivityControl.valueChanges.subscribe(() => {
            this.getActivityFeeds(true);
        }));
        this.subscriptions.add(this.activityFeedService.hasUpdated().subscribe(() => {
            this.getActivityFeeds(true);
        }));
        this.form.get('allCase')
        this.getActivityFeeds();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getActivityFeeds(reset = true): void {
        this.loaded = false;
        this.spinnerService.show(this.spinnerName);
        const filter = new ActivityFeedFilters();
        if (this.hideSwitch) {
            filter.caseId = this.caseId;
        } else if (this.allCaseActivityControl.value) {
            filter.caseId = this.caseId;
            filter.referralId = this.referralId;
        } else {
            filter.referralId = this.referralId;
        }

        if (reset) {
            this.page = 1;
        } else {
            this.page += 1;
        }

        this.activityFeedService.getPagedList(this.page, filter, '-object_timestamp').subscribe((response) => {
            if (reset) {
                this.activityFeeds = response.results;
            } else {
                this.activityFeeds = [...this.activityFeeds, ...response.results];
            }

            this.hasNextPage = response.next ? true : false;
            this.loaded = true;
            this.spinnerService.hide(this.spinnerName);
        })
    }

    formatTimestamp(moment: Moment) {
        return moment.format('DD MMM YYYY [at] h[:]mm a')
    }

    getActivityType(activityType: string) {
        switch(activityType) {
            case ActivityFeedActivityType.UPDATE:
                return 'Updated';
            case ActivityFeedActivityType.ADD:
                return 'Added';
            case ActivityFeedActivityType.REMOVE:
                return 'Removed';
        }
    }

    getObjectType(objectType: ActivityFeedObjectType) {
        return ActivityFeedObjectType[objectType];
    }

    onScroll() {
        if (this.loaded && this.hasNextPage) {
            this.getActivityFeeds(false);
        }
    }

    linkClicked(url: string, uid: string): void {
        if (this.router.url.includes(url)) {
            this.router.navigate([url, uid]).then(() => {
                window.location.reload();
            });
        }
    }

}
