import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '@app/shared/models/user.model';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '@app/core/services/configuration.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

    logoLink = environment.configuration.logo;
    readonly dashboardLink = environment.links.dashboard;
    readonly adminOrganisationAbbreviation = environment.configuration.adminOrganisationAbbreviation;

    @Output() logoClicked = new EventEmitter();

    @Input() user: User;

    isNavbarOpen = false;
    subscriptions = new Subscription();

    constructor(private configService: ConfigurationService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.configService.hasUpdated().subscribe(() => {
                this.logoLink = environment.configuration.logo;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onLogoClicked(): void {
        this.logoClicked.emit();
    }    

}
