import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientService } from '@app/core/services/client.service';
import { ClientFormService } from '@app/core/services/forms/client-form.service';
import { ToastService } from '@app/core/services/toast.service';
import { BaseFormComponent } from '@app/shared/components/forms/base-form/base-form.component';
import { Client } from '@app/shared/models/client.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-basic-info-form',
  templateUrl: './client-basic-info-form.component.html',
  styleUrls: ['./client-basic-info-form.component.scss']
})
export class ClientBasicInfoFormComponent extends BaseFormComponent {

    readonly showGPInfo = environment.configuration.showGPInfo;
    readonly showNhsNumber = environment.configuration.showNhsNumber;
    readonly titleTypes = [
        'Mrs', 'Mr', 'Miss'
    ];

    _client: Client;
    @Input() set client(client: Client) {
        this._client = client;
        this.formGroup = this.clientFormService.getForm(this._client);
    }

    @Input() hasCustomSave: boolean;

    @Output() backClicked = new EventEmitter();
    @Output() saveSuccessful = new EventEmitter<Client>();

    get isBasicInfoValid(): boolean {
        if (!this.formGroup) {
            return false;
        }
        return this.formGroup.get('firstName').valid &&
                this.formGroup.get('lastName').valid &&
                this.formGroup.get('dob').valid;
    }

    constructor(
        protected toastService: ToastService,
        private clientService: ClientService,
        private clientFormService: ClientFormService
    ) {
        super(toastService);
    }

    initialize(): void {
        this.object = this._client;
        this.objectType = 'Client';
        this.objectService = this.clientService;
        this.formService = this.clientFormService;
        if (!this.formGroup) {
            this.formGroup = this.formService.getForm(this.object);
        }
    }

    save(): void {
        if (this.hasCustomSave) {
            this.saveSuccessful.emit();
        } else {
            super.save(this._client);
        }
    }

    onSuccessfulSave(updatedClient: Client): void {
        this.saveSuccessful.emit(updatedClient);
    }

    goBack(): void {
        this.backClicked.emit();
    }

}
