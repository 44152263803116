<app-mobile-tabs [isMobileScreen]="mobileScreenSize" [mobileTabTitles]="['Info', 'Details']"
    [activeTabIndex]="activeMobileTabIndex" (onTabClicked)="activateTab($event)"></app-mobile-tabs>

<div class="d-flex w-100 light-background-colour">
    <div *ngIf="!mobileScreenSize || activeMobileTabIndex === 0" id="referral-exportable-container"
        class="col-md-4 col-xl-3 col sticky-top scrollbar-hidden h100"
        [ngClass]="{ 'p5 border-right vh-100-navbar': !mobileScreenSize }">
        <ng-content select="[leftSection]"></ng-content>
    </div>
    <div *ngIf="!mobileScreenSize || activeMobileTabIndex === 1" class="col-md-8 col-xl-9">
        <app-directory-page-layout [customHorizontalPadding]="customHorizontalPadding" *ngIf="mainDirectoryTitle"
            [directory]="directory" [mainTitle]="mainDirectoryTitle">
            <ng-content headerItems select="[headerItems]"></ng-content>
        </app-directory-page-layout>
        <ng-content select="[middleSection]"></ng-content>
    </div>
</div>