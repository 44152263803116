<main>
    <header ngClass="{{customHorizontalPadding ? customHorizontalPadding : 'pr5 pl5'}}"
        class="border-bottom d-flex flex-wrap justify-content-between align-items-{{ alignItems }} pt5 pb5">
        <app-directory [directory]="directory" [mainTitle]="mainTitle" [subtitle]="subtitle"
            [hasCustomMainTitle]="hasCustomMainTitle">
            <div *ngIf="hasCustomMainTitle" mainTitle>
                <ng-content select="[mainTitle]"></ng-content>
            </div>
        </app-directory>
        <div class="h-min-content">
            <ng-content select="[headerItems]"></ng-content>
        </div>
    </header>
    <div>
        <ng-content select="[body]"></ng-content>
    </div>
</main>