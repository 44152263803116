import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BaseReadWriteService } from '@app/core/services/base-read-write.service';
import { FormArrayBaseService } from '@app/core/services/forms/form-array-base.service';
import { ToastService } from '@app/core/services/toast.service';
import { BaseModel } from '@app/shared/models/base-model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-base-form-array',
    template: '',
})
export class BaseFormArrayComponent<T extends BaseModel> implements OnChanges {

    @Input() objects: T[] = [];

    objectType: string;
    formGroup: FormGroup;
    waitingOnRequests = false;
    formService: FormArrayBaseService<T>;
    objectService: BaseReadWriteService<T, any>;

    constructor(
        protected toastService: ToastService
    ) {}

    get formArray(): FormArray {
        return this.formGroup?.get('formArray') as FormArray;
    }

    ngOnChanges(c: SimpleChanges): void {
        if (c?.objects && !c.objects.firstChange) {
            this.setForm();
        }
    }

    setForm(): void {
        this.formGroup = this.formService.createFormArray(this.objects);
    }

    addNewForm(): void {
        this.formArray.push(this.formService.createForm());
    }

    isFormValid(form: FormGroup): boolean {
        return form.valid && form.dirty;
    }

    cancelForm(form: FormGroup): void {
        if (form.value.uid) {
            const object = this.objects.find(item => item.uid === form.value.uid);
            const prestineValue = this.formService.createForm(object).value;
            form.setValue(prestineValue);
            form.markAsPristine();
        } else {
            const formIndex = this.formArray.controls.findIndex(formGroup => formGroup === form)
            this.formArray.removeAt(formIndex);
        }
    }

    saveObject(form: FormGroup) {
        this.waitingOnRequests = true;
        const newObject = this.formService.from(form);
        let source: Observable<T>;

        if (newObject.uid) {
            source = this.objectService.update(newObject.uid, newObject);
        } else {
            source = this.objectService.add(newObject);
        }

        source.subscribe((response: T) => {
            form.markAsPristine();
            this.objectService.emitUpdated();
            if (newObject.uid) {
                const newObjectIndex = this.objects.findIndex(item => item.uid === newObject.uid);
                this.objects[newObjectIndex] = response;
            } else {
                this.objects.push(response);
            }
            this.toastService.add(
                { title: 'Success', message: `${newObject.uid ? 'Updated' : 'Added' } ${this.objectType} successfully.`, type: 'success' }
            );
            this.waitingOnRequests = false;
        }, () => {
            this.toastService.add(
                { title: 'Error', message: `${newObject.uid ? 'Updating' : 'Adding' } ${this.objectType} failed.`, type: 'error' }
            );
            this.waitingOnRequests = false;
        })
    }

}
