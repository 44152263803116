import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/shared/models/validators/custom-validators';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
    selector: 'app-date-of-birth-select',
    templateUrl: './date-of-birth-select.component.html',
    styleUrls: ['./date-of-birth-select.component.scss'],
})
export class DateOfBirthSelectComponent implements OnInit {

    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() appIcon = false;
    @Input() appIconText: string;
    @Input() isRequired = true;

    @Output() dobUpdated = new EventEmitter();

    constructor(private formBuilder: FormBuilder) { }

    today: Date;
    dateOfBirthForm: FormGroup = this.formBuilder.group(
        {
            day: [],
            month: [],
            year: [],
        },
        {
            validators: [
                CustomValidators.dateOfBirthCheck('day', 'month', 'year'),
            ],
        }
    );
    dayScope: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
    yearScope: number[] = [];
    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    ngOnInit(): void {
        this.today = new Date();
        this.setYearScope();

        if (this.formGroup.get(this.formControlKey).value) {
            const date: Moment = this.formGroup.get(this.formControlKey).value;
            this.dateOfBirthForm.get('day').setValue(date.format('DD'));
            this.dateOfBirthForm.get('month').setValue(date.format('MMMM'));
            this.dateOfBirthForm.get('year').setValue(date.format('YYYY'));
        }
    }

    setYearScope(): void {
        const year: number = this.today.getFullYear();
        for (let i = 0; i < 100; i++) {
            this.yearScope.push(year - i);
        }
    }

    setDateOfBirth(): void {
        const day = this.dateOfBirthForm.get('day')?.value;
        const month = this.dateOfBirthForm.get('month').value;
        const year = this.dateOfBirthForm.get('year')?.value;
        if (
            day &&
            month &&
            year !== null &&
            this.dateOfBirthForm.valid
        ) {
            const dobString = `${year}-${month}-${day}`;
            const momentDate = moment(dobString);
            this.formGroup.get(this.formControlKey).setValue(momentDate);
        } else {
            this.formGroup.get(this.formControlKey).setValue(null);
        }
    }
}
