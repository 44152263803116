<div class="card step-card d-flex flex-row justify-content-start align-items-center pr8 mb2 "
    [ngClass]="{
        'active' : step.number === activeStepIndex,
        'inactive' :  step.number > activeStepIndex,
        'completed': step.number < activeStepIndex
    }">
    <div class="circlediv">
        <span class="circle">
            <i *ngIf="step.number < activeStepIndex" class="fa-solid fa-check"></i>
        </span>
    </div>
    <div class="textdiv">
        <div class="xs-text text-primary font-weight-bold">Step {{ step.number }}</div>
        <div class="step-text sm-text text-nowrap">{{ step.title }}</div>
    </div>
</div>
