<div class="card">
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="sm-label modal-title">Inactivity Warning!</h3>
            <i class="fal fa-times link-tertiary cursor-pointer" (click)="keepLoggedIn()" tabindex="-1"></i>
        </div>
        <div class="mt5">
            <p>
                Your session will expire in {{ formatSessionTimeRemaining(subscriberTime) }}. Do you want to renew
                your session?
            </p>
        </div>
        <div>
            <button type="button" class="btn btn-outline-primary mr3" (click)="logOut()">Logout</button>
            <button type="button" class="btn btn-primary" (click)="keepLoggedIn()">Yes, stay logged in!</button>
        </div>
    </div>
</div>
