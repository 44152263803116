import { Component, OnInit } from '@angular/core';
import { PagedResponse } from 'django-rest-core';
import { Observable, of } from 'rxjs';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { DashboardTimePeriod } from '@app/shared/models/enums/enums.model';
import { MetadataEnum } from '@app/shared/models/metadataEnum.model';

@Component({
    selector: 'app-time-period-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class TimePeriodSelectComponent extends SelectBaseComponent<MetadataEnum> implements OnInit {

    constructor(
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.bindToField = 'title';
        this.bindToValue = 'value';
        this.getData(null).subscribe((response) => {
            this.options = response.results;
        });
    }

    getData(page: number): Observable<PagedResponse<MetadataEnum>> {
        const data = new PagedResponse<MetadataEnum>();
        data.results = [];

        for (const dashboardTimePeriod of Object.values(DashboardTimePeriod).filter(Number)) {
            switch (dashboardTimePeriod) {
                case DashboardTimePeriod.WEEK:
                    data.results.push(new MetadataEnum(DashboardTimePeriod.WEEK as any, 'Last 7 days'));
                    break;
                case DashboardTimePeriod.MONTH:
                    data.results.push(new MetadataEnum(DashboardTimePeriod.MONTH as any, 'Last 30 days'));
                    break;
                case DashboardTimePeriod.ALLTIME:
                    data.results.push(new MetadataEnum(DashboardTimePeriod.ALLTIME as any, 'All Time'));
                    break;
            }
        }

        return of(data);
    }

}
