import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[template-name]'
})
export class TableColumnDirective {

    @Input('template-name') columnName: string;

    constructor(public readonly template: TemplateRef<any>) { }

}
