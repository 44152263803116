<div class="card">
    <div class="card-body p5">
        <div class="row align-items-center">
            <div class="col-auto">
                <div class="primary-colour">
                    <i class="fal fa-3x {{ file.faIcon ? file.faIcon : 'fa-file' }}"></i>
                </div>
            </div>
            <div class="col">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="xs-text">
                        <div class="font-weight-bold">{{ file.title }}</div>
                    </div>
                    <div>
                        <app-dropdown [iconColourDark]="true" ngxContainer="body">
                            <div items>
                                <app-dropdown-item text="Download File" (click)="onDownloadClicked()">
                                </app-dropdown-item>
                                <app-dropdown-item text="Remove File" (click)="onRemoveClicked()">
                                </app-dropdown-item>
                            </div>
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>