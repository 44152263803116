import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { UserFilters } from '@app/shared/models/filters/userFilters.model';
import { BaseReadWriteService } from './base-read-write.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseReadWriteService<User, UserFilters>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'users';
        this.exampleObject = new User();
    }

}
