<app-form-card>
    <div class="font-weight-bold" header>
        <app-dropdown *ngIf="canEdit" class="float-right">
            <div items>
                <app-dropdown-item text="Edit Details" (click)="handleEditClient(1)"></app-dropdown-item>
                <app-dropdown-item text="Edit Contact Details"
                    (click)="handleEditClient(2)"></app-dropdown-item>
                <app-dropdown-item text="Edit Address" (click)="handleEditClient(3)"></app-dropdown-item>
            </div>
        </app-dropdown>
        <div>
            <div *ngIf="client?.firstName" class="header-xs mb3">
                {{client?.title}} {{client.firstName}} {{client?.lastName}}
            </div>
            <div class="sm-text mb3 show-ellipsis" *ngIf="client?.email">{{client?.email}}</div>
            <div class="font-smaller primary-colour">{{client?.dateOfBirth | date:'dd MMM YYYY'}}</div>
        </div>
    </div>
    <div class="sm-text" body>
        <div class="row gx0 gy3">
            <div class="col-12">
                <div class="row">
                    <div class="col-1">
                        <i class="fa-regular fa-face-viewfinder primary-colour"></i>
                    </div>
                    <div class="col">
                        <div class="text-capitalize">{{client?.preferredPronouns ? client?.preferredPronouns : '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-left">
                    <div class="col-1">
                        <i class="fa-regular fa-venus-mars primary-colour"></i>
                    </div>
                    <div class="col">
                        <div>{{client?.gender?.name ? client?.gender?.name : '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-1">
                        <i class="fa-regular fa-house primary-colour"></i>
                    </div>
                    <div class="col">
                        <div *ngIf="client?.address; else noFixedAddress">
                            {{ client?.address.firstLine ? client.address.firstLine + ',' : '-' }}
                            {{ client?.address?.secondLine ? client.address.secondLine + ',' : '' }}
                            {{ client?.address.town ? client.address.town + ',' : '-' }}
                            {{ client?.address.county ? client.address.county + ',' : '' }}
                            {{ client?.address.postcode ? client.address.postcode : '' }}
                        </div>
                        <ng-template #noFixedAddress>
                            No Fixed Address
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="client?.localAuthority" class="col-12">
                <div class="row">
                    <div class="col-1">
                        <i class="fa-regular fa-location-pin primary-colour"></i>
                    </div>
                    <div class="col">
                        <div>
                            {{client?.localAuthority.name ? client?.localAuthority.name : '-' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-1">
                        <i class="fa-regular fa-phone primary-colour"></i>
                    </div>
                    <div class="col">
                        <div>{{client?.primaryPhoneNumber ? client?.primaryPhoneNumber : '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div *ngIf="client?.secondaryPhoneNumber" class="row justify-content-left">
                    <div class="col-1">
                        <i class="fa-regular fa-phone-plus primary-colour"></i>
                    </div>
                    <div class="col">
                        <div>{{client?.secondaryPhoneNumber}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt5">
            <div class="xs-label pb3">PERMISSIONS</div>
            <div class="col-12 p0">
                <div class="row">
                    <div class="col-auto">
                        <div class="row">
                            <div class="col-1">
                                <i [ngClass]="['fa-regular pr3', client?.leaveText ? 'fa-circle-check primary-colour' : 'fa-circle-xmark tertiary']"></i>
                            </div>
                            <div class="col">
                                <span class="sm-text no-wrap">Text (SMS)</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="row">
                            <div class="col-1">
                                <i [ngClass]="['fa-regular pr3', client?.leaveMessage ? 'fa-circle-check primary-colour' : 'fa-circle-xmark tertiary']"></i>
                            </div>
                            <div class="col">
                                <span class="sm-text no-wrap">Voicemail</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showNhsNumber" class="mt5">
            <div class="xs-label pb3">NHS NUMBER</div>
            <div class="row gy3">
                <div class="col-11">
                    <div class="row">
                        <div class="col-1">
                            <i class="fa-regular fa-hospital-user primary-colour "></i>
                        </div>
                        <div class="col">
                            <div>{{client?.nhsNumber ? client?.nhsNumber : '-'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showGPInfo" class="mt5">
            <div class="xs-label pb3">GP SURGERY</div>
            <div class="row gy3">
                <div class="col-11">
                    <div class="row">
                        <div class="col-1">
                            <i class="fa-regular fa-stethoscope primary-colour"></i>
                        </div>
                        <div class="col">
                            <div class="wrap-text">{{client?.gpInfo ? client?.gpInfo : '-'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-form-card>