<a class="list-group-item list-group-item-action border-0 p0 m0"
    [class.has-click-event]="hasClickEvent"
	(click)="clicked($event)"
	(keyup.enter)="clicked($event)"
	tabIndex="0">
    <span class="d-flex align-items-center py-1 px-4" [class.active]="isActive">
	    <div *ngIf="faIcon"
	    	class="icon-container">
	       	<i class="{{ type }} fa-{{ faIcon }}"
	       		[class.text-danger]="displayAsWarning"></i>
	    </div>
        <span class="show-ellipsis text-nowrap">{{ text }}</span>
        <app-right-arrow-icon *ngIf="!displayAsWarning"></app-right-arrow-icon>
    </span>
</a>