<div class="row no-gutters align-items-center justify-content-between g0">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="progress">
                <div class="progress-bar tertiary-background-colour" role="progressbar" [ngStyle]="{'width': width + '%' }" 
                    [attr.aria-valuenow]="value" [attr.aria-valuemin]="0" [attr.aria-valuemax]="max">
                </div>
            </div>
        </div>
    </div>
    <div class="col-auto d-flex justify-content-end">
        <span *ngIf="hasProgressText" class="sm-text no-wrap pl4">{{ width }}%</span>
    </div>
</div>
