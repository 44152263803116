import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-list-group-item',
    templateUrl: './list-group-item.component.html',
    styleUrls: ['./list-group-item.component.scss']
})
export class ListGroupItemComponent implements OnInit {

    @Input() item: string;
    @Input() label: string;

    constructor() { }

    ngOnInit(): void {
    }

}
