import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedResponse } from 'django-rest-core';
import { Observable } from 'rxjs';
import { Organisation } from '@app/shared/models/organisation.model';
import { OrganisationFilters } from '@app/shared/models/filters/organisationFilters.model';
import { BaseReadWriteService } from './base-read-write.service';
import { OrganisationAlphabetIndex } from '@app/shared/models/organisationAlphabetIndex.model';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends BaseReadWriteService<Organisation, OrganisationFilters>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'organisations';
        this.defaultSort = 'name';
        this.exampleObject = new Organisation();
    }

    getCompactOrganisations(page: number, filter?: OrganisationFilters, sortBy?: string): Observable<PagedResponse<Organisation>> {
        if (!sortBy) {
            sortBy = 'name';
        }
        return this.getPagedList(page, filter, sortBy, 'compact');
    }

    getOrganisationsSelectOptions(page: number, filter?: OrganisationFilters, sortBy?: string): Observable<PagedResponse<Organisation>> {
        return this.getPagedList(page, filter, sortBy, 'search');
    }

    getOrganisationAlphabetIndexs(
        page: number,
        filter?: OrganisationFilters,
        sortBy?: string
    ): Observable<OrganisationAlphabetIndex[]> {
        return this.setTypeOnResponse(this.getPagedList(page, filter, sortBy, 'alphabet_index', false), new OrganisationAlphabetIndex());
    }

}
