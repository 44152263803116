import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent {

    @Input() text: any;
    @Input() showRemove = false;
    @Input() theme: 'default' | 'secondary' = 'default';
    @Input() tabIndex = 0;
    @Input() hasClickEvent: boolean;
    @Input() hasBorder: boolean;
    @Input() selected: boolean;
    @Input() disabled = false;

    @Output() clickEvent = new EventEmitter();

    constructor() { }

    onClick(): void {
        if (!this.disabled) {
            this.clickEvent.emit();
        }
    }

}
