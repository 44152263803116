<form *ngIf="formGroup" [formGroup]="formGroup"
    (click)="readOnly ? null : toggle()"
    class="d-flex align-items-center w-fit-content"
    [ngClass]="{ 'cursor-pointer': !readOnly, 'flex-row-reverse': hasLabelLeft }">
    <div class="switch mb0" [ngClass]="{ 'ml3': hasLabelLeft, 'mr3': !hasLabelLeft }">
        <input type="checkbox" [formControlName]="formControlKey">
        <span class="slider" [class.disabled]="readOnly"></span>
    </div>
    <span class="{{ textSize }}-{{ isTitle ? 'label' : 'text'}}"
        [ngClass]="{ 'font-weight-bold': isBold, 'text-nowrap': noWrap }">
        {{ switchLabel }}
    </span>
</form>
