import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from 'django-rest-core';
import { BaseReadWriteService } from '../base-read-write.service';
import { CaseNote } from '@app/shared/models/notes/case-note.model';

@Injectable({
    providedIn: 'root'
})
export class CaseNotesService extends BaseReadWriteService<CaseNote, IFilter>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'casenotes';
        this.exampleObject = new CaseNote();
    }

}
