import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemFile } from '@app/shared/models/files/file.model';

@Component({
    selector: 'app-file-card',
    templateUrl: './file-card.component.html',
    styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent {

    @Output() downloadClicked = new EventEmitter<SystemFile>();
    @Output() removeClicked = new EventEmitter<SystemFile>();

    @Input() file: SystemFile;

    constructor() { }

    onDownloadClicked(): void {
        this.downloadClicked.emit(this.file);
    }

    onRemoveClicked(): void {
        this.removeClicked.emit(this.file);
    }

}
