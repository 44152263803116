<div *ngIf="toasts.length > 0" class="toasts-container">
	<div *ngFor="let toast of toasts" (click)="close(toast)" [class.show]="toast.show" aria-atomic="true" aria-live="assertive" class="toast {{ toast.type }}" role="alert" >
		<div class="header d-flex justify-content-between align-items-center pt3 pr5 pb3 pl5">
		    <span class="d-flex align-items-center">
		    	<i class="fas fa-bell mr3"></i>
		    	<strong>{{ toast.title }}</strong>
		    </span>
		    <span class="fa-sm">Just now</span>
		</div>
		<div class="p5">
	    	<div *ngFor="let message of toast.message">{{ message }}</div>
	  	</div>
	</div>
</div>