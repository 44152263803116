import { Component, OnInit } from '@angular/core';
import { ReferralClosureReasonService } from '@app/core/services/referral/referral-closure-reason.service';
import { ReferralClosureReason } from '@app/shared/models/referralClosureReason.model';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-referral-closure-reason-select',
    templateUrl: '../select-base/select-base.component.html'
})
export class ReferralClosureReasonSelectComponent extends SelectBaseComponent<ReferralClosureReason> implements OnInit {

    constructor(
        private referralClosureReasonService: ReferralClosureReasonService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.referralClosureReasonService;
    }

}
