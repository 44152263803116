<a class="tag-container w-min-content mw-100 transition theme-{{ theme }}"
    [ngClass]="{
        'has-click-event': hasClickEvent && !disabled,
        'has-remove': showRemove,
        'has-border': hasBorder,
        'selected': selected,
        'disabled': disabled
    }"
	(click)="onClick()"
	(keyup.enter)="onClick()"
	tabindex="{{ hasClickEvent ? tabIndex : -1 }}">
	<div class="tag-label font-weight-extra-bold show-ellipsis">{{ text }}</div>
    <div *ngIf="showRemove"
    	class="tag-icon">
    	<i class="fal fa-times fa-sm"></i>
    </div>
</a>
