import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-mobile-tabs',
    templateUrl: './mobile-tabs.component.html',
    styleUrls: ['./mobile-tabs.component.scss']
})
export class MobileTabsComponent {

    @Input() mobileTabTitles: string[];
    @Input() activeTabIndex: number;
    @Input() isMobileScreen = false;

    @Output() onTabClicked = new EventEmitter<number>();

    constructor() { }

    onClick(index: number): void {
        this.onTabClicked.emit(index);
    }

}
