import { Inject, Injectable } from "@angular/core";
import { AuthenticationCoreInterceptor, TokenCoreService, Config, CONFIG } from "authentication-core";

@Injectable()
export class AuthenticationInterceptor extends AuthenticationCoreInterceptor {

    protected noAuthPaths: string[];
    protected tokenHeader = 'Bearer ';

    constructor(
        protected tokenService: TokenCoreService,
        @Inject(CONFIG) protected config: Config,
    ) { 
        super(tokenService, config);
    }

}
