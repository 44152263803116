import { Expose } from 'class-transformer';

export class OrganisationAlphabetIndex {

    @Expose({name: 'index_letter'})
    indexLetter: string;

    @Expose({name: 'index_letter_count'})
    indexLetterCount: number;
}
