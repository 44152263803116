import { Component, ContentChild, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';
import { TableColumnDirective } from '@app/shared/directives/template-name.directive';
import { cloneDeep } from 'lodash-es';
import { BaseListComponent } from '../../list/base-list/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent extends BaseListComponent implements OnInit {

    @ContentChildren(TableColumnDirective) templateVars: QueryList<TableColumnDirective>;
    @ContentChild('dropdown', {static: false}) dropdown: TemplateRef<any>;

    _loading: boolean;
    @Input() set loading(loading: boolean) {
        this._loading = loading;
        if (this._loading) {
            this.spinnerService.show(this.spinnerName);
        } else {
            this.spinnerService.hide(this.spinnerName);
        }
    }

    @Input() data: any[];
    @Input() rowConfigs: {
        field: string;
        colSpan: number;
        isBold: boolean;
        isCentered: boolean;
        customTemplateSelect: string;
    }[];
    @Input() hasDropdown = false;
    @Input() hasClickEvent = false;
    @Input() selectedData: any;
    @Input() customPadding: string;
    @Input() infiniteScrollContainer = '#default-page-router-wrapper';
    @Input() noDataMessage = 'No data found';

    @Output() clickEvent = new EventEmitter<PointerEvent>();

    // Reload infinite scroll component (fixes bug)
    initialLoad = false;
    spinnerName = 'card-list-spinner';

    constructor(
        private spinnerService: NgxSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.initialLoad = true;
        });
    }

    onClickEvent(pointerEvent: PointerEvent): void {
        this.clickEvent.emit(pointerEvent);
    }

    getField(item: any, field: string): any {
        let tempItem = cloneDeep(item);
        const fieldArray = field.split('.');
        for (let i = 0; i < fieldArray.length; i++) {
            tempItem = tempItem[fieldArray[i]];
            if (tempItem === null || tempItem === undefined) {
                return null;
            }
            if (i === fieldArray.length - 1) {
                return tempItem;
            }
        }
    }

    getTemplate(templateName: string): TemplateRef<any> {
        return this.templateVars.toArray().find(item => item.columnName === templateName).template;
    }

    stopPropagation(pointerEvent: PointerEvent): void {
        pointerEvent.stopPropagation();
    }

}
