export enum ActivityFeedObjectType {
    Case = 'Case',
    Referral = 'Referral',
    Client = 'Client',
    ReferralNote = 'Referral Note',
    CaseNote = 'Case Note'
}

export enum ActivityFeedActivityType {
    UPDATE = '~',
    ADD = '+',
    REMOVE = '-'
}