import { Component, OnDestroy } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service';
import { AuthenticationCoreService, TokenCoreService } from 'authentication-core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/ngx-bootstrap-modal';
import { Subscription } from 'rxjs';
import { SessionInactiveModalComponent } from './../session-inactive-modal/session-inactive-modal.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-session-inactive',
    template: '',
})
export class SessionInactiveComponent implements OnDestroy {

    modalRef: BsModalRef;
    activeSubscription = new Subscription();

    constructor(
        private authenticationService: AuthenticationCoreService,
        protected tokenService: TokenCoreService,
        private bsModalService: BsModalService,
        private modalService: ModalService,
    ) {
        // To test app-session-inactive-modal uncomment the line below.
        // environment.authentication.config.maxInactivePeriodInSeconds = environment.authentication.config.warningPeriodInSeconds + 1;
        this.authenticationService.setRequestTimer();

        this.activeSubscription = this.authenticationService.isSessionInactive.subscribe((value) => {
            if (value === true) {
                this.open();
            } else if (this.modalRef) {
                this.modalRef.hide();
            }
        });
    }

    ngOnDestroy(): void {
        this.activeSubscription.unsubscribe();
    }

    open(): void {
        this.modalService.create(this.bsModalService, (options: any) =>
            this.modalRef = this.bsModalService.show(SessionInactiveModalComponent, options), 'lg', null, false);
    }
}
