<app-icon
    [theme]="theme"
    (clickEvent)="clicked()"
    [hasClickEvent]="hasClickEvent"
    [tabIndex]="tabIndex"
    [size]="size"
    [tooltipText]="userTooltip"
    [ngxContainer]="ngxContainer"
    [placement]="placement"
    [isCircle]="isCircle">
    <div icon>
        <span *ngIf="user?.initials" class="d-block fa-{{ faSize }} header-font-logo">{{ user?.initials }}</span>
    </div>
</app-icon>
<ng-template #userTooltip>
    <span [innerHTML]="tooltipTitle ? tooltipTitle : user?.name"></span>
</ng-template>
