<div infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="infiniteScrollContainer"
    [fromRoot]="true"
    [alwaysCallback]="true"
    (scrolled)="onScroll()">
    <div *ngIf="!hideSwitch" class="pb3 mb3 border-bottom">
        <div class="xs-label">FILTER</div>
        <div class="d-flex">
            <app-switch switchLabel="All Case Activity" [formGroup]="form" formControlKey="allCaseActivity">
            </app-switch>
        </div>
    </div>
    <div class="row gy5">
        <div *ngFor="let activityFeed of activityFeeds" class="col-12">
            <div class="card mb2">
                <div class="card-body p3">
                    <div class="row no-gutters">
                        <div class="col-auto pr3"><i class="far fa-rotate"></i></div>
                        <div class="col sm-text">
                            <div class="row no-gutters">
                                <div class="col-auto pr3">
                                    <span class="font-weight-bold">{{ activityFeed.performedByName }}</span>
                                </div>
                                <div class="col-auto pr3 text-lowercase">
                                    <span>{{ getActivityType(activityFeed.activityType)  }}</span>
                                </div>
                                <div class="col-auto">
                                    <span>{{ getObjectType(activityFeed.objectType) }}</span>
                                </div>
                                <div class="col-auto">
                                    <!-- TODO: What should this be? Seems to not be working -->
                                    <span [ngSwitch]="activityFeed.objectType">
                                        <ng-container *ngSwitchCase="activityFeedObjectType.CASE"
                                            [ngTemplateOutlet]="caseType"
                                            [ngTemplateOutletContext]="{$implicit: activityFeed.caseId}"></ng-container>
                                        <ng-container *ngSwitchCase="activityFeedObjectType.REFERRAL"
                                            [ngTemplateOutlet]="referralType"
                                            [ngTemplateOutletContext]="{$implicit: activityFeed.referralId, caseId: activityFeed.caseId}"></ng-container>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between">
                <span class="col-auto ml2 xxs-text gray-darker show-ellipsis text-nowrap font-weight-bold"
                    [title]="formatTimestamp(activityFeed.objectTimestamp)">
                    {{ formatTimestamp(activityFeed.objectTimestamp) }}
                </span>
                <span class="col-auto mr2 xxs-text gray-darker show-ellipsis font-weight-bold"
                    [title]="activityFeed.performedByEmail">
                    {{ activityFeed.performedByEmail }}
                </span>
            </div>
        </div>
    </div>
    <h1 *ngIf="activityFeeds.length === 0 && loaded" class="header-xs text-center">
        No Activity
    </h1>
    <div *ngIf="!loaded" class="spinner-container">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0)" [name]="spinnerName" type="ball-spin-clockwise" color="#000" [fullScreen]="false" size="small"></ngx-spinner>
    </div>
</div>

<ng-template #caseType let-caseId>
    <a class="link" (click)="linkClicked(caseLink, caseId)" [routerLink]="[caseLink, caseId]">#{{ caseId }}</a>
</ng-template>

<ng-template #referralType let-referralId let-caseId="caseId">
    <a class="link" (click)="linkClicked(referralLink, referralId)" [routerLink]="[referralLink, referralId]">#{{ referralId }}</a> on Case
    <a class="link" (click)="linkClicked(caseLink, caseId)" [routerLink]="[caseLink, caseId]">#{{ caseId }}</a>
</ng-template>