<div class="sm-text">
    <!-- <div *ngIf="organisation.age && extraDetails" class="d-flex align-items-center mb3">
        <i class="fa-regular fa-clock-rotate-left text-primary mr3"></i>
        Member since {{ organisation.age formated }}
    </div> -->
    <div class="d-flex align-items-center mb3">
        <i class="fa-regular fa-phone text-primary mr3"></i>
        <a href="tel:{{ organisation?.phoneNumber }}" class="link-black">{{
            organisation?.phoneNumber ? organisation.phoneNumber : '-' }}</a>
    </div>
    <div class="d-flex align-items-center mb3">
        <i class="fa-regular fa-envelope text-primary mr3"></i>
        <a [title]="organisation?.email" href="mailto:{{ organisation?.email }}" class="link-black show-ellipsis">{{
            organisation?.email
            ?
            organisation.email : '-'
            }}</a>
    </div>
    <div class="d-flex align-items-center mb3">
        <i class="fa-regular fa-crosshairs text-primary mr3"></i>
        <div>
            {{ organisation?.address ? (organisation.address | address: ['town', 'postcode']) : '-' }}
        </div>

    </div>
    <div *ngIf="organisation?.website && extraDetails" class="d-flex align-items-center mb3">
        <i class="fa-regular fa-window-maximize text-primary mr3"></i>
        <a [href]="organisation?.website" target="_blank" class="link-black">{{ organisation?.website ?
            organisation.website : '-' }}</a>
    </div>
</div>