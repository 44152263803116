import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-base-filter',
  template: '',
})
export class BaseFilterComponent implements OnInit {

    formGroup: FormGroup;
    filters: any;
    emitChangesEnabled = true;
    firstLoad = true;
    filterFormService: any;

    _searchTerm: string;
    @Input() set searchTerm(searchTerm: string) {
        if (this.formGroup && this.formGroup.get('name').value !== searchTerm) {
            this.formGroup.get('name').setValue(searchTerm);
            this.onSelectedOptionsChange();
        }
        this._searchTerm = searchTerm;
    }

    @Output() filtersChanged = new EventEmitter();

    get isValid(): boolean {
        return this.formGroup.valid && this.formGroup.dirty;
    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
    ) { }

    ngOnInit(): void {
        this.initializeForm();
        this.activatedRoute.queryParams.subscribe((params) => {
            if (this.firstLoad) {
                this.firstLoad = false;
                this.filters.fromUrl(params);
                this.setForm();
            }
        });
    }

    initializeForm(): void {
        this.formGroup = this.filterFormService.getForm();
    }

    setForm(): void {
        this.filterFormService.setForm(this.formGroup, this.filters).subscribe(() => {
            setTimeout(() => {
                this.onSelectedOptionsChange();
            });
        });
    }

    onSelectedOptionsChange = (reset = false) => {
        this.filters = this.filterFormService.from(this.formGroup, this.filters);
        this.updateFilterUrl(reset);
        if (this.emitChangesEnabled) {
            this.filtersChanged.emit(this.filters);
        }
    }

    updateFilterUrl(reset = false): void {
        if (!reset) {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: this.filters.toUrl(),
                queryParamsHandling: 'merge',
            });
        } else {
            const url = this.router.url.split('?')[0];
            this.router.navigateByUrl(url);
        }
    }

    clearFilters(): void {
        this.formGroup.reset();
        this.onSelectedOptionsChange();
    }

}
