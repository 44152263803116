import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    protected getApiRoot = () => `${environment.apiRoot}files/signed_post_url`

    constructor(protected http: HttpClient) { }

    uploadFile(file: File): Observable<string> {
        return this.http.get(this.getApiRoot(), { params: { format: 'json', file_name: file.name }}).pipe(
            mergeMap((response: S3Data) => {
                return this.uploadFileToS3(file, response);
            })
        );
    }

    uploadFileToS3(file: File, s3Data: S3Data): Observable<string> {
        const postData = new FormData();
        for (const key in s3Data.fields) {
            postData.append(key, s3Data.fields[key]);
        }
        postData.append('file', file);
        return this.http.post(s3Data.url, postData).pipe(
            map(() => {
                return s3Data.file_url;
            })
        );
    }

}

class S3Data {
    url: string;
    file_url: string;
    fields: Object;
}
