import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'app-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
	config: any;
	minDate: Date;
	maxDate: Date;
	onDatepickerOpenSubscription = new Subscription();

	@ViewChild(BsDatepickerDirective, { static: false })
	datepicker?: BsDatepickerDirective;

	@Input() customFarIcon = 'calendar-alt';
	@Input() customFormat: string;
	@Input() controlName: string;
	@Input() isMonthMin = false;
	@Input() minMode: string;
	@Input() placeholder = 'Select a date...';
	@Input() tabIndex = 0;
	@Input() form: FormGroup;

	@Output() dateChanged = new EventEmitter();

	constructor(private controlContainer: ControlContainer) {}

	get formControls(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	get date(): any {
		return this.form.get(this.controlName).value;
	}

	ngOnInit(): void {
		this.config = {
			adaptivePosition: true,
			dateInputFormat:
				this.minMode === 'month' ? 'MMMM YYYY' : 'DD MMM YYYY',
			containerClass: 'theme-default',
		};
		this.form = this.controlContainer.control as FormGroup;
		this.form
			.get(this.controlName)
			.valueChanges.subscribe((value: Date) => {
				if (this.customFormat) {
					const formattedValue = new Date(
						moment(value).format(this.customFormat)
					);
					this.form
						.get(this.controlName)
						.setValue(formattedValue, { emitEvent: false });
					this.dateChanged.emit(formattedValue);
				} else {
					this.dateChanged.emit(value);
				}
			});
	}

	ngOnDestroy(): void {
		this.onDatepickerOpenSubscription.unsubscribe();
	}

	clear = () => this.form.get(this.controlName).setValue(null);
}
