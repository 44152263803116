// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --dev` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiRoot: 'https://service-dev.viitata.app/api/v1/',
    pageSize: 50,
    // TODO: Restrict access to API key when ready for production mode
    google_maps: {
        url: 'https://www.google.com/maps/embed/v1/place?q={QUERY}&key={APIKEY}&maptype={MAPTYPE}',
        apiKey: 'AIzaSyCCEI15XlF0SZGWmpc0sNyLAIVPKtE-b8g',
    },
    authentication: {
        config: {
            endSessionReturnUrl: '/authentication/login',
            maxInactivePeriodInSeconds: 7200,
            unAuthReturnRouteUrl: '/authentication/login',
            warningPeriodInSeconds: 60,
            noAuthUrls: ['/apps/apps/', '/styles/styles/', '/variables/variables/', 's3.amazonaws.com'],
            isUsingDjangoDefender: true
        }
    },
    configuration: {
        uid: null,
        showGPInfo: true,
        showNhsNumber: true,
        adminOrganisationAbbreviation: null,
        adminOrganisationName: null,
        logo: 'assets/img/logo.svg',
        availableThemes: [
            '#F78DA7',
            '#CF2E2E',
            '#FF6900',
            '#FCB900',
            '#8ABF2D',
            '#1DD3B0',
            '#8ED1FC',
            '#0693E3',
            '#9B51E0',
            '#9E3283'
        ],
        theme: null,
        updateWithinOverdueLimit: 3,
        closeWithinOverdueLimit: 5,
        adminSupportEmail: null,
        privacyPolicyLink: null,
        googleTagManagerId: null,
        tawkWebchatCode: null,
        terminology: {
            client: 'Client',
            clientPlural: 'Clients',
            case: 'Case',
            casePlural: 'Cases',
            referral: 'Referral',
            referralPlural: 'Referrals',
            issue: 'Issue',
            issuePlural: 'Issues',
            organisation: 'Organisation',
            organisationPlural: 'Organisations',
            questionnaire: 'Questionnaire',
            questionnairePlural: 'Questionnaires',
        },
    },
    links: {
        auth: {
            login: '/authentication/login',
            signUp: '/authentication/sign-up',
            changePassword: '/authentication/change-password',
            forgotPassword: '/authentication/forgot-password',
        },
        dashboard: '/home',
        announcements: '/announcements',
        cases: '/c',
        case: '/c/details/',
        clients: '/p',
        client: '/p/details/',
        referrals: '/r',
        referral: '/r/details/',
        createReferral: '/r/create',
        organisations: '/o',
        organisation: '/o/details/',
        createOrganisation: '/o/create',
        users: '/settings/users',
        messages: '/messages',
        settings: '/settings',
        pageNotFound: '/page-not-found'
    },
    mobileScreenSize: 768
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
