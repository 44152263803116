import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'app-base-open-select',
    templateUrl: './base-open-select.component.html',
    styleUrls: ['./base-open-select.component.scss']
})
export class BaseOpenSelectComponent<T> implements OnInit {

    @Output() clickEvent = new EventEmitter<T>();

    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() disabledUids: string[];

    defaultTheme = 'secondary';
    activeTheme = 'default';
    isDataObject = true;
    filter;
    data: T[] = [];

    constructor() { }

    get formControl(): AbstractControl {
        return this.formGroup?.get(this.formControlKey);
    }

    get activeUids(): string[] {
        return this.formControl?.value ? this.formControl.value.map(item => item.uid) : [];
    }

    ngOnInit(): void {
        this.initialize();
        this.getData(1, this.filter);
    }

    toggleTag(data: any): void {
        const index = this.activeUids.indexOf(this.isDataObject ? data.uid : data);
        if (index >= 0) {
            const tempFormControl = cloneDeep(this.formControl);
            tempFormControl.value.splice(tempFormControl.value.findIndex(
                item => (this.isDataObject ? item.uid : item) === (this.isDataObject ? data.uid : data)
            ), 1);
            this.formControl.setValue(tempFormControl.value);
        } else {
            this.formControl.setValue([...this.formControl.value, data]);
        }
        this.formGroup.markAsDirty();
        this.clickEvent.emit(data);
    }

    initialize(): void {}

    getData(page: number, filter?): void {}

}
