import { Injectable } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

    private openSubject = new ReplaySubject<boolean>();
    private openSubject$ = this.openSubject.asObservable();

    modalRefs: BsModalRef[] = [];

    constructor(private toastService: ToastService) { }

    create(
        bsModalService: BsModalService,
        showCallBack: (options: any) => BsModalRef,
        size?: 'xl' | 'lg' | 'md' | 'sm',
        initialState = null,
        normalUse = true,
        customClass = ''
    ): void {

        const options = {
            class: `modal-dialog-centered modal-${size ? size : 'lg'} ${customClass}`,
            keyboard: normalUse,
            backdrop: normalUse ? true : 'static',
            initialState
        };

        // Force max of 1 open modal at any time
        this.closeAll();

        this.modalRefs.push(showCallBack(options));
        this.openSubject.next(true);

        const onHideSubscription = bsModalService.onHide.subscribe(() => {
            this.close();
            onHideSubscription.unsubscribe();
        });

        const onHiddenSubscription = bsModalService.onHidden.pipe(mergeMap(() => this.toastService.hasQueue())).subscribe(() => {
            this.toastService.showQueued();
            onHiddenSubscription.unsubscribe();
        });
    }

    close(modalRef?: BsModalRef): void {
        if (modalRef) {
            modalRef.hide();
        }
        this.modalRefs.pop();
        this.openSubject.next(false);
    }

    closeAll(): void {
        this.modalRefs.forEach(modal => this.close(modal));
        this.openSubject.next(false);
    }

    hasOpen(): boolean {
        return this.modalRefs.length > 0;
    }

    getOpen(): Observable<boolean> {
        return this.openSubject$;
    }
}
