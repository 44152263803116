import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityFeed } from '@app/shared/models/activity-feed.model';
import { BaseReadOnlyService } from './base-read-only.service';
import { ActivityFeedFilters } from '@app/shared/models/filters/activityFeed.model';


@Injectable({
    providedIn: 'root'
})
export class ActivityFeedService extends BaseReadOnlyService<ActivityFeed, ActivityFeedFilters> {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'activityfeed';
        this.exampleObject = new ActivityFeed();
    }

}
