import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoteFilters } from '@app/shared/models/filters/noteFilters.model';
import { BaseReadWriteService } from '../base-read-write.service';
import { ReferralNote } from '@app/shared/models/notes/referral-note.model';

@Injectable({
    providedIn: 'root'
})
export class ReferralNotesService extends BaseReadWriteService<ReferralNote, NoteFilters>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'referralnotes';
        this.exampleObject = new ReferralNote();
    }

}
