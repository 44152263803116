import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-card',
    templateUrl: './form-card.component.html',
    styleUrls: ['./form-card.component.scss']
})
export class FormCardComponent {

    @Input() mainForm = false;
    @Input() title: string;
    @Input() step: number;
    @Input() customPadding: string;

    constructor() { }

}
