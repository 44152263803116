<div class="row no-gutters gx5 gy5 justify-content-start mt5-md-down mb5">
    <div class="col-auto" *ngFor="let navItem of navItems">
        <app-icon
            [faIcon]="navItem.icon"
            [hasClickEvent]="true"
            [tooltipText]="navItem.title"
            placement="bottom"
            [theme]="navItem.active ? 'tertiary' : 'white'"
            (clickEvent)="navItem.onClick()">
        </app-icon>
    </div>
</div>
