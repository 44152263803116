import { Component, OnInit } from '@angular/core';
import { Client } from '@app/shared/models/client.model';
import { ClientService } from '@app/core/services/client.service';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { ClientFilters } from '@app/shared/models/filters/clientFilters.model';

@Component({
    selector: 'app-client-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class ClientSelectComponent extends SelectBaseComponent<Client> implements OnInit {

    constructor(
        private clientService: ClientService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.clientService;
        this.getObjectCustomField = 'getCompactClients';
        this.filter = new ClientFilters();
    }

}
