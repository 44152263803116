import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {

    @Output() selectedOptionsChange = new EventEmitter();

    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() switchLabel: string;
    @Input() isBold = false;
    @Input() isTitle = false;
    @Input() noWrap = false;
    @Input() hasLabelLeft = false;
    @Input() textSize: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

    // will render a the switch if a formGroup is not necessary
    @Input() isSelected = false;

    // will disable the switch if user is not admin true = buttons disabled.
    @Input() readOnly = false;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                switch: [this.isSelected]
            });
            this.formControlKey = 'switch';
        }
    }

    toggle(): void {
        if (this.formGroup) {
            const formControl = this.formGroup.controls[this.formControlKey];
            formControl.setValue(!formControl.value);
            formControl.markAsDirty();
        }
        this.selectedOptionsChange.emit();
    }

}
