import { MetadataOption } from 'django-rest-core';

export class MetadataEnum extends MetadataOption {
    uid: string;
    value: string;

    constructor(value: string, title: string, pk?: number, url?: string) {
        super(pk, title);
        super.url = url;
        this.value = value;
    }
}
