import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ReferralSummary } from '@app/shared/models/referral-summary.model';
import { Referral } from '@app/shared/models/referral.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-referrals-accordion-group',
    templateUrl: './referrals-accordion-group.component.html',
    styleUrls: ['./referrals-accordion-group.component.scss']
})
export class ReferralsAccordionGroupComponent {

    readonly referralLink = environment.links.referral;

    @Input() title: string;
    @Input() theme: 'primary' | 'tertiary' | 'white' | 'red' = 'white';
    @Input() referrals: Referral[];
    @Input() referralSummaries: ReferralSummary[];
    @Input() totalReferrals: number;
    @Input() isOpen = false;
    @Input() collapsedPermanently = false;
    @Input() customTopPadding: string;

    @Output() viewReferralsClicked = new EventEmitter();

    constructor(
        private router: Router
    ) { }

    get backgroundClass(): string {
        return this.theme + '-background-colour';
    }

    get fontClass(): string {
        if (this.theme === 'white'){
            return 'tertiary-colour';
        } else {
            return this.theme + '-colour';
        }
    }

    viewReferrals(): void {
        if (this.collapsedPermanently) {
            this.viewReferralsClicked.emit();
        }
    }

    getReferralsCount(): number {
        if (this.totalReferrals || this.totalReferrals === 0) {
            return this.totalReferrals;
        } else {
            if (this.referrals?.length || this.referrals?.length === 0) {
                return this.referrals?.length;
            } else {
                return this.referralSummaries?.length;
            }
        }
    }

    routeToReferral(referralUid: String) {
        if (this.router.url.includes(this.referralLink)) {
            this.router.navigate([this.referralLink, referralUid]).then(() => {
                window.location.reload();
            });
        }
    }

}
