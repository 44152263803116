import { Component } from '@angular/core';
import { IconComponent } from '../icon/icon.component';


@Component({
    selector: 'app-filter-icon',
    templateUrl: '../icon/icon.component.html',
    styleUrls: ['../icon/icon.component.scss', './filter-icon.component.scss']
})
export class FilterIconComponent extends IconComponent {

    tooltipText = 'Filter';

    constructor() {
        super();
        this.faIcon = 'sliders-h';
        this.theme = 'primary';
        this.hasClickEvent = true;
    }

}
