import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from 'django-rest-core';
import { LocalAuthority } from '@app/shared/models/localAuthority.model';
import { BaseReadWriteService } from './base-read-write.service';

@Injectable({
    providedIn: 'root'
})
export class LocalAuthorityService extends BaseReadWriteService<LocalAuthority, IFilter>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'localauthorities';
        this.exampleObject = new LocalAuthority();
    }

}
