import { Expose, Transform, Type } from 'class-transformer';
import { ResourceLink } from 'django-rest-core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Case } from './case.model';
import { Issue } from './issue.model';
import { ReferralNote } from './notes/referral-note.model';
import { Organisation } from './organisation.model';
import { ReferralClosureReason } from './referralClosureReason.model';
import { User } from './user.model';
import { ReferralStatus } from './referralStatus.model';
import { BaseModel } from './base-model';
import { Tag } from './tag.model';

export class Referral extends BaseModel {

    @Expose({name: 'referral_number'})
    referralNumber: number;

    @Type(() => Case)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    case: Case;

    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => {
        if (typeof params.value === 'object' && params.value !== null) {
            return new ResourceLink<Issue>(params.value.url, params.value);
        }
        return params.value ? new ResourceLink<Issue>(params.value) : null;
    }, { toClassOnly: true })
    issue: ResourceLink<Issue>;

    @Expose({name: 'current_status'})
    @Type(() => ReferralStatus)
    currentStatus: ReferralStatus;

    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => {
        if (typeof params.value === 'object' && params.value !== null) {
            return new ResourceLink<Organisation>(params.value.url, params.value);
        }
        return params.value ? new ResourceLink<Organisation>(params.value) : null;
    }, { toClassOnly: true })
    organisation: ResourceLink<Organisation>;

    @Expose({name: 'closure_reason'})
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<ReferralClosureReason>(params.value) : null, { toClassOnly: true })
    closureReason: ResourceLink<ReferralClosureReason>;

    @Expose({name: 'closure_note'})
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<ReferralNote>(params.value) : null, { toClassOnly: true })
    closureNote: ResourceLink<ReferralNote>;

    @Expose({name: 'sensitive_information'})
    sensitiveInformation: string;

    @Expose({name: 'is_urgent'})
    isUrgent: boolean;

    @Expose({name: 'has_sensitive_information'})
    hasSensitiveInformation: boolean;

    @Expose({name: 'referral_statuses'})
    @Type(() => ReferralStatus)
    referralStatuses: ReferralStatus[];

    // TODO: /referrals provides a string[], should it not provide a Tag[]?
    // @Type(() => Tag)
    tags: string[];

    @Expose({name: 'created_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DDTHH:mmZ') : null, { toPlainOnly: true })
    createdAt: Moment;

    @Expose({name: 'created_by'})
    @Type(() => User)
    createdBy: User;

    @Expose({name: 'is_overdue'})
    isOverdue: boolean;

    @Expose({name: 'is_close_overdue'})
    isCloseOverdue: boolean;

    @Expose({name: 'is_update_overdue'})
    isUpdateOverdue: boolean;

    // TODO: Why is this part of the referral object returned from /referrals? Seems to be the same as organisation.name
    title: string;

}
