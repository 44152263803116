import { Expose, Transform } from 'class-transformer';
import { BaseModel } from 'django-rest-core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { User } from '../user.model';

export class Note extends BaseModel {

    uid: string;
    content: string;

    @Expose({name: 'created_at'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DDTHH:mmZ') : null, { toPlainOnly: true })
    createdAt: Moment;

    @Expose({name: 'created_by'})
    createdBy: User;

}
