import { Expose, Transform } from 'class-transformer';
import { ResourceLink } from 'django-rest-core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Case } from './case.model';

export class CaseConsent {

    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<Case>(params.value) : null, { toClassOnly: true })
    case: ResourceLink<Case>;

    @Expose({name: 'consent_date'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DD') : null, { toPlainOnly: true })
    consentDate: Moment;

    @Expose({name: 'has_client_consent'})
    hasClientConsent: boolean;

    consentee: string;

}
