import { Component, OnInit } from '@angular/core';
import { Toast } from '@app/shared/models/toast/toast.model';
import { ToastService } from '@app/core/services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {

    toasts: Toast[] = [];
    addEvent: any;
    closeAllEvent: any;

    constructor(private toastService: ToastService) { }

    close(toast: Toast): void {
        toast.show = true;
        setTimeout(() => {
            this.toasts.splice(this.toasts.indexOf(toast), 1);
        }, 1000);
    }

    ngOnInit() {
        this.addEvent = this.toastService.getAdd().subscribe(toast => {
            this.add(toast);
        });

        this.closeAllEvent = this.toastService.getCloseAll().subscribe(() => {
            this.closeAll();
        });
    }

    add(toast: Toast) {
        this.toasts.push(toast);
        if (toast.persistent !== true) {
            setTimeout(() => {
                this.close(toast);
            }, toast.autoClose || 2000);
        }
    }

    closeAll() {
        this.toasts.forEach(toast => {
            this.close(toast);
        });
    }
}
