import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-add-placeholder',
    templateUrl: './add-placeholder.component.html',
    styleUrls: ['./add-placeholder.component.scss']
})
export class AddPlaceholderComponent {

    @Input() text: string;
    @Input() hideText = false;
    @Input() showIconOutline = false;
    @Input() faIcon = 'plus';
    @Input() faType: 'light' | 'regular' | 'solid' = 'light';
    @Input() required = false;
    @Input() tabIndex = 0;

    @Output() clickEvent = new EventEmitter();
    @Output() focusEvent = new EventEmitter();

    constructor() { }

    get type(): string {
        return this.faType === 'light' ? 'fal' : (this.faType === 'solid' ? 'fas' : 'far');
    }

    clicked = () => this.clickEvent.emit();

    onFocus = () => this.focusEvent.emit();
}
