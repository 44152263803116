import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {

    @Input() faIcon: string;
    @Input() faType: 'light' | 'regular' | 'solid' = 'light';
    @Input() faSize: 'sm' | 'md' | 'lg' | 'xl' = 'lg';
    @Input() size: 'xl' | 'lg' | 'md' | 'sm' = 'xl';
    @Input() theme: 'primary' | 'light' | 'gray' | 'green' | 'blue' | 'clear' = 'primary';
    @Input() alignment: 'start' | 'end' | 'center' = 'center';
    @Input() placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'auto';
    @Input() active = false;
    @Input() hasClickEvent = false;
    @Input() disabled = false;
    @Input() isCircle = false;
    @Input() tooltipText: string;
    @Input() text: string;
    @Input() ngxContainer = '#page-container';

    @Output() clickEvent = new EventEmitter<MouseEvent>();

    get type(): string {
        return this.faType === 'light' ? 'fal' : this.faType === 'regular' ? 'far' : 'fas';
    }

    constructor() {}

    clicked(e: MouseEvent): void {
        if (this.hasClickEvent && !this.disabled) {
            this.clickEvent.emit(e);
        }
    }

}
