import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {

    today: Date;
    bsConfig?: Partial<BsDatepickerConfig>;

    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() placeholder = 'DD/MM/YYYY';
    @Input() minDate: Date;
    @Input() maxDate: Date;

    constructor() {
        this.maxDate = moment().toDate();
    }

    ngOnInit(): void {
        this.bsConfig = {
            adaptivePosition: true,
            isAnimated: true,
            containerClass: 'theme-default',
            dateInputFormat: 'DD/MM/YYYY',
            minDate: this.minDate,
            maxDate: this.maxDate
        };
    }
}
