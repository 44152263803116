<div class="border-bottom xs-text pb5">
    <div class="font-weight-bold mb2">CREATED BY</div>
    <div class="cursor-pointer underline-hover xs-label tertiary"(click)="goToOrganisation()">{{case?.organisationSent.name}}</div>
</div>
<div class="pb3 pt3">
    <accordion *ngIf="summaries.length > 0" [isAnimated]="true">
        <app-referrals-accordion-group *ngFor="let summary of summaries; index as i" [title]="summary.status.name"
            [referralSummaries]="summary.referrals" [theme]="getTheme(summary.status, i)">
        </app-referrals-accordion-group>
    </accordion>
    <div *ngIf="summaries.length === 0 && loaded" class="d-flex align-items-center justify-content-center">
        <span class="h1 header-xs p5">
            No {{ referralsTerm }}
        </span>
    </div>
    <div *ngIf="!loaded" class="spinner-container">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0)" [name]="spinnerName" type="ball-spin-clockwise" color="#000" [fullScreen]="false" size="small"></ngx-spinner>
    </div>
</div>