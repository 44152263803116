import { Expose, Type } from 'class-transformer';
import { BaseModel, ResourceLink } from 'django-rest-core';
import { Case } from './case.model';
import { QuestionnaireQuestion } from './questionnaireQuestion.model';
import { ReferralQuestionnaireNote } from './notes/referralQuestionnaireNote.model';

export class ReferralQuestionnaire extends BaseModel {
    
    @Expose({name: 'boolean_response'})
    booleanResponse: boolean;

    case: ResourceLink<Case>;

    @Expose({name: 'questionnaire_question'})
    @Type(() => QuestionnaireQuestion)
    questionnaireQuestion: QuestionnaireQuestion;

    @Expose({name: 'referral_questionnaire_notes'})
    @Type(() => ReferralQuestionnaireNote)
    referralQuestionnaireNotes: ReferralQuestionnaireNote[];

    response: string;

    uid: string;

}
