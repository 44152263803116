<!-- TODO: Use this component as a base for other section-specific cards -->
<div class="card-header d-flex justify-content-between align-items-center {{ theme }}">
    <h5 class="show-ellipsis mb0 ml3">{{ header }}</h5>
   	<div class="d-flex align-items-center">
   		<ng-content select="[icons]"></ng-content>
    </div>
</div>
<div class="card-body h-100">
    <ng-content select="[body]"></ng-content>
</div>
