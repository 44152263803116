import { Expose } from 'class-transformer';
import { BaseModel } from 'django-rest-core';

export class StaffRole extends BaseModel {

    uid: string;
    name: string;

    @Expose({name: 'staff_title'})
    staffTitle: string;

    @Expose({name: 'is_lead'})
    isLead: boolean;

    @Expose({name: 'is_manager'})
    isManager: boolean;

}
