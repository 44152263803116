import { Expose } from 'class-transformer';
import { BaseModel } from 'django-rest-core';

export class Address extends BaseModel {

    uid: string;
    county: string;
    postcode: string;

    @Expose({name: 'address_1'})
    firstLine: string;

    @Expose({name: 'address_2'})
    secondLine: string;

    @Expose({name: 'town_city'})
    town: string;

    toFormattedAddress(customFormat: string[] = null, country: string = null): string {
        if (!customFormat) {
            customFormat = [
                'firstLine',
                'secondLine',
                'town',
                'county',
                'postcode'
            ];
        }
        const addressParts: string[] = [];
        for (const field of customFormat) {
            if (this[field]) {
                addressParts.push(this[field]);
            }
        }

        if (country) {
            addressParts.push(country);
        }

        return addressParts.length > 0 ? addressParts.join(', ') : '';
    }

}
