import { IFilter } from 'django-rest-core';

export class ActivityFeedFilters implements IFilter {

    name: string;
    caseId: string;
    clientId: string;
    referralId: string;

    constructor() { }

    public getSearchCriteriaString(): string {
        let search = '';
        if (this.name) {
            search += '&search=' + encodeURIComponent(this.name);
        }
        if (this.caseId) {
            search += '&case_id=' + this.caseId;
        }
        if (this.clientId) {
            search += '&client_id=' + this.clientId;
        }
        if (this.referralId) {
            search += '&referral_id=' + this.referralId;
        }

        return search;
    }

}
