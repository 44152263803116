<div class="d-flex align-items-center gx5">
    <app-icon
        (clickEvent)="onMessageClick()"
        faIcon="envelope"
        faType="light"
        tooltipText="Messages"
        theme="light"
        faSize="xl"
        [hasClickEvent]="true">
    </app-icon>
    <!-- TODO: Implement Userflow when this button is clicked -->
    <app-icon 
        faIcon="circle-question"
        faType="light"
        tooltipText="Userflow"
        theme="primary"
        faSize="xl">
    </app-icon>
    <app-icon
        faIcon="arrow-right-from-bracket"
        faType="light"
        [hasClickEvent]="true"
        tooltipText="Logout"
        theme="light"
        (clickEvent)="logout()">
    </app-icon>
</div>
