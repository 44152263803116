import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

    @Input() buttonStyle: string;
    @Input() faIcon: string;
    @Input() text: string;

    @Output() click = new EventEmitter();

    constructor() { }

    onClick(): void {
        this.click.emit();
    }

}
