import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressService } from '@app/core/services/address.service';
import { ClientService } from '@app/core/services/client.service';
import { AddressFormService } from '@app/core/services/forms/address-form.service';
import { PostcodeSearchService } from '@app/core/services/postcode-search.service';
import { ToastService } from '@app/core/services/toast.service';
import { Address } from '@app/shared/models/address.model';
import { Client } from '@app/shared/models/client.model';
import { Postcode } from '@app/shared/models/postcode.model';
import { plainToClass } from 'class-transformer';
import { Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseFormComponent } from '../forms/base-form/base-form.component';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent extends BaseFormComponent implements OnDestroy {

    @Input() client: Client;
    @Input() showAddressFields = false;
    @Input() hasFixedAddressCheckbox = false;
    @Input() hasCustomSave = false;
    @Input() customPadding: string;

    @Output() saveSuccessful = new EventEmitter<Client>();
    @Output() backClicked = new EventEmitter();

    postcodesArray: Postcode[];
    postcodesFound = false;
    subscription = new Subscription();
    fixedAddressSubscription: Subscription;

    constructor(
        private addressService: AddressService,
        private addressFormService: AddressFormService,
        private clientService: ClientService,
        protected toastService: ToastService,
        private postcodeSearch: PostcodeSearchService
    ) {
        super(toastService);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    initialize(): void {
        if (this.fixedAddressSubscription) {
            this.subscription.remove(this.fixedAddressSubscription);
        }
        if (!this.formGroup) {
            this.formGroup = this.addressFormService.getClientAddressForm(this.client);
        }
        if (this.getFormGroup('firstLine').value) {
            this.showAddressFields = true;
        }
        if (this.getFormGroup('noFixedAbode')) {
            this.fixedAddressSubscription = this.addressFormService.getFixedAddressOnChange(
                this.formGroup
            );
            this.subscription.add(this.fixedAddressSubscription);
        }
    }

    getFormGroup(formControlKey: string): FormGroup {
        return this.formGroup?.get(formControlKey) as FormGroup;
    }

    manualAddressEnter(): void {
        this.showAddressFields = true;
    }

    clearAddress(): void {
        this.showAddressFields = false;
        this.postcodesFound = false;
        this.formGroup.reset();
        this.formGroup.markAsPristine();
    }

    findAddress(postcode: string): void {
        this.showAddressFields = false;
        this.postcodeSearch.getPostcodes(postcode).subscribe((response) => {
            this.postcodesArray = response;
            this.postcodesFound = true;
        });
    }

    selectAddress = (value: Postcode) => {
        this.showAddressFields = true;
        this.formGroup.get('firstLine').setValue(value.house);
        this.formGroup.get('secondLine').setValue(value?.street ?? null);
        this.formGroup.get('town').setValue(value?.city ?? null);
        this.formGroup.get('county').setValue(value?.county ?? null);
    }

    goBack(): void {
        this.backClicked.emit();
    }

    save(): void {
        this.waitingOnRequests = true;
        const address = this.addressFormService.from(this.formGroup);
        const isAddressNew = !address.uid;
        if (isAddressNew || (this.client.noFixedAbode !== this.formGroup.get('noFixedAbode').value)) {
            this.client.noFixedAbode = this.formGroup.get('noFixedAbode').value;

            const isObjectNew = !this.client.uid;
            let source = of(null);
            const getObjectSource = (newAddress): Observable<any> => {
                if (newAddress) {
                    address.uid = newAddress.uid;
                    address.url = newAddress.url;
                    this.client.address = plainToClass(Address, newAddress as object);
                } else {
                    this.client.address = null;
                }

                let objectSource = null;
                if (isObjectNew) {
                    objectSource = this.clientService.add(this.client);
                } else {
                    objectSource = this.clientService.partialUpdate(this.client.uid as any, this.client);
                }

                return objectSource;
            };

            if (!this.client.noFixedAbode) {
                let addressSource = of(null);
                if (!address?.uid) {
                    addressSource = this.addressService.add(address);
                } else {
                    addressSource = this.addressService.partialUpdate(address.uid as any, address);
                }
                source = addressSource.pipe(
                    mergeMap((newAddress: Address) => {
                        newAddress = plainToClass(Address, newAddress as object);
                        return getObjectSource(newAddress);
                    })
                );
            } else {
                const localAuth = this.formGroup.get('localAuthority').value;
                this.client.localAuthority = localAuth;
                source = getObjectSource(null);
            }

            source.subscribe((response) => {
                this.toastService.add(
                    { title: 'Success', message: `Address ${isObjectNew ? 'added' : 'updated' } successfully.`, type: 'success' }
                );
                this.formGroup.markAsPristine();
                this.clientService.emitUpdated();
                this.waitingOnRequests = false;
                this.saveSuccessful.emit(this.client);
            }, (err) => {
                this.toastService.add(
                    { title: 'Error', message: `Address ${isObjectNew ? 'add' : 'update' } failed.`, type: 'error' }
                );
                this.waitingOnRequests = false;
            });
        } else {
            this.updateAddress();
        }
    }

    updateAddress(): void {
        this.waitingOnRequests = true;
        const address = this.addressFormService.from(this.formGroup);
        const localAuth = this.formGroup.get('localAuthority').value;
        const isObjectNew = !address.uid;
        this.addressService.partialUpdate(address.uid as any, address).pipe(
            mergeMap(()=>{
                if (localAuth != this.client.localAuthority){
                    this.client.localAuthority = localAuth;
                    return this.clientService.partialUpdate(this.client.uid as any, this.client)
                } else {
                    return of(null);
                }
            })
        ).subscribe(() => {
                    this.toastService.add(
                        { title: 'Success', message: `Address ${isObjectNew ? 'added' : 'updated' } successfully.`, type: 'success' }
                    );
                    this.formGroup.markAsPristine();
                    this.addressService.emitUpdated();
                    this.waitingOnRequests = false;
                    this.saveSuccessful.emit(this.client);
                }, (err) => {
                    this.toastService.add(
                        { title: 'Error', message: `Address ${isObjectNew ? 'add' : 'update' } failed.`, type: 'error' }
                    );
                    this.waitingOnRequests = false;
                }
        )
    }

    
}
