import { Component, OnInit } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { UserService } from '@app/core/services/user.service';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-user-select',
    templateUrl: '../select-base/select-base.component.html',
})
export class UserSelectComponent extends SelectBaseComponent<User> implements OnInit {

    constructor(
        private userService: UserService
    ) {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    setVariables(): void {
        this.objectService = this.userService;
    }

}
