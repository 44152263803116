import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-circle-label',
    templateUrl: './circle-label.component.html',
    styleUrls: ['./circle-label.component.scss']
})
export class CircleLabelComponent {

    @Input() label: string;
    @Input() textLeftSide = true;
    @Input() circleTheme: 'primary' | 'danger' = 'primary';
    @Input() circleSize: 'sm' | 'md' = 'sm';
    @Input() isBold = false;

    constructor() { }

}
