import { Expose } from 'class-transformer';
import { BaseModel } from 'django-rest-core';

export class Configuration extends BaseModel {

    uid: string;
    logo: string;
    theme: string;

    @Expose({name: 'admin_organisation_name'})
    adminOrganisationName: string;

    @Expose({name: 'admin_organisation_abbreviation'})
    adminOrganisationAbbreviation: string;

    @Expose({name: 'admin_support_email'})
    adminSupportEmail: string;

    @Expose({name: 'privacy_policy_link'})
    privacyPolicyLink: string;

    @Expose({name: 'show_gp_info'})
    showGPInfo: boolean;

    @Expose({name: 'show_nhs_number'})
    showNhsNumber: boolean;

    @Expose({name: 'client_terminology'})
    clientTerminology: string;

    @Expose({name: 'client_terminology_plural'})
    clientTerminologyPlural: string;

    @Expose({name: 'case_terminology'})
    caseTerminology: string;

    @Expose({name: 'case_terminology_plural'})
    caseTerminologyPlural: string;

    @Expose({name: 'referral_terminology'})
    referralTerminology: string;

    @Expose({name: 'referral_terminology_plural'})
    referralTerminologyPlural: string;

    @Expose({name: 'issue_terminology'})
    issueTerminology: string;

    @Expose({name: 'issue_terminology_plural'})
    issueTerminologyPlural: string;

    @Expose({name: 'organisation_terminology'})
    organisationTerminology: string;

    @Expose({name: 'organisation_terminology_plural'})
    organisationTerminologyPlural: string;

    @Expose({name: 'questionnaire_terminology'})
    questionnaireTerminology: string;

    @Expose({name: 'questionnaire_terminology_plural'})
    questionnaireTerminologyPlural: string;

    @Expose({name: 'update_within_overdue_limit'})
    updateWithinOverdueLimit: number;

    @Expose({name: 'close_within_overdue_limit'})
    closeWithinOverdueLimit: number;

    @Expose({name: 'logout_timer_period'})
    logoutTimerPeriod: number;

    @Expose({name: 'user_account_deactivation_period'})
    userAccountDeactivationPeriod: number;

    @Expose({name: 'data_redaction_period'})
    dataRedactionPeriod: number;

    @Expose({name: 'consent_expiration_period'})
    consentExpirationPeriod: number;

    @Expose({name: 'google_tag_manager_id'})
    googleTagManagerId: string;

    @Expose({name: 'tawk_webchat_code'})
    tawkWebchatCode: string;
}