import { Expose, Type } from 'class-transformer';
import { ReferralSummary } from './referral-summary.model';
import { Status } from './status.model';

export class CaseSummary {

    @Type(() => OpenReferrals)
    O: OpenReferrals[];

    @Type(() => ClosedReferrals)
    C: ClosedReferrals;

}

export class Closure {
    referrals: ReferralSummary[];
    count: number;
}

export class OpenReferrals extends Closure {
    @Type(() => Status)
    status: Status;
}

class ClosedReferrals {

    @Expose({name: 'positive_closures'})
    @Type(() => Closure)
    positiveClosures: Closure;

    @Expose({name: 'negative_closures'})
    @Type(() => Closure)
    negativeClosures: Closure;
}


