import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseReadWriteService } from '@app/core/services/base-read-write.service';
import { ToastService } from '@app/core/services/toast.service';

@Component({
    selector: 'app-base-form',
    template: '',
})
export class BaseFormComponent implements OnInit {

    @Output() onSave = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();

    object: any;
    objectType: string;
    objectService: BaseReadWriteService<any, any>;
    formService: any;
    acceptedPatchFields: string[] = null;
    waitingOnRequests = false;
    
    @Input() formGroup: FormGroup;

    get isValid(): boolean {
        if (!this.formGroup) {
            return false;
        }
        return this.formGroup.valid && this.formGroup.dirty;
    }

    constructor(
        protected toastService: ToastService,
    ) { }

    ngOnInit(): void {
        this.initialize();
    }

    initialize(): void {}
    onSuccessfulSave(updatedObject?): void {}
    onSuccessfulDelete(): void {}

    clearForm(): void {
        this.formGroup.reset();
    }

    save(oldObject?: any): void {
        this.waitingOnRequests = true;
        const partialObject = this.formService.from(this.formGroup, oldObject);

        const isObjectNew = !this.object?.uid;
        let source = null;
        if (isObjectNew) {
            source = this.objectService.add(partialObject);
        } else {
            source = this.objectService.partialUpdate(this.object.uid, partialObject);
        }

        source.subscribe((response) => {
            this.onSave.emit(response);
            this.toastService.add(
                { title: 'Success', message: `${this.objectType} ${isObjectNew ? 'added' : 'updated' } successfully.`, type: 'success' }
            );
            this.formGroup.markAsPristine();
            this.objectService.emitUpdated();
            this.waitingOnRequests = false;
            this.onSuccessfulSave(response);
        }, () => {
            this.toastService.add(
                { title: 'Error', message: `${this.objectType} ${isObjectNew ? 'add' : 'update' } failed.`, type: 'error' }
            );
            this.waitingOnRequests = false;
        });
    }

    delete(): void {
        this.waitingOnRequests = true;
        this.objectService.delete(this.object.uid).subscribe(() => {
            this.onDelete.emit(this.object);
            this.toastService.add({ title: 'Success', message: `${this.objectType} removed successfully.`, type: 'success' });
            this.objectService.emitUpdated();
            this.waitingOnRequests = false;
            this.onSuccessfulDelete();
        }, () => {
            this.toastService.add({ title: 'Error', message: `An error occured removing ${this.objectType}.`, type: 'error'});
            this.waitingOnRequests = false;
        });
    }

}
