<div [formGroup]="formGroup">
    <div class="mb5">
        <div class="d-flex justify-content-between align-items-center mb3">
             <!-- TODO: Check appropriate aria info for tooltips - ensure tooltip text can be read by screenreaders -->
            <label class="xs-label mb0">CONSENT</label>
            <i class="fa-solid fa-circle-question primary-colour"
                [tooltip]="consentTooltip"
                placement="right">
            </i>
        </div>
        <app-switch
            (click)="toggleHasClientConsent()"
            [formGroup]="formGroup"
            [formControlKey]="clientConsentControl"
            switchLabel="The {{ clientTerm }} has given their consent.">
        </app-switch>
    </div>
    <div class="mb5" *ngIf="!formGroup.get(clientConsentControl).value">
        <label class="xs-label required-field" for="consentee">CONSENTEE</label>
        <input id="consentee" class="form-control mb5 light-gray-border xs-placeholder" [formControlName]="consenteeControl"
            placeholder="Consentee">
    </div>
    <div>
        <label id="date-of-consent" class="required-field xs-label">CONSENT DATE</label>
        <app-date-picker [formControlKey]="consentDateControl" [formGroup]="formGroup"></app-date-picker>
        <app-error class="mt3" *ngIf="formGroup" [control]="formGroup.get(consentDateControl)"></app-error>
    </div>
</div>