import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-add-new-icon',
    templateUrl: './add-new-icon.component.html',
    styleUrls: ['./add-new-icon.component.scss']
})
export class AddNewIconComponent {

    @Output() onAddNewClicked = new EventEmitter();
    @Output() onShowMoreClicked = new EventEmitter();

    @Input() hasShowMoreBtn = false;

    constructor() { }

    addNew(): void {
        this.onAddNewClicked.emit();
    }

    showMore(): void {
        this.onShowMoreClicked.emit();
    }

}
