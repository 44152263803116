import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthenticationPageLayoutComponent } from './components/layouts/authentication-page-layout/authentication-page-layout.component';
import { ErrorComponent } from './components/error/error.component';
import { DefaultPageLayoutComponent } from './components/layouts/default-page-layout/default-page-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { DropdownComponent } from './components/dropdown/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/dropdown/dropdown-item/dropdown-item.component';
import { ListsPageComponent } from './components/layouts/lists-page/lists-page.component';
import { IconComponent } from './components/icons/icon/icon.component';
import { FilterIconComponent } from './components/icons/filter-icon/filter-icon.component';
import { PlusIconComponent } from './components/icons/plus-icon/plus-icon.component';
import { InsertionDirective } from './directives/insertion.directive';
import { TextInputComponent } from './components/forms/text-input/text-input.component';
import { TextAreaComponent } from './components/forms/text-area/text-area.component';
import { CheckboxInputComponent } from './components/forms/checkbox-input/checkbox-input.component';
import { TagComponent } from './components/tag/tag.component';
import { OrganisationSelectComponent } from './components/select/organisation-select/organisation-select.component';
import { FieldComponent } from './components/field/field/field.component';
import { AddPlaceholderComponent } from './components/field/add-placeholder/add-placeholder.component';
import { LocalAuthoritySelectComponent } from './components/select/local-authority-select/local-authority-select.component';
import { UserSelectComponent } from './components/select/user-select/user-select.component';
import { DateRangePickerComponent } from './components/controls/date-range-picker/date-range-picker.component';
import { ReferralStatusSelectComponent } from './components/select/referral-status-select/referral-status-select.component';
import { ReferralClosureReasonSelectComponent } from './components/select/referral-closure-reason-select/referral-closure-reason-select.component';
import { ReferralIssuesSelectComponent } from './components/select/referral-issues-select/referral-issues-select.component';
import { CardComponent } from './components/cards/card/card.component';
import { ListGroupItemComponent } from './components/list-group-item/list-group-item.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastsComponent } from './components/toasts/toasts.component';
import { StaffSelectComponent } from './components/select/staff-select/staff-select.component';
import { YesNoPipe } from './pipes/yesNo.pipe';
import { TimePeriodSelectComponent } from './components/select/time-period-select/time-period-select.component';
import { OrganisationTypeSelectComponent } from './components/select/organisation-type-select/organisation-type-select.component';
import { UserIconComponent } from './components/icons/user-icon/user-icon.component';
import { GoogleMapsEmbedComponent } from './components/google-maps-embed/google-maps-embed.component';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { FormCardComponent } from './components/forms/form-card/form-card.component';
import { DirectoryPageLayoutComponent } from './components/layouts/directory-page-layout/directory-page-layout.component';
import { DirectoryComponent } from './components/directory/directory/directory.component';
import { AddressPipe } from './pipes/address.pipe';
import { GenderSelectComponent } from './components/select/gender-select/gender-select.component';
import { TagSelectComponent } from './components/select/tag-select/tag-select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { ReferralCaseLayoutComponent } from './components/layouts/referral-case-layout/referral-case-layout.component';
import { DetailsHeaderComponent } from './components/details-header/details-header.component';
import { CasesReferralsStatusOverviewComponent } from './components/cases-referrals-status-overview/cases-referrals-status-overview.component';
import { ReferralsAccordionGroupComponent } from './components/referrals-accordion-group/referrals-accordion-group.component';
import { ActivityFeedComponent } from './components/activity-feed/activity-feed.component';
import { DescriptionBoxComponent } from './components/description-box/description-box.component';
import { CardListComponent } from './components/cards/card-list/card-list.component';
import { CircleLabelComponent } from './components/circle-label/circle-label.component';
import { StepComponent } from './components/step/step.component';
import { TagsComponent } from './components/tags/tags.component';
import { BaseFormComponent } from './components/forms/base-form/base-form.component';
import { ImageInputComponent } from './components/forms/image-input/image-input.component';
import { TagOpenSelectComponent } from './components/select/open-select/tag-open-select/tag-open-select.component';
import { BaseOpenSelectComponent } from './components/select/open-select/base-open-select/base-open-select.component';
import { IssueOpenSelectComponent } from './components/select/open-select/issue-open-select/issue-open-select.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { IssueSelectComponent } from './components/select/issue-select/issue-select.component';
import { ListWithDetailPageComponent } from './components/layouts/list-with-detail-page/list-with-detail-page.component';
import { FilterButtonComponent } from './components/buttons/filter-button/filter-button.component';
import { DatePickerComponent } from './components/controls/date-picker/date-picker.component';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { OrganisationCardComponent } from './components/organisation-card/organisation-card.component';
import { OrganisationBasicInfoComponent } from './components/organisation-basic-info/organisation-basic-info.component';
import { OrganisationCardHeaderComponent } from './components/organisation-card-header/organisation-card-header.component';
import { CardListHeaderComponent } from './components/cards/card-list-header/card-list-header.component';
import { QuestionTypeSelectComponent } from './components/select/question-type-select/question-type-select.component';
import { TableColumnDirective } from './directives/template-name.directive';
import { FilterComponent } from './components/filter/filter.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { OrganisationLogoComponent } from './components/organisation-logo/organisation-logo.component';
import { QuestionIconComponent } from './components/icons/question-icon/question-icon.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { DateOfBirthSelectComponent } from './components/select/date-of-birth-select/date-of-birth-select.component';
import { NgQuillEditorComponent } from './components/quill/ng-quill-editor/ng-quill-editor.component';
import { QuillModule } from 'ngx-quill';
import { NgQuillViewComponent } from './components/quill/ng-quill-view/ng-quill-view.component';
import { StaffRoleSelectComponent } from './components/select/staff-role-select/staff-role-select.component';
import { AlphabetSortListComponent } from './components/alphabet-sort-list/alphabet-sort-list.component';
import { FiltersAccordionComponent } from './components/filters-accordion/filters-accordion.component';
import { MobileTabsComponent } from './components/mobile-tabs/mobile-tabs.component';
import { SessionInactiveComponent } from './components/session-inactive/session-inactive/session-inactive.component';
import { NoteCardComponent } from './components/note-card/note-card.component';
import { FileCardComponent } from './components/file-card/file-card.component';
import { ConsentFormComponent } from './components/forms/consent-form/consent-form.component';
import { ListLogoComponent } from './components/list-logo/list-logo.component';
import { CreateReferralFormComponent } from './components/create-referral-form/create-referral-form.component';
import { AddNoteModalComponent } from './components/add-note-modal/add-note-modal.component';
import { BaseFilterComponent } from './components/filter/base-filter/base-filter.component';
import { ResolutionStatusSelectComponent } from './components/select/resolution-status-select/resolution-status-select.component';
import { ClientBasicInfoFormComponent } from './components/forms/client-basic-info-form/client-basic-info-form.component';
import { ClientContactDetailsFormComponent } from './components/forms/client-contact-details-form/client-contact-details-form.component';
import { CaseSelectComponent } from './components/select/case-select/case-select.component';
import { StaticSelectComponent } from './components/select/static-select/static-select.component';
import { NumberInputComponent } from './components/forms/number-input/number-input.component';
import { AddNewIconComponent } from './components/icons/add-new-icon/add-new-icon.component';
import { BaseFormArrayComponent } from './components/forms/base-form-array/base-form-array.component';
import { StatusesFormComponent } from './components/forms/statuses-form/statuses-form.component';
import { RadioInputComponent } from './components/forms/radio-input/radio-input.component';
import { SaveAndDeleteIconsComponent } from './components/icons/save-and-delete-icons/save-and-delete-icons.component';
import { ReferralClosedReasonsFormComponent } from './components/forms/referral-closed-reasons-form/referral-closed-reasons-form.component';
import { GendersFormComponent } from './components/forms/genders-form/genders-form.component';
import { ConfigFormTemplateComponent } from './components/forms/config-form-template/config-form-template.component';
import { ColourSelectComponent } from './components/select/colour-select/colour-select.component';
import { CaseStatusSelectComponent } from './components/select/case-status-select/case-status-select.component';
import { ClientSelectComponent } from './components/select/client-select/client-select.component';
import { EditClientCardComponent } from './components/edit-client-card/edit-client-card.component';
import { DragulaModule } from 'ng2-dragula';
import { FileInputComponent } from './components/forms/file-input/file-input.component';
import { AddNoteButtonComponent } from './components/buttons/add-note-button/add-note-button.component';
import { IconButtonComponent } from './components/buttons/icon-button/icon-button.component';
import { UserOrgSelectComponent } from './components/navbar/user-org-select/user-org-select.component';
import { NavbarButtonsComponent } from './components/navbar/navbar-buttons/navbar-buttons.component';

@NgModule({
    declarations: [
        ActivityFeedComponent,
        AddPlaceholderComponent,
        AddressFormComponent,
        AddressPipe,
        AddNoteButtonComponent,
        AddNoteModalComponent,
        AddNewIconComponent,
        AlphabetSortListComponent,
        AuthenticationPageLayoutComponent,
        BaseFormComponent,
        BaseFormArrayComponent,
        BaseFilterComponent,
        BaseOpenSelectComponent,
        CasesReferralsStatusOverviewComponent,
        CardComponent,
        CardListComponent,
        CardListHeaderComponent,
        CaseSelectComponent,
        CaseStatusSelectComponent,
        CircleLabelComponent,
        ClientBasicInfoFormComponent,
        ClientContactDetailsFormComponent,
        ClientCardComponent,
        ClientSelectComponent,
        CheckboxInputComponent,
        ColourSelectComponent,
        ConfigFormTemplateComponent,
        ConsentFormComponent,
        CreateReferralFormComponent,
        DateRangePickerComponent,
        DropdownComponent,
        DropdownItemComponent,
        DirectoryComponent,
        DirectoryPageLayoutComponent,
        DirectoryComponent,
        DetailsHeaderComponent,
        DateOfBirthSelectComponent,
        DatePickerComponent,
        DefaultPageLayoutComponent,
        DirectoryComponent,
        DescriptionBoxComponent,
        EditClientCardComponent,
        ErrorComponent,
        FieldComponent,
        FilterComponent,
        FileCardComponent,
        FilterButtonComponent,
        FilterIconComponent,
        FileInputComponent,
        FiltersAccordionComponent,
        FormCardComponent,
        GendersFormComponent,
        GenderSelectComponent,
        GoogleMapsEmbedComponent,
        IconComponent,
        IconButtonComponent,
        ImageInputComponent,
        InsertionDirective,
        IssueSelectComponent,
        IssueOpenSelectComponent,
        ListLogoComponent,
        ListsPageComponent,
        ListGroupItemComponent,
        ListWithDetailPageComponent,
        LocalAuthoritySelectComponent,
        MobileTabsComponent,
        ModalComponent,
        NavbarComponent,
        NavbarButtonsComponent,
        NgQuillEditorComponent,
        NgQuillViewComponent,
        NoteCardComponent,
        NumberInputComponent,
        OrganisationBasicInfoComponent,
        OrganisationCardComponent,
        OrganisationCardHeaderComponent,
        OrganisationSelectComponent,
        OrganisationTypeSelectComponent,
        OrganisationLogoComponent,
        PlusIconComponent,
        ProgressBarComponent,
        QuestionIconComponent,
        QuestionTypeSelectComponent,
        RadioInputComponent,
        ReferralStatusSelectComponent,
        ReferralCaseLayoutComponent,
        ReferralClosureReasonSelectComponent,
        ReferralClosedReasonsFormComponent,
        ReferralIssuesSelectComponent,
        ReferralsAccordionGroupComponent,
        ResolutionStatusSelectComponent,
        SafeUrlPipe,
        SaveAndDeleteIconsComponent,
        SearchBarComponent,
        StaffSelectComponent,
        StaffRoleSelectComponent,
        StaticSelectComponent,
        StatusesFormComponent,
        SessionInactiveComponent,
        SwitchComponent,
        StepComponent,
        SideMenuComponent,
        TableColumnDirective,
        TagsComponent,
        TagOpenSelectComponent,
        TagSelectComponent,
        TextInputComponent,
        TextAreaComponent,
        TagComponent,
        ToastsComponent,
        TimePeriodSelectComponent,
        UserIconComponent,
        UserSelectComponent,
        UserOrgSelectComponent,
        YesNoPipe,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        HttpClientModule,
        NgSelectModule,
        NgxSpinnerModule,
        InfiniteScrollModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AccordionModule.forRoot(),
        CollapseModule.forRoot(),
        QuillModule.forRoot(),
        DragulaModule.forRoot()
    ],
    exports: [
        AccordionModule,
        AddPlaceholderComponent,
        AddressFormComponent,
        AddNewIconComponent,
        AddNoteButtonComponent,
        AddNewIconComponent,
        AddNoteModalComponent,
        AlphabetSortListComponent,
        ActivityFeedComponent,
        AddressPipe,
        BsDropdownModule,
        CardComponent,
        CardListComponent,
        CardListHeaderComponent,
        CaseSelectComponent,
        CaseStatusSelectComponent,
        CasesReferralsStatusOverviewComponent,
        CheckboxInputComponent,
        CircleLabelComponent,
        ClientBasicInfoFormComponent,
        ClientContactDetailsFormComponent,
        ClientCardComponent,
        ClientSelectComponent,        
        ColourSelectComponent,
        CommonModule,
        ConsentFormComponent,
        CreateReferralFormComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DateOfBirthSelectComponent,
        DescriptionBoxComponent,
        DetailsHeaderComponent,
        DirectoryPageLayoutComponent,
        DragulaModule,
        DropdownComponent,
        DropdownItemComponent,
        EditClientCardComponent,
        ErrorComponent,
        GendersFormComponent,
        FileCardComponent,
        FileInputComponent,
        FieldComponent,
        FilterComponent,
        FilterButtonComponent,
        FiltersAccordionComponent,
        FileCardComponent,
        FilterIconComponent,
        FormCardComponent,
        FormsModule,
        GenderSelectComponent,
        GoogleMapsEmbedComponent,
        IconComponent,
        IconButtonComponent,
        IssueOpenSelectComponent,
        ImageInputComponent,
        InfiniteScrollModule,
        InsertionDirective,
        IssueSelectComponent,
        ListGroupItemComponent,
        ListLogoComponent,
        ListsPageComponent,
        ListLogoComponent,
        ListWithDetailPageComponent,
        LocalAuthoritySelectComponent,
        MobileTabsComponent,
        ModalComponent,
        NavbarButtonsComponent,
        NgQuillEditorComponent,
        NgQuillViewComponent,
        NgSelectModule,
        NgxSpinnerModule,
        NoteCardComponent,
        NumberInputComponent,
        OrganisationSelectComponent,
        OrganisationCardComponent,
        OrganisationBasicInfoComponent,
        OrganisationCardHeaderComponent,
        OrganisationTypeSelectComponent,
        OrganisationLogoComponent,
        ProgressBarComponent,
        PlusIconComponent,
        QuestionIconComponent,
        QuestionTypeSelectComponent,
        RadioInputComponent,
        ReactiveFormsModule,
        ReferralsAccordionGroupComponent,
        ReferralCaseLayoutComponent,
        ReferralClosureReasonSelectComponent,
        ReferralClosedReasonsFormComponent,
        ReferralIssuesSelectComponent,
        ReferralStatusSelectComponent,
        ResolutionStatusSelectComponent,
        SaveAndDeleteIconsComponent,
        SafeUrlPipe,
        SearchBarComponent,
        SessionInactiveComponent,
        StaffSelectComponent,
        StaffRoleSelectComponent,
        SideMenuComponent,
        StaticSelectComponent,
        StatusesFormComponent,
        StepComponent,
        SwitchComponent,
        TableColumnDirective,
        TagComponent,
        TagsComponent,
        TagSelectComponent,
        TagOpenSelectComponent,
        TextInputComponent,
        TextAreaComponent,
        TooltipModule,
        TimePeriodSelectComponent,
        ToastsComponent,
        UserIconComponent,
        UserSelectComponent,
        UserOrgSelectComponent,
        YesNoPipe,
    ]
})
export class SharedModule {}
