import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Status } from '@app/shared/models/status.model';
import { FormsBaseService } from './forms-base.service';
import { FormArrayBaseService } from './form-array-base.service';
import { StatusState } from '@app/shared/models/enums/enums.model';
import { partition } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class StatusFormService extends FormsBaseService implements FormArrayBaseService<Status> {

    constructor(protected formBuilder: FormBuilder) {
        super(formBuilder);
    }

    createFormArray(statuses: Status[]): FormGroup {
        return this.formBuilder.group({
            formArray: this.formBuilder.array(statuses ?
                statuses.map((status) => this.createForm(status)) : []
            )
        });
    }

    createForm(status?: Status): FormGroup {
        return this.formBuilder.group({
            uid: [status?.uid],
            url: [status?.url],
            name: [status?.name, Validators.required],
            state: [status?.state, Validators.required],
            order: [status?.order],
            defaultOpeningStatus: [false]
        });
    }

    public from(statusForm: FormGroup, status = new Status()): Status {
        const formData = statusForm.getRawValue();

        status.uid = formData.uid;
        status.url = formData.url;
        status.name = formData.name;
        status.state = formData.state;
        status.order = formData.order;

        return status;
    }

    public fromArray(form: FormArray, statuses: Status[] = []): Status[] {
        const newStatuses = [];

        for (let i = 0; i < form.controls.length; i++) {
            newStatuses.push(this.from(form.controls[i] as FormGroup, statuses[i]));
        }

        return newStatuses;
    }
}
