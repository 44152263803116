<app-config-form-template [objectType]="objectType" [form]="formGroup" (saveClicked)="save()">
    <div form>
        <div class="mb5">
            <app-text-input [formGroup]="formGroup" formControlKey="name" labelText="Label" [marginBottom]="false"></app-text-input>
        </div>
        <div class="mb5">
            <label class="xs-text font-weight-extra-bold text-uppercase required-field">Outcome</label>
            <app-radio-input [formContainer]="formGroup" formControlKey="closureType" [options]="radioOptions" class="h-fit-content d-block mr6"></app-radio-input>
        </div>
        <div class="mb5">
            <label class="xs-text font-weight-extra-bold text-uppercase mb0">Settings</label>
            <app-checkbox-input [formGroup]="formGroup" formControlKey="requiresClosureReasonNote" labelText="Note required"></app-checkbox-input>
        </div>
    </div>
</app-config-form-template>