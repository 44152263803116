import { AfterViewInit, Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseReadOnlyService } from '@app/core/services/base-read-only.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Filter, IFilter, PagedResponse } from 'django-rest-core';
import { get } from 'lodash-es';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Directive()
export abstract class SelectBaseComponent<T> implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('select') select: NgSelectComponent;

    @Output() selectedOptionsChange = new EventEmitter<T[]>();

    @Input() multiselect = false;
    @Input() bindToField: string;
    @Input() bindToValue: string;
    @Input() preItemLabel = '';
    @Input() postItemLabel = '';
    @Input() tabIndex = 0;
    @Input() canAddNew = false;
    @Input() clearable = true;
    @Input() searchable = true;
    @Input() isOptionsRemoveable = true;
    @Input() appendTo = 'body';
    @Input() placeholder: string;
    @Input() formGroup: FormGroup;
    @Input() formControlKey: string;
    @Input() customClass: string;

    _filter: Filter;
    @Input() set filter(value: Filter) {
        this._filter = value ? value : null;
        this.outsideEventUpdated();
    }

    readonly pageSize = environment.pageSize;
    readonly scrollBuffer = 5;

    options: T[];
    subscriptions = new Subscription();
    currentPage = 1;
    hasNextPage = false;
    input$ = new Subject<string>();
    isLoadingNextPage = false;
    defaultPlaceholder: string;
    objectService: BaseReadOnlyService<T, IFilter>;
    getObjectCustomField: string;
    sortBy: string;
    filterSearchField = 'name';

    constructor() {
        this.onInputChanged();
    }

    @HostListener('window:resize')
    onResize(): void {
        if (this.select?.isOpen) {
            this.updateDropdownPosition();
        }
    }

    ngOnInit(): void {
        this.setVariables();
        this.defaultPlaceholder = this.multiselect ? 'Select options...' : 'Select option...';

        if (!this._filter) {
            this._filter = new Filter('');
        }

        if (this.objectService) {
            this.subscriptions.add(this.objectService.hasUpdated().subscribe(() => {
                this.outsideEventUpdated();
            }));
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this.onOutsideScroll, true);
        this.input$.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        const select = this.select;

        this.subscriptions.add(select.openEvent.subscribe(() => {
            window.addEventListener('scroll', this.onOutsideScroll, true);
            this.updateDropdownPosition();
            if (!this.options) {
                this.updateData();
            }
        }));

        this.subscriptions.add(select.closeEvent.subscribe(() => {
            window.removeEventListener('scroll', this.onOutsideScroll, true);
        }));
    }

    private onOutsideScroll = (event: Event) => {
        const target = event.target as HTMLElement;
        const isScrollingInDropdown = target.classList.contains('ng-dropdown-panel-items');
        if (!isScrollingInDropdown) {
            this.select.close();
        }
    }

    private updatePosition(selectElement: HTMLElement, parentElement: HTMLElement, dropdownEl: HTMLElement, currentPosition: string): void {
        const select = selectElement.getBoundingClientRect();
        const parent = parentElement.getBoundingClientRect();
        const offsetLeft = select.left - parent.left;
        this.setOffset(parent, select, dropdownEl, currentPosition);
        dropdownEl.style.left = offsetLeft + 'px';
        dropdownEl.style.width = select.width + 'px';
        dropdownEl.style.minWidth = select.width + 'px';
    }

    private setOffset(parent: DOMRect, select: DOMRect, dropdownElement: HTMLElement, currentPosition: string): void {
        const delta = select.height;

        if (currentPosition === 'top') {
            const offsetBottom = parent.bottom - select.bottom;
            dropdownElement.style.bottom = offsetBottom + delta + 'px';
            dropdownElement.style.top = 'auto';
        } else if (currentPosition === 'bottom') {
            const offsetTop = select.top - parent.top;
            dropdownElement.style.top = offsetTop + delta + 'px';
            dropdownElement.style.bottom = 'auto';
        }
    }

    private onInputChanged(): void {
        this.input$.pipe(
            switchMap((term) => {
                if (this._filter[this.filterSearchField] === term) {
                    this.isLoadingNextPage = true;
                    return of(null);
                } else {
                    this._filter[this.filterSearchField] = term;
                    this.currentPage = 1;
                    return this.getData(this.currentPage, this._filter);
                }
            })
        ).subscribe((response) => {
            if (response) {
                this.hasNextPage = response.next != null;
                this.options = response.results;
            }
            this.isLoadingNextPage = false;
        });
    }

    private updateData(reset = true): void {
        this.isLoadingNextPage = true;
        if (reset) {
            this.currentPage = 1;
        } else {
            this.currentPage++;
        }
        this.getData(this.currentPage, this._filter).subscribe((response) => {
            this.hasNextPage = response.next != null;
            this.options = (this.currentPage === 1) ? response.results : this.options.concat(response.results);
            this.isLoadingNextPage = false;
        });
    }

    getData(page: number, filter?: IFilter): Observable<PagedResponse<T>> {
        let source: Observable<PagedResponse<T>>;
        if (this.getObjectCustomField) {
            source = this.objectService[this.getObjectCustomField](page, filter, this.sortBy);
        } else {
            source = this.objectService.getPagedList(page, filter, this.sortBy);
        }

        return source;
    }

    updateDropdownPosition(): void {
        const selectElement = this.select.element;
        const dropdownEl = document.getElementById(this.select.dropdownId);
        if (dropdownEl) {
            const parent = dropdownEl.parentElement;
            const currentPosition = this.select.dropdownPanel.currentPosition;
            this.updatePosition(selectElement, parent, dropdownEl, currentPosition);
        }
    }

    onScroll(position: { start: number, end: number }): void {
        if (!this.hasNextPage) {
            return;
        }

        const selectedLength = this.formGroup?.get(this.formControlKey).value?.length;
        const lastItemIndex = (this.currentPage * this.pageSize) - (selectedLength ? selectedLength : 0);

        if ((position.end + this.scrollBuffer >= lastItemIndex) && !this.isLoadingNextPage) {
            this.updateData(false);
        }
    }

    resolveItem(item: T): string {
        let itemLabel = ''
        if (this.bindToField) {
            itemLabel = get(item, this.bindToField);
        } else {
            itemLabel = item as any;
        }
        return `${this.preItemLabel}${itemLabel}${this.postItemLabel}`;
    }

    getPlaceholderText(): string {
        return this.placeholder ? this.placeholder : this.defaultPlaceholder;
    }

    outsideEventUpdated(): void {
        if (this.select?.isOpen) {
            this.updateData();
        } else {
            this.options = null;
        }
    }

    protected addNew(name: string): void { }

    protected setVariables(): void { }
}
