
export class Queue<T> {

    queue: T[] = [];

    public push(value: T) {
        this.queue.push(value);
    }

    public pop(): T | undefined {
        return this.queue.shift();
    }

    public isEmpty(): boolean {
        return (this.queue === undefined || this.queue.length === 0);
    }
}
