import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-logo',
  templateUrl: './list-logo.component.html',
  styleUrls: ['./list-logo.component.scss']
})
export class ListLogoComponent implements OnInit {


  @Input() logoUrl: string;
  @Input() name: string;

  constructor() { }

  ngOnInit(): void {
  }

}
