import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CaseNote } from '@app/shared/models/notes/case-note.model';
import { Note } from '@app/shared/models/notes/note.model';
import { ReferralNote } from '@app/shared/models/notes/referral-note.model';

@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss']
})
export class NoteCardComponent {

    @Output() removeClicked = new EventEmitter<Note>();

    @Input() note: Note | CaseNote | ReferralNote;

    constructor() { }

    onRemoveNoteClicked(): void {
        this.removeClicked.emit(this.note);
    }

}
