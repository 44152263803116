import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { classToPlain } from 'class-transformer';
import { BaseReadOnlyService } from './base-read-only.service';
import { IFilter } from 'django-rest-core';

// This is modified code based on this class => RestfulServiceReadWrite<T>
@Injectable({
    providedIn: 'root'
})
export class BaseReadWriteService<T, F extends IFilter> extends BaseReadOnlyService<T, F>  {

    constructor(protected http: HttpClient) {
        super(http);
    }

    add(obj: T): Observable<T> {
        return this.setTypeOnResponse(this.http.post(this.getApiRoot(), classToPlain(obj)), this.exampleObject);
    }

    update(uid: string | number, obj: any): Observable<T> {
        return this.setTypeOnResponse(this.http.put(`${this.getApiRoot()}${uid}/`, classToPlain(obj)), this.exampleObject);
    }

    partialUpdate(uid: string | number, obj: any): Observable<T> {
        return this.setTypeOnResponse(this.http.patch(`${this.getApiRoot()}${uid}/`, classToPlain(obj)), this.exampleObject);
    }

    delete(uid: string | number): Observable<object> {
        return this.http.delete(`${this.getApiRoot()}${uid}/`);
    }

}
