import { Transform } from 'class-transformer';
import { ResourceLink } from 'django-rest-core';
import { Referral } from '../referral.model';
import { Note } from './note.model';

export class ReferralNote extends Note {
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<Referral>(params.value) : null, { toClassOnly: true })
    referral: ResourceLink<Referral>;
}
