
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Client } from '@app/shared/models/client.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-client-card',
    templateUrl: './client-card.component.html',
    styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent {

    readonly showGPInfo = environment.configuration.showGPInfo;
    readonly showNhsNumber = environment.configuration.showNhsNumber;

    @Input() client: Client;
    @Input() canEdit = true;

    @Output() editClient = new EventEmitter<{client: Client, step: number}>();

    constructor() { }

    handleEditClient(step: number): void {
        this.editClient.emit({client: this.client, step});
    }

}
