import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-description-box',
    templateUrl: './description-box.component.html',
    styleUrls: ['./description-box.component.scss']
})
export class DescriptionBoxComponent implements AfterViewInit {

    @ViewChild('descriptionBox') descriptionBox: ElementRef;

    @Input() text: string;
    @Input() linesOfText = 3;
    @Input() alwaysHideControls = false;

    showMore = false;
    showControls = false;

    constructor() { }

    get isEllipsisActive(): boolean {
        const nativeElement = this.descriptionBox?.nativeElement;
        if (!nativeElement) {
            return true;
        }
        return nativeElement.offsetHeight < nativeElement.scrollHeight;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showControls = this.isEllipsisActive;
        });
    }

    toggle(isEllipsisActive: boolean): void {
        this.showMore = isEllipsisActive;
    }

}
