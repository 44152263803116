import { Component, Input, OnInit } from '@angular/core';
import { Case } from '@app/shared/models/case.model';
import { Client } from '@app/shared/models/client.model';
import { Issue } from '@app/shared/models/issue.model';
import { Referral } from '@app/shared/models/referral.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-created-modal',
  templateUrl: './referral-created-modal.component.html',
  styleUrls: ['./referral-created-modal.component.scss']
})
export class ReferralCreatedModalComponent {

    readonly referralTerm = environment.configuration.terminology.referral;

    @Input() case: Case;
    @Input() client: Client;
    @Input() referral: Referral;
    @Input() issue: Issue;
    @Input() viewReferral: () => void;
    @Input() addReferral: () => void;


    constructor() { }

}
