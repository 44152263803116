import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

    @Input() control: FormControl;
    @Input() labelName?: string;
    @Input() showBottomMargin = true;

    constructor() { }

    get errorMessage(): string {
        for (const propertyName in this.control.errors) {
            if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
                if (propertyName === 'customErrorMessage') {
                    return this.control.errors[propertyName];
                } else {
                    return ValidationService.getValidationErrorMessage(
                        propertyName,
                        this.control.errors[propertyName],
                        this.labelName,
                    );
                }
            }
        }

        return undefined;
    }
}
