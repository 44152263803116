import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {
    // NB: We can't just use window.scroll as it is not the window that scrolls in this app, it is the component projected by router-outlet
    // Most pages use default-page-layout component, so we use the id of the div inside that component

    scrolling = new Subject();

    emitScroll(): void {
        this.scrolling.next();
    }

    scrollDefaultPageToTop(): void {
        document.getElementById('default-page-router-wrapper').scrollTo({ top: 0, left: 0, behavior: 'smooth'});
    }

}
