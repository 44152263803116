<div *ngIf="initialLoad"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="infiniteScrollContainer"
    [fromRoot]="true"
    [alwaysCallback]="true"
    class="container-fluid pb5"
    (scrolled)="scrolled()">
    <div class="row flex-column gy3">
        <div *ngFor="let item of data"
            class="card d-block col-12" [ngClass]="{ 'has-click-event': hasClickEvent, 'selected': selectedData?.uid === item?.uid }"
            (click)="hasClickEvent ? onClickEvent(item) : null">
            <div class="card-body row card-list-item flex-nowrap {{ customPadding ? customPadding : 'pl0 pr0 pt2 pb2' }}">
                <div *ngFor="let config of rowConfigs; index as i"
                    class="{{ config.colSpan ? 'col-' + config.colSpan : 'col' }} d-flex align-items-center sm-text"
                    [ngClass]="{
                        'font-weight-bold': config.isBold,
                        'justify-content-between': (hasDropdown && i === rowConfigs.length - 1)
                    }">
                    <div *ngIf="!config.customTemplateSelect" class="w-100 show-ellipsis sm-text" [class.text-center]="config.isCentered">{{ getField(item, config.field) }}</div>
                    <div *ngIf="config.customTemplateSelect" class="w-100" [ngClass]="{ 'd-flex justify-content-center align-items-center': config.isCentered }">
                        <ng-container [ngTemplateOutlet]="getTemplate(config.customTemplateSelect)"
                            [ngTemplateOutletContext]="{ item: item, index: i }"></ng-container>
                    </div>
                    <div *ngIf="hasDropdown &&  i === rowConfigs.length - 1" class="w-fit-content card-list-dropdown"
                        (click)="stopPropagation($event)">
                        <ng-container 
                            [ngTemplateOutlet]="dropdown"
                            [ngTemplateOutletContext]="{ item: item }"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="_loading" class="spinner-container">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0)" [name]="spinnerName" type="ball-spin-clockwise" color="#000" [fullScreen]="false" size="small"></ngx-spinner>
    </div>
    <h2 *ngIf="!_loading && data?.length < 1" class="text-center mt5">
        {{ noDataMessage }}
    </h2>
</div>
