<div aria-label="breadcrumb" class="mb3">
    <ol class="breadcrumb m0 p0">
        <li *ngFor="let breadCrumb of directory; index as i" class="xs-text breadcrumb-item text-nowrap">
            <div *ngIf="isObject(breadCrumb); else stringBreadCrumb" [routerLink]="breadCrumb?.link"
                [ngClass]="{ 'font-weight-bold': breadCrumb.isBold, 'link': breadCrumb.link, 'ml5': i !== 0 }"
                [class]="getTextColor(breadCrumb)">
                {{ breadCrumb.title }}
            </div>
            <ng-template #stringBreadCrumb>
                {{ breadCrumb }}
            </ng-template>
        </li>
    </ol>
</div>
<ng-content *ngIf="hasCustomMainTitle; else mainTitleElement" select="[mainTitle]"></ng-content>
<span *ngIf="hasCustomMainTitle" class="sm-text text-wrap">{{ subtitle }}</span>
<ng-template #mainTitleElement>
    <h1 *ngIf="mainTitle" [class.mb0]="!subtitle">{{ mainTitle }}</h1>
    <span *ngIf="subtitle" class="sm-text text-wrap">{{ subtitle }}</span>
</ng-template>