<app-icon faIcon="circle-question"
    faType="solid"
    faSize="md"
    size="sm"
    theme="clear"
    [placement]="placement"
    [isCircle]="true"
    [tooltipText]="tooltipText"
    [ngxContainer]="ngxContainer">
</app-icon>
