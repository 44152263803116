import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'app-plus-icon',
    templateUrl: '../icon/icon.component.html',
    styleUrls: ['../icon/icon.component.scss', './plus-icon.component.scss']
})
export class PlusIconComponent extends IconComponent {

    @Input() inAccordion = false;
    @Input() inSubAccordion = false;
    @Input() show = true;

    constructor() {
        super();
        this.faIcon = 'plus';
        this.theme = 'green';
        this.hasClickEvent = true;
    }
}
