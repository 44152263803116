<div class="card">
    <div class="card-body">
        <app-dropdown [iconColourDark]="true" class="float-right">
            <div items>
                <app-dropdown-item text="Remove Note" (click)="onRemoveNoteClicked()">
                </app-dropdown-item>
            </div>
        </app-dropdown>
        <div class="d-flex">
            <div class="primary-colour mr3 mt2">
                <i class="fal fa-2x fa-comment"></i>
            </div>
            <div>
                <div class="xs-text">
                    <div class="font-weight-bold">Created by {{ note.createdBy?.name }}</div>
                    <div class="primary-colour">Created by {{ note.createdBy?.email }}</div>
                </div>
                <div class="sm-text pt3">
                    {{ note.content }}
                </div>
            </div>
        </div>
        <div class="mt3 xs-text font-weight-bold float-right">Created at {{ note.createdAt | date: 'dd MMM YYYY' }}</div>
    </div>
</div>
