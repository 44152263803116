import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioOption } from '@app/shared/models/radio-option.model';

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['./radio-input.component.scss']
})
export class RadioInputComponent {

    @Input() formContainer: FormGroup;
    @Input() formControlKey: string;
    @Input() options: RadioOption[] = [];
    @Input() isInline = false;

    constructor() { }

}
