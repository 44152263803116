import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-add-note-modal',
  templateUrl: './add-note-modal.component.html',
  styleUrls: ['./add-note-modal.component.scss']
})
export class AddNoteModalComponent implements OnInit {

    @Input() saveNote: (note?: string) => void;

    formGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private bsModalRef: BsModalRef,
    ) { }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            note:['', Validators.required]
        },{});
    }

    onCancel(): void {
        this.bsModalRef.hide();
    }

    onSave(): void {
        let note = this.formGroup.get('note').value;
        this.saveNote(note);
        this.bsModalRef.hide();  
    }

}
