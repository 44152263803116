import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { User } from '@app/shared/models/user.model';

@Component({
    selector: 'app-user-org-select',
    templateUrl: './user-org-select.component.html',
    styleUrls: ['./user-org-select.component.scss']
})
export class UserOrgSelectComponent implements OnInit {

    @Input() user: User;

    formGroup: FormGroup;
    ngxContainer = '#navbar-container';

    constructor(
        private currentUserService: CurrentUserService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        if (!this.user.isSiteAdmin) {
            let currentStaff = this.user?.staff?.length > 0 ? this.user.staff[0] : null;
            const currentOrgUid = this.currentUserService.getCurrentOrganisationUid();
            if (currentOrgUid) {
                currentStaff = this.user?.staff?.find(item => item.organisation?.uid === currentOrgUid) ?? currentStaff;
            }
            if (currentStaff?.organisation.uid) {
                this.currentUserService.setCurrentOrganisationUid(currentStaff?.organisation.uid);
            }
            this.formGroup = this.formBuilder.group({
                currentStaff: [currentStaff]
            });
        }
    }

    currentOrgChanged(): void {
        const staff = this.formGroup.get('currentStaff').value;
        this.currentUserService.setCurrentOrganisationUid(staff.organisation?.uid);
    }

}
