import { Expose, Transform, Type } from 'class-transformer';
import { BaseModel } from 'django-rest-core';
import { Organisation } from './organisation.model';
import { StaffRole } from './staffRole.model';
import { User } from './user.model';

export class Staff extends BaseModel {

    uid: string;
    position: string;
    active: boolean;

    @Type(() => User)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    user: User;

    @Expose({name: 'staff_role'})
    @Type(() => StaffRole)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    staffRole: StaffRole;

    @Expose({name: 'staff_title'})
    staffTitle: string;

    @Expose({name: 'phone_number'})
    phoneNumber: string;

    @Type(() => Organisation)
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    organisation: Organisation;

}
