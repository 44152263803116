import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Postcode } from '@app/shared/models/postcode.model';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PostcodeSearchService {

    protected getApiRoot = (postcode: string) => `${environment.apiRoot}postcodes/${postcode}/addresses`;

    constructor(protected http: HttpClient) { }

    getPostcodes(postcode: string): Observable<Postcode[]> {
        return this.http.get<Postcode[]>(this.getApiRoot(postcode)).pipe(
            map((response) => {
                return plainToClass(Postcode, response as object[]);
            })
        );
    }
}
