import { Component, Input } from '@angular/core';
import { GenderFormService } from '@app/core/services/forms/gender-form.service';
import { ToastService } from '@app/core/services/toast.service';
import { Gender } from '@app/shared/models/gender.model';
import { GenderService } from '@app/core/services/gender.service';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
    selector: 'app-genders-form',
    templateUrl: './genders-form.component.html',
    styleUrls: ['./genders-form.component.scss']
})
export class GendersFormComponent extends BaseFormComponent {

    _gender: Gender;
    @Input() set gender(gender: Gender) {
        this._gender = gender;
        this.initialize();
    }

    @Input() isDirty = false;

    constructor(
        protected toastService: ToastService,
        private genderFormService: GenderFormService,
        private genderService: GenderService,
    ) {
        super(toastService);
    }

    initialize(): void {
        this.object = this._gender;
        this.objectType = 'Gender';
        this.objectService = this.genderService;
        this.formService = this.genderFormService;
        this.formGroup = this.formService.createForm(this.object);
        if (this.isDirty) {
            this.formGroup.markAsDirty();
        }
    }

}
