import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ng-quill-view',
    templateUrl: './ng-quill-view.component.html',
    styleUrls: ['./ng-quill-view.component.scss']
})
export class NgQuillViewComponent {

    @Input() content: string;

    constructor() { }

}
