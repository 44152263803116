import { Expose } from "class-transformer";
import { BaseModel } from "django-rest-core";

export class Postcode extends BaseModel{
    
    postcode: string;
    house: string;
    street: string;
    city: string;
    locality: string;
    county: string;
    lat: string;
    long: string;

    @Expose({name: 'long_lat'})
    longLat: string;

}