<app-config-form-template [objectType]="objectType" [form]="formGroup" (saveClicked)="save()">
    <div form>
        <div class="mb5">
            <app-text-input [formGroup]="formGroup" formControlKey="name" [marginBottom]="false" labelText="Label"></app-text-input>
        </div>
        <div class="mb5">
            <label class="xs-text font-weight-extra-bold text-uppercase mb0">Settings</label>
            <app-checkbox-input [formGroup]="formGroup" formControlKey="isNonBinary" labelText="Non-binary"></app-checkbox-input>
        </div>
    </div>
</app-config-form-template>
