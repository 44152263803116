import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BreadCrumb } from '@app/shared/models/breadCrumb.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-referral-case-layout',
    templateUrl: './referral-case-layout.component.html',
    styleUrls: ['./referral-case-layout.component.scss']
})
export class ReferralCaseLayoutComponent implements OnInit {

    @Input() mainDirectoryTitle: string;
    @Input() directory: (string | BreadCrumb)[];
    @Input() customHorizontalPadding: string;

    mobileScreenSize = false;
    activeMobileTabIndex: number;

    constructor() { }

    @HostListener('window:resize', ['$event.target'])
    onResize(window: Window): void {
        if (window.innerWidth < environment.mobileScreenSize) {
            if (!this.mobileScreenSize) {
                this.activeMobileTabIndex = 0;
            }
            this.mobileScreenSize = true;
        } else {
            this.mobileScreenSize = false;
        }
    }

    ngOnInit(): void {
        this.onResize(window);
    }

    activateTab(tabIndex: number): void {
        this.activeMobileTabIndex = tabIndex;
    }

}
