import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '@app/shared/models/address.model';
import { Client } from '@app/shared/models/client.model';
import { ResourceLink } from 'django-rest-core';
import * as moment from 'moment';

import { FormsBaseService } from './forms-base.service';

@Injectable({
    providedIn: 'root',
})
export class ClientFormService extends FormsBaseService {

    constructor(protected formBuilder: FormBuilder) {
        super(formBuilder);
    }

    getForm(client?: Client): FormGroup {
        return this.formBuilder.group({
            uid: [client?.uid],
            url: [client?.url],
            title: [client?.title],
            pronouns: [client?.preferredPronouns],
            firstName: [client?.firstName, [Validators.required]],
            lastName: [client?.lastName, [Validators.required]],
            dob: [client?.dateOfBirth ? client.dateOfBirth.toDate() : null, [Validators.required]],
            gender: [client?.gender],
            nhsNumber: [client?.nhsNumber],
            gpSurgery: [client?.gpInfo],
            email: [client?.email],
            primaryTelephone: [client?.primaryPhoneNumber, [Validators.required, Validators.minLength(11)]],
            secondaryTelephone: [client?.secondaryPhoneNumber, Validators.minLength(11)],
            leaveText: [client?.leaveText ?? false],
            leaveMessage: [client?.leaveMessage ?? false]
        });
    }

    public from(clientForm: FormGroup, client = new Client()): Client {
        const formData = clientForm.getRawValue();

        client.uid = formData.uid;
        client.url = formData.url;
        client.firstName = formData.firstName;
        client.lastName = formData.lastName;
        client.title = formData.title;
        client.preferredPronouns = formData.pronouns;
        client.dateOfBirth = moment(formData.dob);
        client.gender = formData.gender;
        client.nhsNumber = formData.nhsNumber;
        client.gpInfo = formData.gpSurgery;
        client.primaryPhoneNumber = formData.primaryTelephone;
        client.secondaryPhoneNumber = formData.secondaryTelephone;
        client.leaveText = formData.leaveText;
        client.leaveMessage = formData.leaveMessage;
        client.email = formData.email;

        return client;
    }
}
