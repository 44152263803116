import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@app/shared/models/address.model';
import { IFilter } from 'django-rest-core';
import { BaseReadWriteService } from './base-read-write.service';


@Injectable({
    providedIn: 'root'
})
export class AddressService extends BaseReadWriteService<Address, IFilter> {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'addresses';
        this.exampleObject = new Address();
    }

}
