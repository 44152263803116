import { Expose, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { Moment } from 'moment';
import { UserBase } from 'user-core';
import { Staff } from './staff.model';

export class User extends UserBase {

    uid: string;
    name: string;
    initials: string;
    email: string;
    username: string;

    @Expose({ name: 'first_name' })
    firstName: string;

    @Expose({ name: 'last_name' })
    lastName: string;

    @Expose({ name: 'is_active' })
    isActive: boolean;

    @Expose({ name: 'is_blocked' })
    isBlocked: boolean;

    @Expose({ name: 'is_owner' })
    isOwner: boolean;

    @Expose({ name: 'is_site_admin' })
    isSiteAdmin: boolean;

    // is_admin is a backend only variable
    @Expose({ name: 'is_admin' })
    isAdmin: boolean;

    @Expose({ name: 'has_access_to_all_organisations' })
    hasAccessToAllOrganisations: boolean;

    @Type(() => Staff)
    staff: Staff[];

    @Expose({ name: 'system_name' })
    nameWithUsername: boolean;

    @Expose({ name: 'system_title' })
    systemTitle: string;

    @Expose({ name: 'date_joined' })
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    dateJoined: Moment;

    @Expose({ name: 'last_login' })
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    lastLogin: Moment;

    @Expose({ name: 'member_since' })
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    memberSince: Moment;

}
