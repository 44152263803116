import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { Util } from '@app/shared/models/utils/utils';

@Component({
    selector: 'app-user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent {

    @Input() user: any;
    @Input() size: 'lg' | 'md' | 'sm' = 'md';
    @Input() faSize: 'sm' | 'md' | 'lg' = 'md';
    @Input() theme: 'primary' | 'secondary' | 'tertiary' | 'cyan' | 'light' | 'gray' | 'blue' | 'clear' = 'primary';
    @Input() placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'auto';
    @Input() tabIndex = 0;
    @Input() hasClickEvent = false;
    @Input() isCircle = false;
    @Input() tooltipTitle: string;
    @Input() ngxContainer = '#page-container';

    @Output() clickEvent = new EventEmitter();

    constructor() { }

    clicked = () => this.clickEvent.emit();

}
