<div [ngStyle]="{ 'width': width, 'height': height }" class="organisation-image-container d-flex align-items-center position-relative"
    [ngClass]="{ 'border': hasBorder }">
    <img *ngIf="logoUrl && _loaded" [src]="logoUrl" alt="{{ name ? 'Logo of ' + name : null }}" class="w-100">
    <ngx-spinner *ngIf="!_loaded" bdColor="rgba(0, 0, 0, 0)"
        [name]="orgLogoSpinnerName"
        type="ball-spin-clockwise"
        color="#000"
        [fullScreen]="false"
        size="small"></ngx-spinner>
</div>
