import { Directive, EventEmitter, Output } from '@angular/core';

@Directive()
export abstract class BaseListComponent {

    @Output() onScroll = new EventEmitter();

    constructor() { }

    scrolled() {
        this.onScroll.emit();
    }

}
