import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from 'django-rest-core';
import { Configuration } from '@app/shared/models/configuration.model';
import { BaseReadWriteService } from './base-read-write.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends BaseReadWriteService<Configuration, IFilter> {

    constructor(protected http: HttpClient, private router: Router) {
        super(http);
        this.endPoint = 'configurations';
        this.exampleObject = new Configuration();
    }

    getConfiguration(): Promise<Configuration> {
        return super.get(1).pipe(
            map((config: Configuration) => {
                this.setEnviroment(config);
                return config;
            })
        ).toPromise().catch((error: HttpErrorResponse) => { 
            if (error) {
                localStorage.clear();
                this.router.navigate([environment.links.auth.login]);
            }
         }) as any;
    }

    setEnviroment(config: Configuration) {
        // Terminology
        environment.configuration.terminology.client = config.clientTerminology;
        environment.configuration.terminology.clientPlural = config.clientTerminologyPlural;
        environment.configuration.terminology.case = config.caseTerminology;
        environment.configuration.terminology.casePlural = config.caseTerminologyPlural;
        environment.configuration.terminology.referral = config.referralTerminology;
        environment.configuration.terminology.referralPlural = config.referralTerminologyPlural;
        environment.configuration.terminology.issue = config.issueTerminology;
        environment.configuration.terminology.issuePlural = config.issueTerminologyPlural;
        environment.configuration.terminology.organisation = config.organisationTerminology;
        environment.configuration.terminology.organisationPlural = config.organisationTerminologyPlural;
        environment.configuration.terminology.questionnaire = config.questionnaireTerminology;
        environment.configuration.terminology.questionnairePlural = config.questionnaireTerminologyPlural;

        // Automation
        environment.authentication.config.maxInactivePeriodInSeconds = config.logoutTimerPeriod * 60;
        environment.configuration.updateWithinOverdueLimit = config.updateWithinOverdueLimit;
        environment.configuration.closeWithinOverdueLimit = config.closeWithinOverdueLimit;

        // General
        environment.configuration.uid = config.uid;
        environment.configuration.adminOrganisationAbbreviation = config.adminOrganisationAbbreviation;
        environment.configuration.adminOrganisationName = config.adminOrganisationName;
        environment.configuration.adminSupportEmail = config.adminSupportEmail;
        environment.configuration.privacyPolicyLink = config.privacyPolicyLink;
        environment.configuration.showGPInfo = config.showGPInfo;
        environment.configuration.showNhsNumber = config.showNhsNumber;
        environment.configuration.logo = config.logo;
        environment.configuration.theme = config.theme;

        // Integrations
        environment.configuration.googleTagManagerId = config.googleTagManagerId;
        environment.configuration.tawkWebchatCode = config.tawkWebchatCode;
    }

}
