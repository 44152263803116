<div class="pt7 pb7 pl9 pr9">
    <h2 class="mb6 font-weight-extra-bold">Add a Note</h2>
    <div class="mb3">
        <div class="form-group">
            <app-text-area [formGroup]="formGroup" formControlKey="note" [maxLength]="500" [rows]="6"
                [marginBottom]="false"></app-text-area>
        </div>
    </div>
    <div class="w-fit-content ml-auto">
        <span class="mr3">
            <button (click)="onCancel()" class="btn btn-outline-primary">Cancel</button>
        </span>
        <span>
            <button [disabled]="formGroup.invalid" (click)="onSave()" class="btn btn-primary">Save</button>
        </span>
    </div>
</div>