<div class="row row-cols-1 gy3" [formGroup]="formGroup">
    <div class="col">
        <label class="xs-label required-field" for="email">EMAIL ADDRESS</label>
        <input id="email" class="form-control light-gray-border xs-placeholder" formControlName="email"
            placeholder="Email Address" required>
        <app-error class="mt3" *ngIf="formGroup"
            [control]="formGroup.get('email')"></app-error>
    </div>
    <div class="col">
        <label class="required-field xs-label" for="telephone">TELEPHONE NUMBER</label>
            <input id="telephone" class="form-control light-gray-border xs-placeholder"
                formControlName="primaryTelephone" placeholder="Telephone Number" required>
            <app-error class="mt3" *ngIf="formGroup"
                [control]="formGroup.get('primaryTelephone')"></app-error>
    </div>
    <div class="col" *ngIf="showSecondaryTelephone">
        <label class="xs-label" for="telephone">ALTERNATIVE TELEPHONE NUMBER</label>
        <input id="telephone" class="form-control light-gray-border xs-placeholder"
            formControlName="secondaryTelephone" placeholder="Telephone Number">
    </div>
    <div class="col">
        <a class="form-link-primary" (click)="toggleShowSecondaryTelephone()">
            {{ showSecondaryTelephone ? '- Remove Alternative Telephone Number' :
            '+ Add Alternative Telephone Number'}}
        </a>
    </div>
    <div class="col">
        <app-checkbox-input labelText="Permission to Text (SMS)" formControlKey="leaveText" [formGroup]="formGroup"></app-checkbox-input>
    </div>
    <div class="col">
        <app-checkbox-input labelText="Permission to Leave Voicemail" formControlKey="leaveMessage" [formGroup]="formGroup"></app-checkbox-input>
    </div>
    <div class="col" *ngIf="!hasCustomSave">
        <div class="row no-gutters gx3 gy3 mt5">
            <div class="col-auto">
                <button (click)="save()" class="btn btn-primary no-wrap"
                    [disabled]="!isContactDetailsValid">Save</button>
            </div>
            <div class="col-auto">
                <button (click)="goBack()" class="btn btn-outline-primary">Back</button>
            </div>
        </div>
    </div>
</div>
