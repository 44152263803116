import { Expose, Transform } from 'class-transformer';
import { ResourceLink } from 'django-rest-core';
import { Moment } from 'moment';
import { User } from './user.model';
import * as moment from 'moment';
import { BaseModel } from './base-model';
import { ActivityFeedActivityType, ActivityFeedObjectType } from './enums/activityFeedObjectTypes.enum';

export class ActivityFeed extends BaseModel {

    @Expose({name: 'case_id'})
    caseId: number;

    @Expose({name: 'client_id'})
    clientId: number;

    @Expose({name: 'referral_id'})
    referralId: number;

    @Expose({name: 'activity_type'})
    activityType: ActivityFeedActivityType;

    @Expose({name: 'object_id'})
    objectId: number;

    @Expose({name: 'object_type'})
    objectType: ActivityFeedObjectType;

    @Expose({name: 'object_instance'})
    objectInstance: string;

    @Expose({name: 'object_timestamp'})
    @Transform((params) => params.value ? moment(params.value) : null, { toClassOnly: true })
    @Transform((params) => params.value ? params.value.format('YYYY-MM-DDTHH:mmZ') : null, { toPlainOnly: true })
    objectTimestamp: Moment;

    @Expose({name: 'performed_by'})
    @Transform((params) => params.value ? params.value.url : null, { toPlainOnly: true })
    @Transform((params) => params.value ? new ResourceLink<User>(params.value) : null, { toClassOnly: true })
    performedBy: ResourceLink<User>;

    @Expose({name: 'performed_by_name'})
    performedByName: string;

    @Expose({name: 'performed_by_email'})
    performedByEmail: string;

}
