<div [formGroup]="referralForm">
    <div class="row justify-content-between">
        <div class="col-5 d-flex flex-column gy6">
                <div>
                    <div class="header-xs">You are creating a {{ referralTerm }} for:</div>
                    <div class="header-xs">{{client.fullName | uppercase}}.</div>
                </div>
                <div>
                    <label for="issue" class="xs-label text-uppercase required-field">{{ issueTerm }}</label>
                    <app-issue-select bindToField="name" [formGroup]="referralForm" formControlKey="issue"
                        id="issue" (selectedOptionsChange)="setOrganisationFilters($event)">
                    </app-issue-select>
                </div>
                <div>
                    <label for="organisation" class="xs-label text-uppercase required-field">{{ organisationTerm }}</label>
                    <app-organisation-select bindToField="name" [filter]="organisationFilters"
                        [formGroup]="referralForm" formControlKey="organisation" id="organisation"
                        (selectedOptionsChange)="getOrganisation($event)">
                    </app-organisation-select>
                </div>
                <div>
                    <label for="urgentReferral" class="xs-label text-uppercase">Is this {{ referralTerm }} urgent?</label>
                    <app-switch [formGroup]="referralForm" formControlKey="isUrgent" id="urgentReferral">
                    </app-switch>
                </div>
                <div>
                    <button type="button" class="btn btn-outline-primary cursor-pointer"
                        (click)="onBackClicked()">Back</button>
                    <button type="button" class="btn btn-primary ml5" (click)="onSaveClicked()"
                        [disabled]="referralForm.invalid">Save</button>
                </div>
        </div>
        <div class="col-7">
            <app-organisation-card *ngIf="organisation" [showCriteria]="true" [showServiceOffer]="false" [hasCustomButtons]="true"
                [organisation]="organisation"></app-organisation-card>
        </div>
    </div>
</div>