import { Component, OnInit } from '@angular/core';
import { PagedResponse } from 'django-rest-core';
import { Observable, of } from 'rxjs';
import { SelectBaseComponent } from '../select-base/select-base.component';

@Component({
    selector: 'app-question-type-select',
    templateUrl: '../select-base/select-base.component.html'
})
export class QuestionTypeSelectComponent extends SelectBaseComponent<any> implements OnInit {

    constructor() {
        super();
    }

    ngOnInit = () => super.ngOnInit();

    getData(page: number): Observable<PagedResponse<any>> {
        const pagedResponse = new PagedResponse<any>();
        pagedResponse.count = 2;
        pagedResponse.results = [
            { name: 'Toggle' },
            { name: 'Text Area' },
        ]
        return of(pagedResponse);
    }

}
