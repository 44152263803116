import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '@app/shared/models/client.model';
import { BaseReadWriteService } from './base-read-write.service';
import { ClientFilters } from '@app/shared/models/filters/clientFilters.model';
import { Observable } from 'rxjs';
import { PagedResponse } from 'django-rest-core';
import { Moment } from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ClientService extends BaseReadWriteService<Client, ClientFilters> {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'clients';
        this.defaultSort = 'full_name';
        this.exampleObject = new Client();
    }

    getCompactClients(page: number, filter?: ClientFilters, sortBy?: string): Observable<PagedResponse<Client>> {
        return this.getPagedList(page, filter, sortBy, 'compact');
    }

    searchClients(firstName: string, lastName: string, dob: Moment): Observable<PagedResponse<Client>>{
        const clientToSend = {
            search_first_name: firstName,
            search_last_name: lastName,
            search_date_of_birth: dob.format('YYYY-MM-DD').toString()
        };
        return this.setTypeOnResponse(this.http.post(`${this.getApiRoot()}search/`, clientToSend), this.exampleObject);
    }

}
