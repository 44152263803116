import { Expose } from "class-transformer";
import { ClosureType } from "./enums/enums.model";
import { BaseModel } from "./base-model";

export class ReferralClosureReason extends BaseModel {
    
    uid: string;
    name: string;

    @Expose({name: 'requires_closure_reason_note'})
    requiresClosureReasonNote: boolean;

    @Expose({name: 'closure_type'})
    closureType: ClosureType;
}