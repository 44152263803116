import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from 'django-rest-core';
import { StaffRole } from '@app/shared/models/staffRole.model';
import { BaseReadWriteService } from './base-read-write.service';

@Injectable({
    providedIn: 'root'
})
export class StaffRoleService extends BaseReadWriteService<StaffRole, IFilter>  {

    constructor(protected http: HttpClient) {
        super(http);
        this.endPoint = 'staffrole';
        this.defaultSort = 'name';
        this.exampleObject = new StaffRole();
    }

}


