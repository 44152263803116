<div *ngFor="let item of navItems">
    <div (click)="onSelected(item)" class="card nav-item-card cursor-pointer mb3" [ngClass]="{ active: item.active }">
        <div class="card-body p3">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <app-icon [faIcon]="item.icon" [faSize]="item.iconSize" [size]="item.iconSize" [faType]="item.iconType" [isCircle]="true"></app-icon>
                    <span class="sm-text ml3">{{ item.title }}</span>
                </div>
                <span *ngIf="item.notifications || item.notifications === 0" class="xs-text font-weight-bold mr4">{{ item.notifications }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="item.active && item.subItems?.length > 0">
        <div *ngFor="let subItem of item.subItems"
            (click)="onSubItemSelected(subItem, item)"
            class="card nav-item-card cursor-pointer mb3"
            [ngClass]="{ 'border border-dark': item.activeSubItemType === subItem.type }">
            <div class="card-body p3">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <app-icon theme="gray" [faIcon]="subItem.icon" [faSize]="item.iconSize" [size]="item.iconSize" [faType]="item.iconType" [isCircle]="true"></app-icon>
                        <span class="sm-text ml3">{{ subItem.title }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
