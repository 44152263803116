import { Component } from '@angular/core';
import { SelectBaseComponent } from '../select-base/select-base.component';
import { Case } from '@app/shared/models/case.model';
import { CaseService } from '@app/core/services/case/case.service';
import { CaseFilters } from '@app/shared/models/filters/caseFilters.model';

@Component({
  selector: 'app-case-select',
  templateUrl: '../select-base/select-base.component.html',
})
export class CaseSelectComponent extends SelectBaseComponent<Case> {

    constructor(private caseService: CaseService) {
        super();
    }

    setVariables(): void {
        this.objectService = this.caseService;
        this.getObjectCustomField = 'getCompactCases';
        this.filter = new CaseFilters();
    }

}
