import { plainToClassFromExist } from 'class-transformer';
import { cloneDeep } from 'lodash-es';

export abstract class Util {
    static toInteger(value: any): number {
      return parseInt(`${value}`, 10);
    }

    static isNumber(value: any): value is number {
        return !isNaN(this.toInteger(value));
    }

    static padNumber(value: number) {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }

    static resolveNested(option: any, key: string): any {
        if (key.indexOf('.') === -1) {
            return option[key];
        } else {
            const keys: string[] = key.split('.');
            let value = option;
            for (let i = 0, len = keys.length; i < len; ++i) {
                if (value == null) {
                    return null;
                }
                value = value[keys[i]];
            }
            return value;
        }
    }

    static getPkFromUrl(url: string) {
        const segements = url.toString().split('/');
        if (segements.length > 1) {
            return Number(segements[segements.length - 2]);
        }

        return null;
    }
    static getLinkHref(url: string) {
        return (url.startsWith('http')) ? url : 'https://' + url;
    }

    static capitalize(str: string) {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }

    static clamp(num, min, max) {
        return num < min ? min : num > max ? max : num;
    }

    static getInitials(name: string, delimeter: string) {
        if (name) {
            const words = name.split(delimeter);
            switch (words.length) {
                case 1:
                    return words[0].charAt(0).toUpperCase();
                    break;
                default:
                    return words[0].charAt(0).toUpperCase() + words[words.length - 1].charAt(0).toUpperCase();
            }
        }
        return false;
    }

    static staticPlainToClassArrayFromObject(object: any, data: any | any[]): any | any[] {
        if (Array.isArray(data)) {
            const tempExampleArray = [];
            data.forEach(() => tempExampleArray.push(cloneDeep(object)));
            data = plainToClassFromExist(tempExampleArray, data);
        } else {
            data = plainToClassFromExist(cloneDeep(object), data);
        }
        return data;
    }

}
