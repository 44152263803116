<form [formGroup]="formGroup" class="p7">
    <h1 class="mb5">{{ header }}</h1>
    <ng-content select="[formContent]"></ng-content>
    <div class="d-flex align-items-center justify-content-end">
        <div (click)="clearFilters()"  class="form-link pr5">
            <i class="far fa-times pr3"></i>
            Clear Filters
        </div>
        <button (click)="saveFilters()" class="btn btn-outline-primary">Save</button>
    </div>
</form>