import { BaseModel } from 'django-rest-core';
export class Tag extends BaseModel {
    uid: string;
    name: string;

    constructor(name?: string) {
        super();
        this.name = name;
    }

}
